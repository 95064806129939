import dayjs from "dayjs"
import {
    CalendarEvent,
    TimelineChunk,
    selectEventCategories,
} from "../foundryCalendarSlice"
import styles from "./TimelineChunkView.module.css"
import TimelineRowView from "./TimelineRow"
import { useSelector } from "react-redux"

interface TimelineChunkProps {
    timelineChunk: TimelineChunk
}

const TimelineChunkView: React.FC<TimelineChunkProps> = ({ timelineChunk }) => {
    const eventCategories = useSelector(selectEventCategories)
    const { parentEvent, childEvents } = timelineChunk
    const { name, start, end } = parentEvent
    const startString = formatDateString(start)
    const endString = formatDateString(end)
    const containsToday = isCurrentDateInRange(start, end)

    const filteredEvents = childEvents.filter((event) => {
        const cat = eventCategories.find((category) => {
            return category.name === event.type
        })
        return cat?.visible
    })

    const piRow = <TimelineRowView startDate={start} endDate={end} />

    const sprintEvents = filteredEvents.filter((event) => {
        // Convert event.type to lowercase and check if it includes the word 'sprint'
        return event.type.toLowerCase().includes("sprint")
    })
    const sprintRow = (
        <TimelineRowView
            startDate={start}
            endDate={end}
            calendarEvents={sprintEvents}
        />
    )

    const otherEvents = filteredEvents.filter((event) => {
        return !event.type.toLowerCase().includes("sprint")
    })
    const otherRow = (
        <TimelineRowView
            startDate={start}
            endDate={end}
            calendarEvents={otherEvents}
        />
    )

    const monthEvents = generateMonthlyEvents(start, end)
    const monthLabelRow = (
        <TimelineRowView
            startDate={start}
            endDate={end}
            calendarEvents={monthEvents}
        />
    )

    const containerClass = `${styles.container} ${
        containsToday ? styles.current : null
    }`

    return (
        <div className={containerClass}>
            <div className={styles.title}>{name}</div>
            <div className={styles.dateRange}>
                {startString} - {endString}
            </div>
            <div>{monthLabelRow}</div>
            <div>{piRow}</div>
            <div>{sprintEvents.length > 0 && sprintRow}</div>
            <div>{otherEvents.length > 0 && otherRow}</div>
        </div>
    )
}

function formatDateString(dateStr: string, format: string = "MMM DD"): string {
    // Parse the date string
    const date = dayjs(dateStr)

    // Return the formatted date
    return date.format(format)
}

function generateMonthlyEvents(
    startDateStr: string,
    endDateStr: string
): CalendarEvent[] {
    const startDate = dayjs(startDateStr)
    const endDate = dayjs(endDateStr)

    // Start from the first day of the next month if the start date is not the first of a month
    let currentDate =
        startDate.date() === 1
            ? startDate
            : startDate.add(1, "month").startOf("month")
    const events: CalendarEvent[] = []

    while (currentDate.isBefore(endDate)) {
        events.push({
            id: currentDate.format("YYYY-MM-DD"),
            name: currentDate.format("MMMM"),
            category: "UI",
            type: "Month",
            start: currentDate.format("YYYY-MM-DD"),
            end: currentDate.format("YYYY-MM-DD"),
            numDays: 1,
        })

        // Move to the first day of the next month
        currentDate = currentDate.add(1, "month").startOf("month")
    }

    return events
}

function isCurrentDateInRange(
    startDateStr: string,
    endDateStr: string
): boolean {
    const currentDate = dayjs()
    const startDate = dayjs(startDateStr)
    const endDate = dayjs(endDateStr)

    // Check if the current date is the same as or after the start date and the same as or before the end date
    const isAfterOrSameAsStart =
        currentDate.isAfter(startDate, "day") ||
        currentDate.isSame(startDate, "day")
    const isBeforeOrSameAsEnd =
        currentDate.isBefore(endDate, "day") ||
        currentDate.isSame(endDate, "day")

    return isAfterOrSameAsStart && isBeforeOrSameAsEnd
}

export default TimelineChunkView
