import styles from "./JiraOverview.module.css"
import { JiraOverviewModel } from "../../Model/types"
import React, { useState } from "react"
import ProductEpicView from "./ProductEpicView"
import StatusGrid from "./StatusGrid"
import { getColorForStatus, isComplete, sortByStatus } from "./statusCategories"
import UnfoldIcon from "@mui/icons-material/UnfoldMore"
import FoldIcon from "@mui/icons-material/UnfoldLess"
import { motion } from "framer-motion"
import { EchartsReactWrapper } from "../../Components/EchartReactWrapper"

interface JiraOverviewProps {
    overview: JiraOverviewModel
}

const JiraOverview: React.FC<JiraOverviewProps> = ({ overview }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const productEpics = overview.productEpics.map((productEpic, index) => {
        return (
            <motion.div
                key={"motion-id-" + productEpic.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    duration: 0.3,
                    delay: index * 0.03,
                    ease: "easeOut",
                }}
            >
                <ProductEpicView productEpic={productEpic} key={productEpic.id} />
            </motion.div>
        )
    })

    const jiraEpics = overview.jiraEpics.filter((jiraEpic) => jiraEpic.status !== "Cancelled" && jiraEpic.status !== "Will Not Implement")

    const sortedEpics = sortByStatus(jiraEpics)

    const onExpandClick = () => {
        console.log(isExpanded)
        setIsExpanded(!isExpanded)
    }

    const content = isExpanded ? (
        <div className={styles.progressContainer}>{productEpics}</div>
    ) : (
        <div className={styles.progressContainer}>
            <StatusGrid title={"Jira Epics"} statusItems={sortedEpics} />
        </div>
    )

    const icon = isExpanded ? <FoldIcon className={styles.unfoldIcon} /> : <UnfoldIcon className={styles.unfoldIcon} />
    const toggleButtonText = isExpanded ? "Hide Product Epics" : "Show Product Epics"

    const jiraEpicTotal = jiraEpics.length
    const completeJiraEpics = jiraEpics.filter((jiraEpic) => isComplete(jiraEpic.status))
    const jiraEpicComplete = completeJiraEpics.length
    const jiraEpicPercent = Math.round((jiraEpicComplete / jiraEpicTotal) * 100)

    const productEpicTotal = overview.productEpics.length
    // const productEpicComplete = overview.productEpics.filter((productEpic) => productEpic.jiraEpics.every((jiraEpic) => isComplete(jiraEpic.status) && jiraEpic.isDueInPi)).length

    let productEpicComplete = 0
    for (const productEpic of overview.productEpics) {
        let isPEComplete = true
        for (const jiraEpic of productEpic.jiraEpics) {
            if (!isComplete(jiraEpic.status) && jiraEpic.isDueInPi) {
                isPEComplete = false
            }
        }
        if (isPEComplete) {
            productEpicComplete++
        }
    }

    const productEpicPercent = Math.round((productEpicComplete / productEpicTotal) * 100)

    const statusCounts = countUniquePropertyValues(jiraEpics, "status")
    const sortedStatusCounts = sortByStatus(statusCounts)

    const data = sortedStatusCounts.map((statusCount) => {
        const value = statusCount.count
        const name = statusCount.status
        const color = getColorForStatus(name)
        return {
            value: value,
            name: name,
            itemStyle: {
                color: color,
            },
        }
    })

    // make pie chart
    const options = {
        tooltip: {
            trigger: "item",
            confine: true,
        },
        series: [
            {
                type: "pie",
                radius: "90%",
                label: {
                    show: false,
                },
                data: data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
            },
        ],
    }

    const details = isExpanded ? (
        <div className={styles.details}>
            {/* <motion.div
                key={"motion-id-product"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    duration: 0.5,
                    delay: 0.1,
                    ease: "easeOut",
                }}
            >
                <div className={styles.detailsSubHeader}>Product Epics</div>
                <div className={styles.epicSummary}>
                    <div>
                        <div>
                            <b>Complete: </b>
                            {productEpicComplete}
                        </div>
                        <div>
                            <b>Total: </b>
                            {productEpicTotal}
                        </div>
                    </div>
                    <div className={styles.percentage}>{productEpicPercent}%</div>
                </div>
            </motion.div> */}

            <motion.div
                key={"motion-id-jira"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                    duration: 0.5,
                    delay: 0.2,
                    ease: "easeOut",
                }}
            >
                <div className={styles.detailsSubHeader}>Jira Epics</div>
                <div className={styles.epicSummary}>
                    <div>
                        <div>
                            <b>Complete: </b>
                            {jiraEpicComplete}
                        </div>
                        <div>
                            <b>Total: </b>
                            {jiraEpicTotal}
                        </div>
                    </div>
                    <div className={styles.percentage}>{jiraEpicPercent}%</div>
                </div>
            </motion.div>

            <div className={styles.pieChart}>
                <EchartsReactWrapper option={options as any} />
            </div>
        </div>
    ) : null
    // const details = null

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerTop}>
                    <div>{overview.name}</div>
                    <div className={styles.toggleButton} onClick={onExpandClick}>
                        <div className={styles.toggleButtonText}>{toggleButtonText}</div>
                        {icon}
                    </div>
                </div>
                {details}
            </div>
            {content}
        </div>
    )
}

type Item = { [key: string]: any }
function countUniquePropertyValues(items: Item[], propertyName: string): { status: string; count: number }[] {
    const valueCountMap: { [key: string]: number } = {} // Object to keep track of each unique value's count

    items.forEach((item) => {
        const propertyValue = item[propertyName]
        if (propertyValue in valueCountMap) {
            valueCountMap[propertyValue]++ // Increment the count if the value is already in the map
        } else {
            valueCountMap[propertyValue] = 1 // Initialize the count for new values
        }
    })

    // Transform the valueCountMap into an array of objects with the required structure
    const result = Object.keys(valueCountMap).map((key) => ({
        status: key,
        count: valueCountMap[key],
    }))

    return result
}

export default JiraOverview
