import { ReactNode, useCallback } from "react"
import styles from "./TierContainerNode.module.css"
import { Handle, Position } from "reactflow"
import React from "react"
import { RoleColorsContext } from "../ProcessMap"
import { ProcessNode, StandardRole } from "../processMapSlice"
import { find } from "@okta/okta-auth-js"
import { CSSProperties } from "styled-components"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

function TierContainerNode({ data }: any) {
    const onChange = useCallback((evt: any) => {
        console.log(evt.target.value)
    }, [])

    const roleColors = React.useContext(RoleColorsContext)
    const { label, isSelected, processNodes, nodeWidth, nodeSpacing, onClick, selectedRoleId } = data

    const onRoleBarClick = (role: StandardRole) => {
        onClick(role)
    }

    const roles = getUniqueRoles(processNodes)
    const roleBars = roles.map((role: StandardRole, roleIndex: number) => {
        let color = roleColors?.[role.id]

        function getSegments(trueBlocks: TrueBlock[], accountable: boolean = true): ReactNode[] {
            const segments = trueBlocks.map((block, index) => {
                let width = 0
                let pos = 0

                if (block.pos === 0) {
                    width = block.span * (nodeWidth + nodeSpacing) - nodeSpacing * 0.5
                } else {
                    pos = block.pos * (nodeWidth + nodeSpacing) - nodeSpacing * 0.5
                    width = block.span * (nodeWidth + nodeSpacing)
                }

                if (block.pos + block.span === processNodes.length) {
                    width += -nodeSpacing * 0.5
                }

                const delay = isSelected ? `${roleIndex * 0.04}s` : `${(roles.length - roleIndex) * 0.02}s`

                const style: CSSProperties = {
                    width: `${width}px`,
                    height: "100%",
                    display: "block",
                    position: "absolute",
                    top: "0px",
                    left: `${pos}px`,
                    opacity: accountable ? 1 : 0.3,
                    boxSizing: "border-box",
                    transitionDelay: delay,
                    background: accountable
                        ? color
                        : `repeating-linear-gradient(
                                            45deg,
                                            ${color},
                                            ${color} 18px,
                                            #46529800 18px,
                                            #46529800 20px
                                            )`,
                }
                return <div key={`segment-${role.id}-${index}`} className={styles.segment} style={style}></div>
            })
            return segments
        }

        const accountablePosData: boolean[] = processNodes.map((processNode: ProcessNode) => {
            const foundRole = processNode.accountableRoles.find((searchRole) => {
                return searchRole.id === role.id
            })
            if (foundRole) {
                return true
            }
            return false
        })
        const accountableTrueBlocks = findTrueBlocks(accountablePosData)
        const accountableSegments = getSegments(accountableTrueBlocks)

        const involvedPosData: boolean[] = processNodes.map((processNode: ProcessNode) => {
            const foundRole = processNode.involvedRoles.find((searchRole) => {
                return searchRole.id === role.id
            })
            if (foundRole) {
                return true
            }
            return false
        })
        const involvedTrueBlocks = findTrueBlocks(involvedPosData)
        const involvedSegments = getSegments(involvedTrueBlocks, false)

        let namePos = 0
        const lowestPos = findLowestPos([...accountableTrueBlocks, ...involvedTrueBlocks])
        if (lowestPos) {
            namePos = lowestPos * (nodeWidth + nodeSpacing)
            if (lowestPos > 0) {
                namePos -= nodeSpacing * 0.5
            }
        }

        const delay = isSelected ? `${roleIndex * 0.04}s` : `${(roles.length - roleIndex) * 0.02}s`

        const nameStyle: CSSProperties = {
            position: "relative",
            left: `${namePos}px`,
            transitionDelay: delay,
        }

        let roleBarStyle: CSSProperties = {}
        if (selectedRoleId) {
            if (selectedRoleId === role.id) {
                roleBarStyle = {
                    opacity: 1,
                    border: "4px solid white",
                }
            } else {
                roleBarStyle = {
                    opacity: 0.5,
                }
            }
        }

        return (
            <div
                key={role.id}
                className={styles.roleBar}
                style={roleBarStyle}
                onClick={(event) => {
                    event.stopPropagation()
                    onRoleBarClick(role)
                }}
            >
                {accountableSegments}
                {involvedSegments}
                <div className={styles.roleName} style={nameStyle}>
                    {role.name}
                </div>
            </div>
        )
    })

    const className = `${styles.container} ${isSelected ? styles.selected : ""}`

    return (
        <>
            <div className={className}>
                <div className={styles.title}>{label}</div>
                <div className={styles.roleContainer}>
                    <div className={styles.roleGroup}>
                        <div className={styles.roleBars}>{roleBars}</div>
                        <div className={styles.key}>
                            <div className={styles.accountable}>Accountable</div>
                            <div className={styles.involved}>Involved</div>
                        </div>
                    </div>
                </div>
                <div className={styles.expandButton}>
                    <ExpandMoreIcon style={{ width: "40px", height: "40px" }} />
                </div>
            </div>
        </>
    )
}

type TrueBlock = {
    pos: number
    span: number
}

function findTrueBlocks(arr: boolean[]): TrueBlock[] {
    const blocks: TrueBlock[] = []
    let startPos: number | null = null

    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === true) {
            if (startPos === null) {
                startPos = i
            }
        } else if (startPos !== null) {
            blocks.push({ pos: startPos, span: i - startPos })
            startPos = null
        }
    }

    // Handle the case where the last block of trues goes until the end of the array
    if (startPos !== null) {
        blocks.push({ pos: startPos, span: arr.length - startPos })
    }

    return blocks
}

function getUniqueRoles(nodes: ProcessNode[]): StandardRole[] {
    // Extract and flatten all roles from each ProcessNode
    const allRoles = nodes.flatMap((node) => [...node.accountableRoles, ...node.involvedRoles])

    // Use reduce to filter out duplicates based on the id property
    const uniqueRoles: { [id: string]: StandardRole } = allRoles.reduce((acc: any, role) => {
        if (!acc[role.id]) {
            acc[role.id] = role
        }
        return acc
    }, {})

    // Convert the object back to an array and sort alphabetically
    return Object.values(uniqueRoles).sort((a, b) => {
        if (a.name < b.name) {
            return -1
        }
        if (a.name > b.name) {
            return 1
        }
        return 0
    })
}

function findLowestPos(blocks: TrueBlock[]): number | null {
    if (blocks.length === 0) {
        return null
    }

    let minPos = blocks[0].pos

    for (let block of blocks) {
        if (block.pos < minPos) {
            minPos = block.pos
        }
    }

    return minPos
}

export default TierContainerNode
