/* eslint-disable */
import { useLayoutEffect, useRef, useState } from "react"
import { OrgChart } from "d3-org-chart"
import * as d3 from "d3"
import Tune from "../Assets/tune.svg"
import Store from "../Model/Store"
import Modal from "../Components/Modal"
import RoleDetailView from "../Components/RoleDetailView"
import ResourceDetailView from "../Components/ResourceDetailView"

function groupIdsByLevel<T extends { id: string; parentId?: string }>(
    items: T[]
): string[][] {
    const itemMap: { [parentId: string]: T[] } = {}
    const result: string[][] = []

    // Create a map to hold the items by their parent ID
    items.forEach((item) => {
        const parentId = item.parentId || ""
        itemMap[parentId] = itemMap[parentId] || []
        itemMap[parentId].push(item)
    })

    // Generate the result array
    let currentItems = [{ id: "" } as T] // Start with the root node
    while (currentItems.length > 0) {
        result.push(currentItems.map((item) => item.id))
        const childItems: T[] = []
        currentItems.forEach((parentItem) => {
            childItems.push(...(itemMap[parentItem.id] || []))
        })
        currentItems = childItems
    }

    return result
}

interface OrgChartComponentProps {
    data: any
    title: string
    store: Store
}

export const OrgChartComponent = (props: OrgChartComponentProps, ref: any) => {
    const d3Container = useRef(null)
    let chart = useRef<any>(null)
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const [selectedResourceId, setSelectedResourceId] = useState(undefined)
    const [selectedRoleId, setSelectedRoleId] = useState(undefined)

    const onNodeClick = (nodeId: any) => {
        const resource = props.store.getResourceById(nodeId)
        if (resource) {
            setSelectedResourceId(nodeId)
            setSelectedRoleId(undefined)
            return
        }
        const role = props.store.getRoleById(nodeId)
        if (role) {
            setSelectedRoleId(nodeId)
            setSelectedResourceId(undefined)
            return
        }
    }

    let levelIds: string[][] = []
    if (props.data) {
        levelIds = groupIdsByLevel(props.data)
    }

    // We need to manipulate DOM
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
        if (props.data && d3Container.current) {
            if (!chart.current) {
                chart.current = new OrgChart()
            }
            chart.current
                .container(d3Container.current)
                .data(props.data)
                .nodeWidth((_d: any) => 160)
                .nodeHeight((node: any) => {
                    return node.data.view.nodeHeight(node)
                })
                .onNodeClick((node: any) => {
                    onNodeClick(node)
                })
                .buttonContent(({ node, state }: any) => {
                    return `<div class="org-chart-node-button"> <span>${
                        node.children
                            ? `<i class="fas fa-angle-up"></i>`
                            : `<i class="fas fa-angle-down"></i>`
                    }</span> + ${node.data._totalSubordinates}  </div>`
                })
                .linkUpdate(function (d: any, i: any, arr: any) {
                    // @ts-ignore
                    d3.select(this)
                        .attr("stroke", "rgb(173, 173, 173)")
                        .attr("stroke-width", 1)
                })
                .nodeContent((node: any) => {
                    return node.data.view.nodeContent(node)
                })
                .render()
                .fit()

            // this crashes for some reason
            //
            // console.log("levelIds", levelIds)
            // if (levelIds) {
            //     levelIds[3].forEach((id) => {
            //         console.log("setExpanded", id)
            //         chart.current.setExpanded(id)
            //     })
            // }
            chart.current.render()
            chart.current.fit()
        }
    }, [props.data, d3Container.current])

    let compact = 0
    let index = 0

    const levelButtons = levelIds.map((ids, index) => {
        return (
            <button
                key={index}
                onClick={() => {
                    // turn them all off
                    props.data.forEach((item: any) => {
                        chart.current.setExpanded(item.id, false)
                    })
                    // turn on that level
                    ids.forEach((id) => {
                        chart.current.setExpanded(id)
                    })
                    chart.current.render()
                    chart.current.fit()
                }}
            >
                Show Level {index}
            </button>
        )
    })
    levelButtons.splice(0, 2)

    const title = props.title ?? "Org Chart"

    const onModalClose = () => {
        setSelectedResourceId(undefined)
        setSelectedRoleId(undefined)
    }

    const resource = selectedResourceId
        ? props.store.getResourceById(selectedResourceId)
        : undefined
    const role = selectedRoleId
        ? props.store.getRoleById(selectedRoleId)
        : undefined

    const modal =
        selectedResourceId && resource ? (
            <Modal onClose={onModalClose}>
                <ResourceDetailView
                    resource={resource}
                    onSelectResource={onNodeClick}
                />
            </Modal>
        ) : selectedRoleId && role ? (
            <Modal onClose={onModalClose}>
                <RoleDetailView
                    role={role}
                    store={props.store}
                    onSelectResource={onNodeClick}
                />
            </Modal>
        ) : null

    return (
        <div className="org-chart">
            {modal}
            <div className="org-chart-title">{title}</div>
            <div id="org-chart-menu">
                <div className="org-chart-menu-toggle">
                    <div
                        className="org-chart-menu-button"
                        onClick={() => {
                            setIsMenuVisible(!isMenuVisible)
                        }}
                    >
                        <img
                            className="menu-icon"
                            src={Tune}
                            alt="alt tag"
                        ></img>
                    </div>
                </div>
                <div
                    className={
                        "org-chart-menu-content " +
                        (!isMenuVisible ? "hidden" : "")
                    }
                >
                    <button
                        onClick={() => {
                            chart.current
                                .compact(!!(compact++ % 2))
                                .render()
                                .fit()
                        }}
                    >
                        Toggle Compact
                    </button>
                    <button
                        onClick={() => {
                            chart.current
                                .layout(
                                    ["left", "bottom", "right", "top"][
                                        index++ % 4
                                    ]
                                )
                                .render()
                                .fit()
                        }}
                    >
                        Rotate Layout
                    </button>
                    <br />
                    {levelButtons}
                </div>
            </div>
            <div className="d3-container" ref={d3Container} />
        </div>
    )
}
