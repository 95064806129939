import React, { useState, useEffect, CSSProperties, ReactNode } from "react"
import styles from "./UserResearch.module.css"
import { ResearchProject, fetchUserResearch, selectCompletedProjects, selectLoading, selectProjects, selectTagGroups } from "./userResearchSlice"
import { useDispatch } from "../Model/hooks"
import { useSelector } from "react-redux"
import LoadingStatus from "../Components/LoadingStatus/LoadingStatus"
import ProjectCard from "./ProjectCard"
import { useNavigate, useParams } from "react-router-dom"
import ProjectDetails from "./ProjectDetails"
import { AnimatePresence, motion } from "framer-motion"
import Color from "color"
import { Resource } from "../Model/types"
import ResourceDetailView from "../Components/ResourceDetailView"
import { selectResources } from "../Model/slices/resourcesSlice"
import Modal from "../Components/Modal"

interface UserResearchProps {}

export const TagColorsContext = React.createContext<{ [id: string]: string } | undefined>(undefined)

const UserResearch: React.FC<UserResearchProps> = () => {
    const loading = useSelector(selectLoading)
    const projects = useSelector(selectCompletedProjects)
    const tagGroups = useSelector(selectTagGroups)

    const resources = useSelector(selectResources)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { projectId } = useParams<{ projectId?: string }>()
    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [hoveredProject, setHoveredProject] = useState<ResearchProject | undefined>()
    const [scrollPosition, setScrollPosition] = useState(0)
    const [modalView, setModalView] = useState<ReactNode | undefined>()

    const filteredProjects = filterProjectsByTags(projects, selectedTags)

    const storeScrollPosition = () => {
        const scrollPos = document.getElementById("projectScroll")?.scrollTop
        if (scrollPos !== undefined) {
            setScrollPosition(scrollPos)
        }
    }

    const restoreScrollPosition = () => {
        // we must wait untill after the transition to set the scroll position
        setTimeout(() => {
            const element = document.getElementById("projectScroll")
            if (element) {
                element.scrollTop = scrollPosition
            }
        }, 210)
    }

    useEffect(() => {
        if (!projectId) {
            restoreScrollPosition()
        }
    }, [projectId])

    useEffect(() => {
        if (loading === "idle") {
            dispatch(fetchUserResearch())
        }
    }, [loading, dispatch])

    if (loading !== "loaded") return <LoadingStatus />

    const onProjectSelect = (project: ResearchProject) => {
        setHoveredProject(undefined)
        storeScrollPosition()
        navigate(`/user-research/project/${project.id}`)
    }

    const onProjectHover = (project: ResearchProject) => {
        setHoveredProject(project)
    }

    const onProjectHoverEnd = () => {
        setHoveredProject(undefined)
    }

    const onResourceClick = (resource: Resource) => {
        setModalView(
            <ResourceDetailView
                resource={resource}
                onSelectResource={(id) => {
                    const newResource = resources.find((r) => r.id === id)
                    if (newResource) {
                        onResourceClick(newResource)
                    }
                }}
            />
        )
    }

    const onModalClose = () => {
        setModalView(undefined)
    }

    const teams = getUniqueSortedTeams(filteredProjects)
    const teamRows = teams.map((team) => {
        const teamProjects = filterProjectsByTeam(filteredProjects, team)
        const projectCards = teamProjects.map((project) => {
            return <ProjectCard key={project.id} project={project} onSelect={onProjectSelect} onHover={onProjectHover} onHoverEnd={onProjectHoverEnd} />
        })
        return (
            <div className={styles.teamRow} key={"id-" + team}>
                <div className={styles.teamRowTitle}>{team}</div>
                <div className={styles.projectCardsContainer}>{projectCards}</div>
            </div>
        )
    })

    const flatTags = tagGroups.flatMap((tg) => tg.tags)

    const tagColors: { [id: string]: string } = {}
    const tagCount = flatTags.length
    let roleIndex = 0
    for (const tag of flatTags) {
        const hue = (roleIndex / tagCount) * 360
        const color = Color(`hsl(${hue}, 50%, 70%)`)
        roleIndex++
        tagColors[tag.name] = color.hex()
    }

    const toggleTag = (tagName: string) => {
        setSelectedTags((prevTags) => {
            // Check if the tag already exists
            const index = prevTags.indexOf(tagName)

            if (index > -1) {
                // Tag exists, so we remove it
                // Filter out the tag from the array
                return prevTags.filter((t) => t !== tagName)
            } else {
                // Tag doesn't exist, so we add it
                // Return a new array with the tag added
                return [...prevTags, tagName]
            }
        })
    }

    const onDetailTagSelect = (tagName: string) => {
        setSelectedTags([tagName])
        navigate(`/user-research/`)
        setScrollPosition(0)
        restoreScrollPosition()
    }

    const tagGroupViews = tagGroups.map((tagGroup) => {
        const tagViews = tagGroup.tags.map((tag) => {
            const onTagClick = () => {
                toggleTag(tag.name)
            }

            let opacity = 1
            let backgroundColor = tagColors[tag.name]
            if (selectedTags.length > 0) {
                if (selectedTags.includes(tag.name)) {
                    backgroundColor = "white"
                } else if (isTestStringInProjects(projects, selectedTags, tag.name)) {
                    opacity = 1
                } else {
                    opacity = 0.3
                }
            }

            if (hoveredProject) {
                if (hoveredProject.tags?.includes(tag.name)) {
                    opacity = 1
                } else {
                    opacity = 0.3
                }
            }

            const style: CSSProperties = {
                backgroundColor: backgroundColor,
                border: `2px solid ${tagColors[tag.name]}`,
                opacity: opacity,
            }

            return (
                <div key={tag.id} className={styles.tag} onClick={onTagClick} style={style}>
                    {tag.name}
                </div>
            )
        })

        return (
            <div key={tagGroup.name} className={styles.tagGroup}>
                <div className={styles.tagGroupTitle}>{tagGroup.name}</div>
                <div className={styles.tagContainer}>{tagViews}</div>
            </div>
        )
    })

    const selectedProject = projects.find((project) => project.id === projectId)
    const relatedProjects = getRelatedProjects(selectedProject, projects)

    return (
        <TagColorsContext.Provider value={tagColors}>
            {modalView && <Modal onClose={onModalClose}>{modalView}</Modal>}
            <AnimatePresence mode="wait">
                {selectedProject ? (
                    <motion.div
                        key="projectDetails" // Unique key for this condition
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <div>
                            <ProjectDetails project={selectedProject} relatedProjects={relatedProjects} onTagSelect={onDetailTagSelect} onResourceClick={onResourceClick} />
                        </div>
                    </motion.div>
                ) : (
                    <motion.div
                        key="teamRows" // Unique key for this condition
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className={styles.container}
                    >
                        <div className={styles.filterContainer}>
                            <div className={styles.filterHeader}>Topics</div>
                            <div className={styles.tagGroups}>{tagGroupViews}</div>
                        </div>
                        <div id="projectScroll" className={styles.teamsContainer}>
                            {teamRows}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </TagColorsContext.Provider>
    )
}

function getRelatedProjects(sourceProject: ResearchProject | undefined, inProjects: ResearchProject[]): ResearchProject[] {
    if (!sourceProject || !sourceProject.tags) {
        return []
    }

    const sourceTags = sourceProject.tags || []
    const seenProjectIds = new Set([sourceProject.id]) // Initialize with sourceProject's ID

    const relatedProjects = inProjects.filter((project) => {
        const projectTags = project.tags || []

        // Check for shared tags and ensure the project isn't the sourceProject or a duplicate
        const isRelatedAndUnique = projectTags.some((tag) => sourceTags.includes(tag)) && !seenProjectIds.has(project.id)

        if (isRelatedAndUnique) {
            seenProjectIds.add(project.id) // Mark this project ID as seen
        }

        return isRelatedAndUnique
    })

    return relatedProjects
}

function getUniqueSortedTeams(projects: ResearchProject[]): string[] {
    const allTeams = projects.flatMap((project) => project.teams) // Step 1: Flatten the array
    const uniqueTeams = new Set(allTeams) // Step 2: Remove duplicates
    const sortedUniqueTeams = Array.from(uniqueTeams).sort() // Step 3: Sort alphabetically

    return sortedUniqueTeams
}

// function getUniqueSortedTags(projects: ResearchProject[]): string[] {
//     const allTags = projects.flatMap((project) => project.tags) // Step 1: Flatten the array
//     const uniqueTags = new Set(allTags) // Step 2: Remove duplicates
//     const sortedUniqueTags = Array.from(uniqueTags).sort() // Step 3: Sort alphabetically
//     const returnTags = sortedUniqueTags.filter((tag) => tag !== undefined)

//     return returnTags
// }

function filterProjectsByTeam(projects: ResearchProject[], team: string): ResearchProject[] {
    return projects.filter((project) => project.teams?.includes(team))
}

function isTestStringInProjects(projects: ResearchProject[], selectedTags: string[], testString: string) {
    // Iterate over the array of projects
    return projects.some((project) => {
        // Safely check if the project's tags include the testString
        const includesTestString = project.tags?.includes(testString)

        // Check if the project's tags include all of the selectedTags
        const includesAllSelectedTags = selectedTags.every((tag) => project.tags?.includes(tag))

        // Return true if the testString exists alongside all of the selectedTags in at least one project
        return includesTestString && includesAllSelectedTags
    })
}

function filterProjectsByTags(projects: ResearchProject[], selectedTags: string[]): ResearchProject[] {
    return projects.filter((project) => {
        // Check if all selected tags are included in the project's tags
        return selectedTags.every((selectedTag) => project.tags?.includes(selectedTag))
    })
}

export default UserResearch
