import React from "react"
import ProfileImage from "../../Components/ProfileImage"
import { Role } from "../../Model/types"
import styles from "./RoleView.module.css"
import Pill from "../../Components/Pill"

interface RoleViewProps {
    role: Role
    onClick?: (role: Role) => void
}

const RoleView: React.FC<RoleViewProps> = ({ role, onClick }) => {
    // const tagClasses = `${styles.tag} ${styles.open}`
    const tag = role.resource ? null : role.interimResource ? <Pill text="Interim" color="rgb(211, 126, 16)" /> : <Pill text="Open" color="rgb(173, 41, 41)" />
    const resource = role.resource ?? role.interimResource
    const imageUrl = resource?.slackUser?.profile.image_192
    const name = role.resource?.name ?? role.interimResource?.name ?? ""

    return (
        <div
            className={styles.roleView}
            onClick={(event) => {
                event.stopPropagation()
                onClick?.(role)
            }}
        >
            <div className={styles.roleImage}>
                <ProfileImage imageUrl={imageUrl} />
            </div>
            <div>
                <div>
                    <b>{role.roleName}</b>
                </div>
                <div style={{ display: "flex", marginTop: "2px", alignItems: "flex-end", gap: "5px" }}>
                    {tag} {name}
                </div>
            </div>
        </div>
    )
}

export default RoleView
