import React, { CSSProperties, useContext } from "react"
import styles from "./PercentConnectedTile.module.css"
import baseStyles from "../Tile.module.css"
import { CountSummary, Product } from "../Model/types"
import { EchartsReactWrapper } from "../../Components/EchartReactWrapper"
import { generateGradientColors } from "../utils"
import { EChartOption } from "echarts"
import { ProductColorsContext } from "../Dashboard"

export interface PercentConnectedTileProps {
    size: { width: number; height: number }
    countSummary: CountSummary
}

const PercentConnectedTile: React.FC<PercentConnectedTileProps> = ({ size, countSummary }) => {
    const layoutStyle = size.width > 300 ? styles.largeLayout : styles.smallLayout

    const boxSize = calculateBoxDimensions(size.width, size.height, countSummary.products.length)
    const boxStyle: CSSProperties = {
        width: `${boxSize.width}px`,
        height: `${boxSize.height}px`,
    }

    const productColors = useContext(ProductColorsContext)

    const pieCharts = countSummary.products.map((product, index) => {
        const options = getChartOptions(product, productColors[product.key])
        return (
            <div key={"pie-percent-" + product.key} style={boxStyle} className={styles.pieContainer}>
                <div style={{ flexGrow: 1 }}>
                    <div className={styles.chart}>
                        <EchartsReactWrapper option={options as any} />
                    </div>
                </div>

                <div>{product.name}</div>
            </div>
        )
    })

    return (
        <div className={styles.percentConnectedTile}>
            <div className={baseStyles.title}>Percent of Devices Connected</div>
            <div className={styles.pieCharts}>{pieCharts}</div>
        </div>
    )
}

interface BoxDimensions {
    width: number
    height: number
}

function calculateBoxDimensions(containerWidth: number, containerHeight: number, numberOfBoxes: number): BoxDimensions {
    let boxesPerRow = Math.ceil(Math.sqrt(numberOfBoxes))
    let boxWidth = containerWidth / boxesPerRow
    let boxHeight = boxWidth
    let rowsNeeded = Math.ceil(numberOfBoxes / boxesPerRow)

    while (rowsNeeded * boxHeight > containerHeight) {
        boxesPerRow++
        boxWidth = containerWidth / boxesPerRow
        boxHeight = boxWidth
        rowsNeeded = Math.ceil(numberOfBoxes / boxesPerRow)
    }

    return {
        width: boxWidth,
        height: boxHeight,
    }
}

function getChartOptions(product: Product, color: string): any {
    const latestCount = product.latestCount

    let connectedCount = 0
    let disconnectedCount = 0
    let totalCount = 0

    if (latestCount) {
        totalCount = latestCount.totalCount
        connectedCount = latestCount.connectedCount
        disconnectedCount = totalCount - connectedCount
    }

    const data =
        connectedCount > 0
            ? [
                  { value: connectedCount, name: "Connected", itemStyle: { color: color } },
                  { value: disconnectedCount, name: "Disconnected", itemStyle: { color: "rgba(255, 255, 255, 0.1)" } },
              ]
            : [{ value: 0, name: "No Data", itemStyle: { color: "rgba(255, 255, 255, 0.1)" } }]

    // I want to put this percent in the middle of the pie chart as a label
    let percent = Math.floor((connectedCount / totalCount) * 100)
    if (totalCount === 0 || connectedCount === 0) {
        percent = 0
    }

    const percentString = percent === 0 ? "" : `${percent}%`

    const options: EChartOption = {
        textStyle: {
            fontFamily: "GibsonCustom",
        },
        tooltip: {
            trigger: "item",
        },
        series: [
            {
                type: "pie",
                radius: ["50%", "80%"],
                avoidLabelOverlap: false,
                label: {
                    show: true,
                    position: "center",
                    formatter: () => percentString,
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "white",
                },
                labelLine: {
                    show: false,
                },
                data: data,
            },
        ],
    }

    return options
}

export default PercentConnectedTile
