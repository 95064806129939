import React, { useState } from "react"
import styles from "./AddTile.module.css"
import { MoreHoriz } from "@mui/icons-material"
import { Edit, EditOff, Addchart } from "@mui/icons-material"
import { CountSummary } from "../Model/types"
import StackedAreaTile from "../Tiles/StackedAreaTile"
import ProductTotalsBarTile from "../Tiles/ProductTotalsBarTile"
import GrandTotalTile from "../Tiles/GrandTotalTile"
import { TileType } from "../Utils/types"
import PercentConnectedTile from "../Tiles/PercentConnectedTile"
import FeatureAvailabilityTile from "../Tiles/FeatureAvailabilityTile"
import CirclesTile from "../Tiles/CirclePacking/CirclesTile"
import HistoricalCountTile from "../Tiles/HistoricalCountTile"
import HistoricalGridTile from "../Tiles/HistoricalGridTile"
import CategoryPieTile from "../Tiles/CategoryPieTile"

export interface AddTileProps {
    countSummary: CountSummary
    addTile: (type: TileType) => void
}

const AddTile: React.FC<AddTileProps> = ({ countSummary, addTile }) => {
    const width = 900
    const height = 600

    const stackedArea = (
        <div key="stacked-area" className={styles.tileThumbnail} onClick={() => addTile(TileType.StackedArea)}>
            <StackedAreaTile size={{ width: width, height: height }} countSummary={countSummary} />
        </div>
    )

    const totalBars = (
        <div key="total-bars" className={styles.tileThumbnail} onClick={() => addTile(TileType.ProductTotals)}>
            <ProductTotalsBarTile size={{ width: width, height: height }} countSummary={countSummary} />
        </div>
    )

    const grandTotal = (
        <div key="grand-total" className={styles.tileThumbnail} onClick={() => addTile(TileType.GrandTotal)}>
            <GrandTotalTile countSummary={countSummary} size={{ width: width, height: height }} />
        </div>
    )

    const spacer = (
        <div key="spacer" className={styles.tileThumbnail} onClick={() => addTile(TileType.Spacer)}>
            <div className={styles.spacerThumbnail}>Blank Space</div>
        </div>
    )

    const percentConnected = (
        <div key="percent-connected" className={styles.tileThumbnail} onClick={() => addTile(TileType.PercentConnected)}>
            <PercentConnectedTile size={{ width: width, height: height }} countSummary={countSummary} />
        </div>
    )

    const featureAvailability = (
        <div key="feature-availability" className={styles.tileThumbnail} onClick={() => addTile(TileType.FeatureAvailability)}>
            <FeatureAvailabilityTile size={{ width: width, height: height }} countSummary={countSummary} />
        </div>
    )

    const deviceRatio = (
        <div key="device-ratio" className={styles.tileThumbnail} onClick={() => addTile(TileType.DeviceRatio)}>
            <CirclesTile size={{ width: width, height: height }} countSummary={countSummary} />
        </div>
    )

    const historicalCount = (
        <div key="historical-count" className={styles.tileThumbnail} onClick={() => addTile(TileType.HistoricalCount)}>
            <HistoricalCountTile size={{ width: width, height: height }} countSummary={countSummary} params={{ productKey: countSummary.products[0].key }} />
        </div>
    )

    const historicalGrid = (
        <div key="historical-grid" className={styles.tileThumbnail} onClick={() => addTile(TileType.HistoricalGrid)}>
            <HistoricalGridTile size={{ width: width, height: height }} countSummary={countSummary} />
        </div>
    )

    const categoryPie = (
        <div key="category-pie" className={styles.tileThumbnail} onClick={() => addTile(TileType.CategoryPie)}>
            <CategoryPieTile size={{ width: width, height: height }} countSummary={countSummary} />
        </div>
    )

    const tiles = [grandTotal, stackedArea, totalBars, percentConnected, featureAvailability, deviceRatio, historicalGrid, historicalCount, categoryPie, spacer]

    return (
        <div className={styles.addTile}>
            <div className={styles.title}>Select a Tile to Add</div>
            <div className={styles.tiles}>{tiles}</div>
        </div>
    )
}

export default AddTile
