import Store from "../Model/Store"
import { renderToString } from "react-dom/server"
import ProfilePic from "../Assets/profilePlaceholder.png"

export interface NodeView {
    nodeContent: (node: any) => string
    nodeHeight: (node: any) => number
}

export function createNodeView(item: any, store: Store): NodeView {
    // first check to see if the item is coming from the team table
    // return reporting structure view - i.e. resource
    if (item.fields.Type === "Resource") {
        const name = item.fields["Name"]
        const slackUser = store.getSlackUserForEmail(
            item.fields["Carrier Email"]
        )

        // const imageUrl = `${slackUser?.profile.image_192}${slackUser?.team_id}-${slackUser?.id}-${slackUser?.profile.avatar_hash}-192`

        const profileImage = slackUser ? (
            <img
                className="org-chart-node-image"
                src={slackUser.profile.image_192}
                alt="alt tag"
            ></img>
        ) : (
            <div>
                <img
                    className="org-chart-node-image"
                    src={ProfilePic}
                    alt="alt tag"
                ></img>
            </div>
        )
        return {
            nodeContent: (node: any) => {
                const fields = node.data.fields
                return renderToString(
                    <div className="org-chart-node-resource">
                        <div className="org-chart-node-image-container">
                            {profileImage}
                        </div>
                        <div className="org-chart-node-resource-content">
                            <b>{name}</b>
                            <br></br>
                            <div style={{ fontSize: "10px" }}>
                                {fields["Title"]}
                            </div>
                        </div>
                    </div>
                )
            },
            nodeHeight: (node: any) => {
                return 120
            },
        }
    }

    if (item.fields.Type === "Role") {
        return {
            nodeContent: (node: any) => {
                const fields = node.data.fields

                const resources = fields.Resources ?? [0]
                const resourceId = resources[0]
                const resource = store.getResourceById(resourceId)
                let name = resource?.name
                const slackUser = resource?.slackUser

                const profileImage = slackUser ? (
                    <img
                        className="org-chart-node-image"
                        src={slackUser.profile.image_192}
                        alt="alt tag"
                    ></img>
                ) : (
                    <div>
                        <img
                            className="org-chart-node-image"
                            src={ProfilePic}
                            alt="alt tag"
                        ></img>
                    </div>
                )

                return renderToString(
                    <div className="org-chart-node-resource">
                        <div className="org-chart-node-image-container">
                            {profileImage}
                        </div>
                        <div className="org-chart-node-resource-content">
                            <b>{fields.Name}</b>
                            <br></br>
                            {name}
                        </div>
                    </div>
                )
            },
            nodeHeight: (node: any) => {
                return 120
            },
        }
    }

    if (
        item.fields.Type === "Pod" ||
        item.fields.Type === "Area Path" ||
        item.fields.Type === "Value Stream" ||
        item.fields.Type === "Platform" ||
        item.fields.Type === "Domain" ||
        item.fields.Type === "Group" ||
        item.fields.Type === "Service"
    ) {
        const groupType = item.fields.Type
        const role = item.fields.Name
        return {
            nodeContent: (node: any) => {
                return renderToString(
                    <div className="org-chart-node-container">
                        <b>{role}</b>
                        <br></br>
                        {groupType}
                    </div>
                )
            },
            nodeHeight: (node: any) => {
                return 70
            },
        }
    }

    // default node view
    return {
        nodeContent: (node: any) => {
            const fields = node.data.fields
            const content = fields.Name ?? "--"
            return renderToString(
                <div className="org-chart-node-container">
                    <b>{content}</b>
                </div>
            )
        },
        nodeHeight: (node: any) => {
            return 60
        },
    }
}
