import { Layout } from "react-grid-layout"
import { v4 as uuidv4 } from "uuid"
import BasicTile from "../Tiles/BasicTile"
import GrandTotalTile from "../Tiles/GrandTotalTile"
import StackedAreaTile from "../Tiles/StackedAreaTile"
import ProductTotalsBarTile from "../Tiles/ProductTotalsBarTile"
import { CountSummary } from "../Model/types"
import { TileData, TileDataParams, TileType } from "./types"
import PercentConnectedTile from "../Tiles/PercentConnectedTile"
import FeatureAvailabilityTile from "../Tiles/FeatureAvailabilityTile"
import CirclesTile from "../Tiles/CirclePacking/CirclesTile"
import HistoricalCountTile from "../Tiles/HistoricalCountTile"
import HistoricalGridTile from "../Tiles/HistoricalGridTile"
import CategoryPieTile from "../Tiles/CategoryPieTile"

export const makeNewTileData = (type: TileType, tileCount: number, countSummary: CountSummary): TileData => {
    const newKey = `id-${uuidv4()}`
    const x = (tileCount * 2) % 12
    const y = Infinity
    let layout: Layout
    let params: TileDataParams | undefined

    switch (type) {
        case TileType.Basic:
            layout = { i: newKey, x: x, y: y, w: 2, h: 4, minW: 2, minH: 4 }
            break
        case TileType.Spacer:
            layout = { i: newKey, x: x, y: y, w: 4, h: 4, minW: 1, minH: 1 }
            break
        case TileType.GrandTotal:
            layout = { i: newKey, x: x, y: y, w: 8, h: 6, minW: 2, minH: 4 }
            break
        case TileType.StackedArea:
            layout = { i: newKey, x: x, y: y, w: 8, h: 16, minW: 2, minH: 4 }
            break
        case TileType.ProductTotals:
            layout = { i: newKey, x: x, y: y, w: 8, h: 18, minW: 2, minH: 4 }
            break
        case TileType.PercentConnected:
            layout = { i: newKey, x: x, y: y, w: 8, h: 18, minW: 2, minH: 4 }
            break
        case TileType.FeatureAvailability:
            layout = { i: newKey, x: x, y: y, w: 8, h: 18, minW: 2, minH: 4 }
            break

        case TileType.DeviceRatio:
            layout = { i: newKey, x: x, y: y, w: 8, h: 18, minW: 2, minH: 4 }
            break

        case TileType.HistoricalCount:
            layout = { i: newKey, x: x, y: y, w: 4, h: 4, minW: 2, minH: 4 }
            params = { productKey: countSummary.products[0].key }
            break

        case TileType.HistoricalGrid:
            layout = { i: newKey, x: x, y: y, w: 8, h: 18, minW: 2, minH: 4 }
            break

        case TileType.CategoryPie:
            layout = { i: newKey, x: x, y: y, w: 8, h: 18, minW: 2, minH: 4 }
            break

        default:
            layout = { i: newKey, x: x, y: y, w: 2, h: 4, minW: 2, minH: 4 }
            break
    }

    const newTile: TileData = {
        type: type,
        layout: layout,
        params: params,
    }

    return newTile
}

export const renderTileContent = (tile: TileData, size: any, countSummary: CountSummary) => {
    switch (tile.type) {
        case TileType.Basic:
            return <BasicTile size={size} />
        case TileType.GrandTotal:
            return <GrandTotalTile size={size} countSummary={countSummary} />
        case TileType.StackedArea:
            return <StackedAreaTile size={size} countSummary={countSummary} />
        case TileType.ProductTotals:
            return <ProductTotalsBarTile size={size} countSummary={countSummary} />
        case TileType.Spacer:
            return <div></div>
        case TileType.PercentConnected:
            return <PercentConnectedTile size={size} countSummary={countSummary} />
        case TileType.FeatureAvailability:
            return <FeatureAvailabilityTile size={size} countSummary={countSummary} />
        case TileType.DeviceRatio:
            return <CirclesTile size={size} countSummary={countSummary} />
        case TileType.HistoricalCount:
            return tile.params ? <HistoricalCountTile size={size} countSummary={countSummary} params={tile.params} /> : null
        case TileType.HistoricalGrid:
            return <HistoricalGridTile size={size} countSummary={countSummary} />
        case TileType.CategoryPie:
            return <CategoryPieTile size={size} countSummary={countSummary} />
        default:
            return <div>Unsupported tile type</div>
    }
}
