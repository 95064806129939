import ProgressBar from "../../Components/ProgressBar"
import styles from "./ProductEpicView.module.css"
import { JiraOverviewModel, ProductEpic } from "../../Model/types"
import SteppedProgressBar from "../../Components/SteppedProgressBar"
import React from "react"
import { getColorForStatus, sortByStatus, statusCategories } from "./statusCategories"
import StatusGrid from "./StatusGrid"

interface ProductEpicViewProps {
    productEpic: ProductEpic
}

const ProductEpicView: React.FC<ProductEpicViewProps> = ({ productEpic }) => {
    const sortedEpics = sortByStatus(productEpic.jiraEpics)

    const onTitleClick = () => {
        const issueKey = productEpic.issueKey
        const url = `https://carrier-digital.atlassian.net/browse/${issueKey}`
        window.open(url, "_blank")
    }

    const title = (
        <div className={styles.title} onClick={onTitleClick}>
            {productEpic.issueKey}: {productEpic.summary}
        </div>
    )

    const subtitle = (
        <div className={styles.subtitle}>
            <div>
                <b>Rank:</b> {productEpic.rank ?? "--"}
            </div>
            <div>
                <b>Pod:</b> {productEpic.pod ?? "--"}
            </div>
            <div>
                <b>Assignee:</b> {productEpic.assignee ?? "--"}
            </div>
        </div>
    )

    return (
        <div className={styles.container}>
            <StatusGrid title={title} subtitle={subtitle} statusItems={sortedEpics} />
        </div>
    )
}

export default ProductEpicView
