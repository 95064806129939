import dayjs from "dayjs"
import styles from "./TimelineRow.module.css"
import { CSSProperties, ReactNode } from "react"
import { CalendarEvent, selectEventCategories } from "../foundryCalendarSlice"
import { Tooltip, TooltipProps, Zoom, tooltipClasses } from "@mui/material"
import { styled } from "@mui/material/styles"
import React from "react"
import { useSelector } from "react-redux"

interface TimelineRowProps {
    startDate: string
    endDate: string
    calendarEvents?: CalendarEvent[]
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "white",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(14),
        border: "1px solid #dadde9",
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
    },
}))

const TimelineRowView: React.FC<TimelineRowProps> = ({
    startDate,
    endDate,
    calendarEvents,
}) => {
    const eventCategories = useSelector(selectEventCategories)

    const tooltipDefaultProps: Partial<TooltipProps> = {
        enterDelay: 10,
        placement: "top",
        arrow: false,
        disableInteractive: true,
    }

    const numDays = daysBetweenDates(startDate, endDate)
    const numEventDays = calendarEvents ? getTotalDays(calendarEvents) : 0
    const numEmptyDays = numDays - numEventDays

    let emptyBlocks: ReactNode = []
    if (numEmptyDays > 0) {
        emptyBlocks = Array(numDays)
            .fill(0)
            .map((_, index) => {
                let bgColor = isWeekend(startDate, index)
                    ? "rgb(177, 177, 177)"
                    : "rgb(147, 147, 147)"
                if (isToday(startDate, index)) {
                    bgColor = "black"
                }
                const isFuture = isFutureDate(startDate, index)
                let style: CSSProperties =
                    numEventDays > 0
                        ? {
                              backgroundColor: "clear",
                              gridColumn: `${index + 1} / span ${1}`,
                          }
                        : {
                              backgroundColor: bgColor,
                              gridColumn: `${index + 1} / span ${1}`,
                              opacity: isFuture ? 0.5 : 1.0,
                          }

                const tooltipContent = getEmptyDayTooltip(startDate, index)

                if (numEventDays === 0) {
                    return (
                        <HtmlTooltip
                            key={index}
                            title={
                                <React.Fragment>
                                    {tooltipContent}
                                </React.Fragment>
                            }
                            {...tooltipDefaultProps}
                            TransitionComponent={Zoom}
                        >
                            <div
                                key={"id-block-" + index}
                                className={styles.block}
                                style={style}
                            ></div>
                        </HtmlTooltip>
                    )
                } else {
                    return <div key={"id-block-" + index} style={style}></div>
                }
            })
    }

    let eventBlocks: ReactNode = []
    if (calendarEvents) {
        eventBlocks = calendarEvents.map((event) => {
            const adjustedEvent = adjustEventWithinDateRange(
                event,
                startDate,
                endDate
            )

            const startOffset = daysBetweenDates(startDate, adjustedEvent.start)
            const span = adjustedEvent.numDays
            const color =
                eventCategories.find((category) => category.name === event.type)
                    ?.color ?? "gray"
            const style: CSSProperties =
                event.type === "Month"
                    ? {
                          gridColumn: `${startOffset} / span ${span}`,
                          backgroundColor: "clear",
                          height: "20px",
                          justifyContent: "flex-start",
                          fontSize: "14px",
                          fontWeight: "bold",
                      }
                    : {
                          gridColumn: `${startOffset} / span ${span}`,
                          backgroundColor: color,
                          height: "40px",
                          borderRadius: "4px",
                          border: `2px solid ${color}`,
                      }
            return (
                <HtmlTooltip
                    key={"tooltip" + event.id}
                    title={
                        <React.Fragment>
                            {getEventTooltip(event)}
                        </React.Fragment>
                    }
                    {...tooltipDefaultProps}
                    TransitionComponent={Zoom}
                >
                    <div
                        key={"id-block-" + event.id}
                        className={styles.block}
                        style={style}
                    >
                        {event.type === "Month" && (
                            <div className={styles.vertLine}></div>
                        )}
                        {adjustedEvent.numDays > 1 ||
                        adjustedEvent.type === "Month"
                            ? event.name
                            : null}
                    </div>
                </HtmlTooltip>
            )
        })
    }

    const allBlocks = [...eventBlocks, ...emptyBlocks]

    return <div className={styles.container}>{allBlocks}</div>
}

function getEventTooltip(event: CalendarEvent): ReactNode {
    return (
        <div className={styles.eventTooltip}>
            <div>{event.name}</div>
            <div>{formatDateRange(event.start, event.end)}</div>
        </div>
    )
}

function getEmptyDayTooltip(
    startDateString: string,
    offset: number
): ReactNode {
    // Parse the start date
    const startDate = dayjs(startDateString)

    // Add the offset days
    const futureDate = startDate.add(offset, "day")

    // Format and return the date as a string
    const futureDateString = futureDate.format("ddd, MMM DD")

    return <div>{futureDateString}</div>
}

function isWeekend(startDateString: string, offset: number): boolean {
    // Parse the start date
    const startDate = dayjs(startDateString)

    // Add the offset days
    const futureDate = startDate.add(offset, "day")

    const dayOfWeek = futureDate.day()
    return dayOfWeek === 0 || dayOfWeek === 6
}

function isToday(startDateString: string, offset: number): boolean {
    // Parse the start date
    const startDate = dayjs(startDateString)

    // Add the offset days
    const futureDate = startDate.add(offset, "day")

    // Check if the future date is today
    const isToday = futureDate.isSame(dayjs(), "day")

    return isToday
}

function isFutureDate(startDateString: string, offset: number): boolean {
    // Parse the start date
    const startDate = dayjs(startDateString)

    // Add the offset days
    const futureDate = startDate.add(offset, "day")

    // Check if the future date is after today
    const isFuture = futureDate.isAfter(dayjs(), "day")

    return isFuture
}

function daysBetweenDates(startDateStr: string, endDateStr: string): number {
    // Parse the start and end dates
    const startDate = dayjs(startDateStr)
    const endDate = dayjs(endDateStr)

    // Calculate the difference in days and add 1 for inclusivity
    return endDate.diff(startDate, "day") + 1
}

function getTotalDays(events: CalendarEvent[]): number {
    return events.reduce((total, event) => total + event.numDays, 0)
}

function adjustEventWithinDateRange(
    event: CalendarEvent,
    rangeStart: string,
    rangeEnd: string
): CalendarEvent {
    const parsedRangeStart = dayjs(rangeStart)
    const parsedRangeEnd = dayjs(rangeEnd)

    let adjustedStart = dayjs(event.start).isBefore(parsedRangeStart)
        ? parsedRangeStart
        : dayjs(event.start)
    let adjustedEnd = dayjs(event.end).isAfter(parsedRangeEnd)
        ? parsedRangeEnd
        : dayjs(event.end)

    // Ensure the start date is not after the end date after adjustment
    if (adjustedStart.isAfter(adjustedEnd)) {
        adjustedStart = adjustedEnd
    }

    const adjustedNumDays = adjustedEnd.diff(adjustedStart, "day") + 1

    return {
        ...event,
        start: adjustedStart.format("YYYY-MM-DD"),
        end: adjustedEnd.format("YYYY-MM-DD"),
        numDays: adjustedNumDays,
    }
}

function formatDateRange(startDateStr: string, endDateStr: string): string {
    const startDate = dayjs(startDateStr)
    const endDate = dayjs(endDateStr)

    // Check if the start and end dates are the same
    if (startDate.isSame(endDate)) {
        // Format: "Dec 25"
        return startDate.format("MMM DD")
    } else if (startDate.month() === endDate.month()) {
        // Format: "Sep 12 - 14"
        return `${startDate.format("MMM DD")} - ${endDate.format("DD")}`
    } else {
        // Format: "Oct 23 - Dec 5"
        return `${startDate.format("MMM DD")} - ${endDate.format("MMM DD")}`
    }
}

export default TimelineRowView
