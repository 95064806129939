import { Resource } from "../Model/types"
import styles from "./ResourceDetailView.module.css"
import ProfileImage from "./ProfileImage"
import Store from "../Model/Store"
import ResourceDetails from "./ResourceDetails"

type ResourceDetailViewProps = {
    resource: Resource
    onSelectResource: (id: string) => void
}

function ResourceDetailView({ resource, onSelectResource }: ResourceDetailViewProps): JSX.Element {
    const resourceDetails = resource ? <ResourceDetails resource={resource} onSelectResource={onSelectResource} /> : null

    return (
        <div className={styles.container}>
            <div className={styles.profileImage}>
                <ProfileImage imageUrl={resource?.slackUser?.profile.image_512} />
            </div>
            {resourceDetails}
        </div>
    )
}

export default ResourceDetailView
