import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit"
import { RootState } from "../Model/types"
import { fetchResearchArtifactsData, fetchResearchAttachmentsData, fetchResearchProjectsData, fetchResearchTagsData } from "../Model/helpers"

export interface ResearchProject {
    id: string
    name: string
    description: string
    personas: string[]
    question: string
    output: string
    date: string
    tags: string[]
    contactEmails: string[]
    format: string
    type: string
    artifacts: ResearchArtifact[]
    locations: string[]
    teams: string[]
    status: string
}

export interface ResearchArtifact {
    id: string
    name: string
    description: string
    type: string
    attachments: Attachment[]
}

export interface Attachment {
    id: string
    name: string
    description: string
    file: string // primary
    link: string // secondary
    type: string
}

export interface TagGroup {
    name: string,
    tags: ResearchTag[]
}

export interface ResearchTag {
    id: string
    name: string
    group: string
}

export interface UserResearchState {
    projects: ResearchProject[]
    tagGroups: TagGroup[]
    loading: string
}

const initialState: UserResearchState = {
    projects: [],
    tagGroups: [],
    loading: "idle",
}

export const selectProjects = (state: RootState) => state.userResearch.projects
export const selectCompletedProjects = createSelector(
    [selectProjects],
    (projects) => projects.filter(project => project.status === "Completed")
);
export const selectTagGroups = (state: RootState) => state.userResearch.tagGroups
export const selectLoading = (state: RootState) => state.userResearch.loading

export const fetchUserResearch = createAsyncThunk("userResearch", async (args, thunkAPI) => {
    const dispatch = thunkAPI.dispatch
    //  load the neccesary airtable data
    let researchProjectData: any[] = []
    let researchArtifactsData: any[] = []
    let researchAttachmentsData: any[] = []
    let researchTagsData: any[] = []
    
    try {
        researchProjectData = await fetchResearchProjectsData(dispatch)
    } catch (error) {
        console.error("Error fetching research projects data", error)
    }

    try {
        researchArtifactsData = await fetchResearchArtifactsData(dispatch)
    } catch (error) {
        console.error("Error fetching research artifacts", error)
    }

    try {
        researchAttachmentsData = await fetchResearchAttachmentsData(dispatch)
    } catch (error) {
        console.error("Error fetching research artifacts", error)
    }

    try {
        researchTagsData = await fetchResearchTagsData(dispatch)
    } catch (error) {
        console.error("Error fetching research artifacts", error)
    }

    const tags = researchTagsData.map(tagData => {
        const tag: ResearchTag = {
            id: tagData.id,
            name: tagData.fields["Tag Name"],
            group: tagData.fields["Group"]?.[0] ?? "",
        }
        return tag
    }).filter(tag => {
        return tag.name !== undefined
    })

    const tagGroupNames = getUniqueTagGroups(tags)
    const tagGroups: TagGroup[] = tagGroupNames.map(groupName => {
        const groupTags = tags.filter(tag => tag.group === groupName)
        const tagGroup: TagGroup = {
            name: groupName,
            tags: groupTags
        }
        return tagGroup
    })

    const projects: ResearchProject[] = researchProjectData.map(projectData => {

        const artifactIds: string[] = projectData.fields["Artifacts"]
        const artifacts: ResearchArtifact[] = artifactIds ? artifactIds.map(artifactId => {
            const artifactData = researchArtifactsData.find(item => item.id === artifactId)

            const attachmentIds: string[] = artifactData.fields["Attachments (link from Table)"]
            
            const attachments: Attachment[] = attachmentIds?.map(attachmentId => {
                const attachmentData = researchAttachmentsData.find(item => item.id === attachmentId)

                const fileData = attachmentData.fields["File (primary)"];

                const url = fileData?.[0]?.url ?? ""; // Provide a default URL if not found
                const type = fileData?.[0]?.type ?? ""; 
                

                const attachment: Attachment = {
                    id: attachmentData.id,
                    name: attachmentData.fields["Name"],
                    description: attachmentData.fields["Description of Attachment"],
                    file: url,
                    link: attachmentData.fields["Link (secondary)"],
                    type: type,
                }
                return attachment

            }) ?? []
            
            const artifact: ResearchArtifact = {
                id: artifactData.id,
                name: artifactData.fields["Artifact Name"],
                description: artifactData.fields["Short Description (single line)"],
                attachments: attachments,
                type: artifactData.fields["Artifact Type"],
            }
            return artifact
        }) : []

        const tagIds: string[] = projectData.fields["Tags (linked)"]
        const tags: string[] = tagIds ? tagIds.map(tagId => {
            const tagData = researchTagsData.find(data => {
                return data.id === tagId
            })
            return tagData.fields["Tag Name"]
        }) : []

        const project: ResearchProject = {
            id: projectData.id,
            name: projectData.fields["Name"],
            description: projectData.fields["Description"],
            personas: projectData.fields["Personas"],
            question: projectData.fields["Key Research Question"],
            output: projectData.fields["Outputs"],
            date: projectData.fields["Date of Research (month & year)"],
            tags: tags,
            contactEmails: projectData.fields["Point of Contact (emails)"],
            format: projectData.fields["Research Format"],
            type: projectData.fields["Research Type"],
            artifacts: artifacts,
            locations: projectData.fields["Location of Research"],
            teams: projectData.fields["Key Stakeholder (team)"] ?? [],
            status: projectData.fields["Status"],
        }

        return project
    })

    

    const userResearchState: UserResearchState = {
        projects: projects,
        tagGroups: tagGroups,
        loading: "loaded"
    }

    return userResearchState
})

const userResearchSlice = createSlice({
    name: "userResearch",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserResearch.pending, (state) => {
                state.loading = "loading"
            })
            .addCase(fetchUserResearch.fulfilled, (state, action) => {
                state.projects = action.payload.projects
                state.tagGroups = action.payload.tagGroups
                state.loading = "loaded"
            })
            .addCase(fetchUserResearch.rejected, (state) => {
                state.loading = "failed"
            })
    },
})

function getUniqueTagGroups(tags: ResearchTag[]): string[] {
    const allGroups = tags.flatMap((tag) => tag.group) // Step 1: Flatten the array
    const uniqueGroups = new Set(allGroups) // Step 2: Remove duplicates
    const sortedUniqueGroups = Array.from(uniqueGroups).sort() // Step 3: Sort alphabetically

    return sortedUniqueGroups
}

export default userResearchSlice.reducer