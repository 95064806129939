import React, { CSSProperties } from "react"
import { ProcessNode, StandardRole } from "../processMapSlice"
import styles from "./ProcessMapDetails.module.css"
import { RoleColorsContext } from "../ProcessMap"

interface ProcessMapDetailsProps {
    node: ProcessNode
    onRoleHover?: (role: StandardRole) => void
    onRoleHoverEnd?: () => void
    onRoleClick?: (role: StandardRole) => void
}

const ProcessMapDetails: React.FC<ProcessMapDetailsProps> = ({ node, onRoleHover, onRoleHoverEnd, onRoleClick }) => {
    const roleColors = React.useContext(RoleColorsContext)

    const getRoleStyle = (role: StandardRole) => {
        const style: CSSProperties = {
            backgroundColor: roleColors?.[role.id],
            border: `2px solid ${roleColors?.[role.id]}`,
        }
        return style
    }

    const RoleView = (props: { role: StandardRole }) => {
        const { role } = props
        const style = getRoleStyle(role)
        return (
            <div
                style={style}
                onMouseEnter={() => {
                    onRoleHover?.(role)
                }}
                onMouseLeave={() => {
                    onRoleHoverEnd?.()
                }}
                onClick={() => {
                    onRoleClick?.(role)
                }}
            >
                {role.name}
            </div>
        )
    }

    const accountableRoleViews = node.accountableRoles.map((role) => {
        return <RoleView key={role.id} role={role} />
    })

    const involvedRoleViews = node.involvedRoles.map((role) => {
        return <RoleView key={role.id} role={role} />
    })

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div>{node.workflowTier.name}</div>
                <div>{node.name}</div>
                <div>{node.description}</div>
            </div>
            <div className={styles.roles}>
                <div className={styles.roleList}>
                    <div>Definition of Ready</div>
                    <div className={styles.roleCollection}>{node.defOfReady}</div>
                </div>
                <div className={styles.roleList}>
                    <div>Definition of Done</div>
                    <div className={styles.roleCollection}>{node.defOfDone}</div>
                </div>
                <div className={styles.roleList}>
                    <div>Accountable Roles</div>
                    <div className={styles.roleCollection}>{accountableRoleViews}</div>
                </div>
                <div className={styles.roleList}>
                    <div>Involved Roles</div>
                    <div className={styles.roleCollection}>{involvedRoleViews}</div>
                </div>
            </div>
        </div>
    )
}

export default ProcessMapDetails
