interface StatusCategory {
    name: string
    color: string
    statuses: string[]
}
export const statusCategories: StatusCategory[] = [
    {
        name: "Done",
        color: "#73C755",
        statuses: ["Done"],
    },
    {
        name: "Ready for Release",
        color: "#25B190",
        statuses: ["Ready for Production Release"],
    },
    {
        name: "Review",
        color: "#13B6CC",
        statuses: ["Review"],
    },
    {
        name: "Testing",
        color: "#149AE5",
        statuses: ["Testing"],
    },
    {
        name: "In Progress",
        color: "#346BD6",
        statuses: ["In Progress"],
    },
    {
        name: "To Do",
        color: "#7D5FD2",
        statuses: ["To Do"],
    },
    {
        name: "Blocked",
        color: "#BE3466",
        statuses: ["Blocked"],
    },
    // {
    //     name: "Cancelled",
    //     color: "#DCA140",
    //     statuses: ["Will Not Implement", "Cancelled"],
    // },
]

// export const statusCategories: StatusCategory[] = [
//     {
//         name: "Done",
//         color: "#59C333",
//         statuses: ["Done"],
//     },
//     {
//         name: "Ready for Release",
//         color: "#11AD88",
//         statuses: ["Ready for Production Release"],
//     },
//     {
//         name: "Review",
//         color: "#13B6CC",
//         statuses: ["Review"],
//     },
//     {
//         name: "Testing",
//         color: "#149AE5",
//         statuses: ["Testing"],
//     },
//     {
//         name: "In Progress",
//         color: "#1154D7",
//         statuses: ["In Progress"],
//     },
//     {
//         name: "To Do",
//         color: "#5B31D0",
//         statuses: ["To Do"],
//     },
//     {
//         name: "Blocked",
//         color: "#B70142",
//         statuses: ["Blocked"],
//     },
//     {
//         name: "Cancelled",
//         color: "#DE8D06",
//         statuses: ["Will Not Implement", "Cancelled"],
//     },
// ]

export function isComplete(status: string): boolean {
    return (status === "Done" || status === "Ready for Production Release")
}

export function getColorForStatus(status: string): string {
    const category = statusCategories.find((cat) => cat.statuses.includes(status))
    return category ? category.color : "rgba(0, 0, 0, 0.1)"
}

export const countItemsWithStatusInCompleteCategory = (items: { status: string }[]): number => {
    
    const itemsInCompleteCategory = items.filter((item) => { 
        return item.status === "Done" || item.status === "Ready for Production Release"
    })
    return itemsInCompleteCategory.length
}

function getOrder(status: string): number {
    for (let categoryIndex = 0; categoryIndex < statusCategories.length; categoryIndex++) {
        const category = statusCategories[categoryIndex]
        const statusIndex = category.statuses.indexOf(status)
        if (statusIndex !== -1) {
            return categoryIndex * 100 + statusIndex // 100 is just a multiplier to give priority to category order
        }
    }
    return Infinity // if status is not found, give it the highest order
}

export function sortByStatus<T extends { status: string }>(arr: T[]): T[] {
    return [...arr].sort((a, b) => getOrder(a.status) - getOrder(b.status))
}
