import React, { ReactNode, useEffect, useState } from "react"
import GridLayout, { WidthProvider, Layout } from "react-grid-layout"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import styles from "./Dashboard.module.css"
import Tile from "./Tile"
import "./gridOverrides.css"
import { CountSummary } from "./Model/types"
import MainMenu from "./MainMenu"
import Modal from "./Components/Modal"
import AddTile from "./AddTiles/AddTile"
import { loadCurrentLayoutData, saveCurrentLayoutData, loadSavedLayouts, saveNewLayoutData } from "./Utils/localStorageUtils"
import { makeNewTileData, renderTileContent } from "./Utils/tileFactory"
import { scrollToBottom } from "./Utils/scrollToBottom"
import { defaultLayoutData } from "./Utils/defaultLayoutData"
import { LayoutData, TileType } from "./Utils/types"
import { v4 as uuidv4 } from "uuid"
import LoadLayout from "./LoadLayout/LoadLayout"
import { AnimationProvider } from "./Utils/animationContext"
import { generateGradientColors } from "./utils"

const ReactGridLayout = WidthProvider(GridLayout)

export const ProductColorsContext = React.createContext<{ [id: string]: string }>({ basic: "white" })

export interface DashboardProps {
    countSummary: CountSummary
    initialLayoutData?: LayoutData // Optional prop for initial layout data
    isThumbnail?: boolean // Optional prop to indicate if this is a thumbnail
}

const Dashboard: React.FC<DashboardProps> = ({ countSummary, initialLayoutData, isThumbnail = false }) => {
    const [layoutData, setLayoutData] = useState<LayoutData>(initialLayoutData || defaultLayoutData)
    // const [layoutData, setLayoutData] = useState<LayoutData>(initialLayoutData || loadCurrentLayoutData() || defaultLayoutData)

    const [isEditing, setIsEditing] = useState(false)
    const [modalView, setModalView] = useState<ReactNode | undefined>()

    console.log(layoutData)

    useEffect(() => {
        if (!isThumbnail) {
            saveCurrentLayoutData(layoutData)
        }
    }, [layoutData, isThumbnail])

    const addTile = (type: TileType) => {
        setModalView(undefined)
        scrollToBottom()
        const newTileData = makeNewTileData(type, layoutData.tiles.length, countSummary)
        setLayoutData((prev) => ({
            ...prev,
            tiles: [...prev.tiles, newTileData],
        }))
    }

    const removeTile = (i: string) => {
        setLayoutData((prev) => ({
            ...prev,
            tiles: prev.tiles.filter((tile) => tile.layout.i !== i),
        }))
    }

    const onLayoutChange = (newLayout: Layout[]) => {
        const updatedTileData = layoutData.tiles.map((tile) => ({
            ...tile,
            layout: newLayout.find((l) => l.i === tile.layout.i) || tile.layout,
        }))
        setLayoutData((prev) => ({
            ...prev,
            tiles: updatedTileData,
        }))
    }

    const toggleEdit = () => {
        setIsEditing((prevIsEditing) => !prevIsEditing)
    }

    const onAddTileButton = () => {
        setModalView(<AddTile countSummary={countSummary} addTile={addTile} />)
    }

    const onModalClose = () => {
        setModalView(undefined)
    }

    const onLoadLayoutButton = () => {
        const onSelectLayout = (layout: LayoutData) => {
            console.log("🚀 ~ onSelectLayout ~ layout:", layout)
            setLayoutData(layout)
            setModalView(undefined)
        }
        setModalView(<LoadLayout selectLayout={onSelectLayout} countSummary={countSummary} />)
    }

    const onSaveLayoutButton = () => {
        const name = prompt("Enter layout name:")
        if (name) {
            const newLayout: LayoutData = {
                id: uuidv4(),
                name,
                tiles: layoutData.tiles,
            }
            saveNewLayoutData(newLayout)
        }
    }

    const tiles = layoutData.tiles.map((tile) => (
        <div key={tile.layout.i} data-grid={tile.layout}>
            <Tile tileData={tile} onRemoveTile={removeTile} isEditing={isEditing}>
                {(size) => renderTileContent(tile, size, countSummary)}
            </Tile>
        </div>
    ))

    const layout = layoutData.tiles.map((tile) => tile.layout)

    // create product colors
    // const inputColors = ["#61C6A9", "#438CD3", "#5743CC", "#AA0086", "#DCB620"]
    const inputColors = ["#DCB620", "#C75828", "#AA0086", "#5743CC", "#1F81E1", "#11C1C1"]
    inputColors.reverse()
    const steps = countSummary.products.length
    const colors = generateGradientColors(inputColors, steps)
    const productColors: { [id: string]: string } = colors.reduce((acc: any, color, index) => {
        acc[countSummary.products[index].key] = color
        return acc
    }, {})

    return (
        <AnimationProvider animation={!isThumbnail}>
            <ProductColorsContext.Provider value={productColors}>
                <div className={styles.dashboard}>
                    {modalView && <Modal onClose={onModalClose}>{modalView}</Modal>}
                    {!isThumbnail && <MainMenu toggleEdit={toggleEdit} isEditing={isEditing} addTile={onAddTileButton} loadLayout={onLoadLayoutButton} saveLayout={onSaveLayoutButton} />}
                    <ReactGridLayout
                        className={styles.layout}
                        layout={layout}
                        onLayoutChange={onLayoutChange}
                        draggableCancel=".no-drag"
                        cols={12}
                        rowHeight={30}
                        resizeHandles={["sw", "se"]}
                        isResizable={isEditing && !isThumbnail}
                        isDraggable={isEditing && !isThumbnail}
                        useCSSTransforms={!isThumbnail}
                        transformScale={isThumbnail ? 0 : 1}
                    >
                        {tiles}
                    </ReactGridLayout>
                </div>
            </ProductColorsContext.Provider>
        </AnimationProvider>
    )
}

export default Dashboard
