import { Layout } from "react-grid-layout"
import { HistoricalCountParams } from "../Tiles/HistoricalCountTile"

export interface LayoutData {
    id: string
    name: string
    tiles: TileData[]
}

export type TileDataParams = HistoricalCountParams

export interface TileData {
    type: TileType
    layout: Layout
    params?: TileDataParams
}

export enum TileType {
    Basic = "Basic",
    Spacer = "Spacer",
    GrandTotal = "Grand Total",
    StackedArea = "Stacked Area",
    ProductTotals = "Product Totals",
    PercentConnected = "Percent Connected",
    FeatureAvailability = "Feature Availability",
    DeviceRatio = "Device Ratio",
    HistoricalCount = "Historical Count",
    HistoricalGrid = "Historical Grid",
    CategoryPie = "Category Pie",
}
