import React, { useState, useEffect, CSSProperties } from "react"
import { ResearchProject } from "./userResearchSlice"
import styles from "./ProjectDetails.module.css"
import ArtifactCard from "./ArtifactCard"
import { TagColorsContext } from "./UserResearch"
import ResourceView from "../Components/ResourceView"
import { useSelector } from "react-redux"
import { selectResources } from "../Model/slices/resourcesSlice"
import { Resource } from "../Model/types"
import RelatedProject from "./RelatedProject"
import { AnimatePresence, motion } from "framer-motion"
import { useNavigate } from "react-router-dom"

interface ProjectDetailsProps {
    project: ResearchProject
    onTagSelect?: (tag: string) => void
    onResourceClick?: (resource: Resource) => void
    relatedProjects?: ResearchProject[]
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({ project, onTagSelect, onResourceClick, relatedProjects }) => {
    const tagColors = React.useContext(TagColorsContext)
    const resources = useSelector(selectResources)
    const navigate = useNavigate()

    const artifactViews = project.artifacts.map((artifact) => {
        return <ArtifactCard key={artifact.id} artifact={artifact} />
    })

    const teams = joinSortedStrings(project.teams)
    const personas = joinSortedStrings(project.personas)

    useEffect(() => {
        setTimeout(() => {
            const element = document.getElementById("mainContent")

            if (element) {
                element.scrollTop = 0
            }
        }, 200)
    }, [project])

    const resourceViews = project.contactEmails.map((email) => {
        const resource = resources.find((r) => {
            const thisEmail = r.email?.toLowerCase()
            const targetEmail = email.toLowerCase()
            if (thisEmail && targetEmail) {
                return thisEmail === targetEmail
            }
            return false
        })

        if (resource) {
            return (
                <ResourceView
                    key={resource.id}
                    resource={resource}
                    onClick={() => {
                        onResourceClick?.(resource)
                    }}
                />
            )
        }
    })

    let sortedTags = project.tags ? [...project.tags].sort() : []

    const tags = sortedTags.map((tag) => {
        const onClick = () => {
            onTagSelect?.(tag)
        }

        const color = tagColors ? tagColors[tag] : "white"
        const style: CSSProperties = {
            backgroundColor: color,
            border: `2px solid ${color}`,
        }

        return (
            <div key={tag} className={styles.tag} style={style} onClick={onClick}>
                {tag}
            </div>
        )
    })

    if (relatedProjects) {
        // Sort relatedProjects based on the number of common tags with 'project'
        relatedProjects.sort((a, b) => {
            // Find the number of common tags between 'project' and each of the related projects
            const commonTagsCountA = findCommonTagsCount(project, a)
            const commonTagsCountB = findCommonTagsCount(project, b)

            // Sort in descending order so that projects with more common tags come first
            return commonTagsCountB - commonTagsCountA
        })
    }

    const relatedProjectViews = relatedProjects?.map((relatedProject) => {
        return <RelatedProject key={relatedProject.id} project={project} relatedProject={relatedProject} onTagSelect={onTagSelect} />
    })

    const onViewAllClick = () => {
        navigate(`/user-research/`)
    }

    return (
        <AnimatePresence mode="wait">
            <motion.div key={project.id} className={styles.projectDetails} initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
                <div className={styles.header}>
                    <div className={styles.teams}>{teams}</div>
                    <div className={styles.backButton} onClick={onViewAllClick}>
                        View All Projects
                    </div>
                </div>
                <div className={styles.name}>{project.name}</div>
                <div className={styles.description}>{project.description}</div>

                <div className={styles.question}>{project.question}</div>
                <div className={styles.name}>Details</div>
                <div className={styles.detailsContainer}>
                    <div className={styles.detailsBlock}>
                        <div>
                            <b>Format: </b>
                            {project.format}
                        </div>
                        <div>
                            <b>Output: </b>
                            {project.output}
                        </div>
                        <div>
                            <b>Personas: </b>
                            {personas}
                        </div>
                        <div>
                            <b>Type: </b>
                            {project.type ?? "--"}
                        </div>
                        <div>
                            <b>Date: </b>
                            {project.date}
                        </div>

                        <div>
                            <b>Location: </b>
                            {project.locations ?? "--"}
                        </div>
                    </div>
                    <div className={styles.detailsBlock}>
                        <div>
                            <b>Contact: </b>
                            <div className={styles.resourceContainer}>{resourceViews}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.tagContainer}>{tags}</div>
                </div>

                <div className={styles.artifacts}>
                    <div className={styles.name}>Artifacts</div>
                    <div className={styles.artifactViews}>{artifactViews}</div>
                </div>

                <div className={styles.relatedContainer}>
                    <div className={styles.name}>Related</div>
                    <div className={styles.relatedProjects}>{relatedProjectViews}</div>
                </div>
            </motion.div>
        </AnimatePresence>
    )
}

function joinSortedStrings(stringsArray: string[]): string {
    if (stringsArray === undefined) {
        return "--"
    }
    // Create a shallow copy of the array and sort it alphabetically
    const sortedArray = [...stringsArray].sort()

    // Join the sorted array elements into a single string, separated by ", "
    const joinedString = sortedArray.join(", ")

    return joinedString
}

function findCommonTagsCount(project1: ResearchProject, project2: ResearchProject): number {
    // Use an empty array as a fallback if tags are undefined
    const tags1 = project1.tags || []
    const tags2 = project2.tags || []

    // Filter tags from project1 that are also present in project2
    const commonTags = tags1.filter((tag) => tags2.includes(tag))

    return commonTags.length
}

export default ProjectDetails
