import { Dispatch } from "@reduxjs/toolkit"
import { AirtableConfig } from "./AirtableConfig"
import oktaAuth from "./oktaAuthSingleton"
import { serverUrl } from "./serverConfig"
import { setLoadingMessage } from "../Components/LoadingStatus/LoadingStatus"

export const fetchAirtableData = async (baseId: string, tableId: string) => {
    try {
        const accessToken = await oktaAuth.getIdToken()

        // If the ID token is unavailable, it might mean the user's session has expired
        if (!accessToken) {
            // This will redirect the user to sign in again
            oktaAuth.signInWithRedirect()
            return
        }

        const response = await fetch(`${serverUrl}/airtable-data/${baseId}/${tableId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        const data = await response.json()
        return data
    } catch (error) {
        console.error("Error fetching data from server:", error)
        return []
    }
}

export const fetchProductEpicsData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Product Epics"))
    const baseId = AirtableConfig.foundryDeliveryOperationsBaseId
    const tableId = AirtableConfig.productEpicsTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}

export const fetchJiraEpicsData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Jira Epics"))
    const baseId = AirtableConfig.foundryDeliveryOperationsBaseId
    const tableId = AirtableConfig.jiraEpicsTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}

export const fetchJiraStoriesData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Jira Stories"))
    const baseId = AirtableConfig.foundryDeliveryOperationsBaseId
    const tableId = AirtableConfig.jiraStoriesTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}


export const fetchFoundryCalendarData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Engineering Calendar"))
    const baseId = AirtableConfig.foundryDeliveryOperationsBaseId
    const tableId = AirtableConfig.foundryCalendarTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}

////////


export const fetchFeatureWorkflowData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Feature Workflow"))
    const baseId = AirtableConfig.processOperationsBaseId
    const tableId = AirtableConfig.featureWorkflowTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}

export const fetchWorkflowPhasesData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Workflow Phases"))
    const baseId = AirtableConfig.processOperationsBaseId
    const tableId = AirtableConfig.workflowPhasesTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}

export const fetchWorkflowTiersData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Workflow Tiers"))
    const baseId = AirtableConfig.processOperationsBaseId
    const tableId = AirtableConfig.workflowTiersTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}

export const fetchStandardRolesData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Standard Role Types"))
    const baseId = AirtableConfig.processOperationsBaseId
    const tableId = AirtableConfig.standardRoleTypesTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}

//////

export const fetchResearchProjectsData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Research Projects"))
    const baseId = AirtableConfig.researchBaseId
    const tableId = AirtableConfig.researchProjectsTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}

export const fetchResearchArtifactsData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Research Artifacts"))
    const baseId = AirtableConfig.researchBaseId
    const tableId = AirtableConfig.researchArtifactsTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}

export const fetchResearchAttachmentsData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Research Attachments"))
    const baseId = AirtableConfig.researchBaseId
    const tableId = AirtableConfig.researchAttachmentsTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}

export const fetchResearchTagsData = async (dispatch: Dispatch) => {
    dispatch(setLoadingMessage("Research Tags"))
    const baseId = AirtableConfig.researchBaseId
    const tableId = AirtableConfig.researchTagsTableId
    const data = await fetchAirtableData(baseId, tableId)
    return data
}
