import React, { useEffect, useState } from "react"
import styles from "./FoundryCalendar.module.css"
import FlowCalendar from "./FlowCalendar"
import TabButton from "../Components/TabButton"
import { AnimatePresence, motion } from "framer-motion"
import YearView from "./Year/YearView"
import TimelineStack from "./Timeline/TimelineStack"
import {
    fetchFoundryCalendar,
    selectEventCategories,
    selectLoading,
    selectTimelineChunks,
} from "./foundryCalendarSlice"
import { useSelector } from "react-redux"
import { useDispatch } from "../Model/hooks"
import CalendarSidebar from "./CalendarSidebar"

export enum CalendarZoomLevel {
    Year = "Year",
    Month = "Month",
    Week = "Week",
    Day = "Day",
}

interface FoundryCalendarProps {}

const FoundryCalendar: React.FC<FoundryCalendarProps> = () => {
    const timelineChunks = useSelector(selectTimelineChunks)
    const loading = useSelector(selectLoading)
    const dispatch = useDispatch()

    const calendarView = <TimelineStack timelineChunks={timelineChunks} />

    useEffect(() => {
        if (loading === "idle") {
            dispatch(fetchFoundryCalendar())
        }
    }, [loading, dispatch])

    return (
        <div className={styles.container}>
            <CalendarSidebar />
            <div className={styles.content}>
                <div className={styles.calendar}>{calendarView}</div>
            </div>
        </div>
    )
}

export default FoundryCalendar
