import React, { CSSProperties, useEffect, useState } from "react"
import Store from "../Model/Store"
import { AreaPath, Platform, Pod, Role, ValueStream } from "../Model/types"
import styles from "./SimplifiedValueStreams.module.css"
import Modal from "../Components/Modal"
import RoleDetailView from "../Components/RoleDetailView"
import { useParams, useNavigate, Outlet } from "react-router-dom"
import PlatformView from "./Views/PlatformView"
import ResourceDetailView from "../Components/ResourceDetailView"
import RoleView from "./Views/RoleView"
import ValueStreamDetails from "./ValueStreamDetails"
import PlatformHeader from "./PlatformHeader"
import ValueStreamSelector from "./ValueStreamSelector"
import Color from "color"
import { AnimatePresence, motion } from "framer-motion"

export interface SimplifiedValueStreamProps {
    store: Store | undefined
}

export const PodColorsContext = React.createContext<{ [id: string]: string } | undefined>(undefined)

const SimplifiedValueStreams: React.FC<SimplifiedValueStreamProps> = (props) => {
    const navigate = useNavigate()
    const { valueStreamId, roleId, resourceId } = useParams<{
        valueStreamId?: string
        roleId?: string
        resourceId?: string
    }>()
    const [scrollPosition, setScrollPosition] = useState(0)

    const storeScrollPosition = () => {
        const scrollPos = document.getElementById("platformScroll")?.scrollTop
        if (scrollPos !== undefined) {
            setScrollPosition(scrollPos)
        }
    }

    const restoreScrollPosition = () => {
        // we must wait untill after the transition to set the scroll position
        setTimeout(() => {
            const element = document.getElementById("platformScroll")
            if (element) {
                element.scrollTop = scrollPosition
            }
        }, 210)
    }

    useEffect(() => {
        if (!valueStreamId) {
            restoreScrollPosition()
        }
    }, [valueStreamId])

    const store = props.store
    if (store === undefined) {
        return (
            <div className={styles.valueStreamsLoading}>
                <div>Loading Data...</div>
            </div>
        )
    }

    const platforms = store.getPlatforms()

    const onValueStreamClick = (valueStream: ValueStream) => {
        storeScrollPosition()
        navigate(`/value-streams/value-stream/${valueStream.id}`)
    }

    function onRoleClick(selectedRole: Role) {
        if (valueStreamId) {
            navigate(`/value-streams/value-stream/${valueStreamId}/roles/${selectedRole.id}`)
        } else {
            navigate(`/value-streams/roles/${selectedRole.id}`)
        }
    }

    function onResourceClick(selectedResourceId: string) {
        if (valueStreamId) {
            navigate(`/value-streams/value-stream/${valueStreamId}/resources/${selectedResourceId}`)
        } else {
            navigate(`/value-streams/resources/${selectedResourceId}`)
        }
    }

    const onModalClose = () => {
        if (valueStreamId) {
            navigate(`/value-streams/value-stream/${valueStreamId}`)
        } else {
            navigate(`/value-streams/`)
        }
    }

    const onHeaderClick = () => {
        navigate(`/value-streams/`)
    }

    const allPods = getAllPods(platforms)
    allPods.sort((a, b) => {
        return a.name.localeCompare(b.name)
    })
    const podColors: { [id: string]: string } = {}
    const podCount = allPods.length
    let podIndex = 0
    for (const pod of allPods) {
        const hue = (podIndex / podCount) * 360
        const color = Color(`hsl(${hue}, 50%, 70%)`)
        podIndex++
        podColors[pod.id] = color.hex()
    }

    const platformViews = platforms.map((platform) => {
        const valueStreams = platform.valueStreams.map((vs) => {
            const topLeads = vs.support.slice(0, 2)
            const leads = topLeads.map((role) => {
                return <RoleView role={role} key={"vsLead-" + role.id} onClick={onRoleClick} />
            })
            const pods = collectPods(vs)
            const podViews = pods.map((pod) => {
                const color = podColors[pod.id]
                const style: CSSProperties = { backgroundColor: color }
                return (
                    <div key={pod.id} className={styles.pod} style={style}>
                        {pod.name}
                    </div>
                )
            })
            return (
                <div
                    key={vs.id}
                    className={styles.valueStream}
                    onClick={() => {
                        onValueStreamClick(vs)
                    }}
                >
                    <div className={styles.valueStreamTitle}>{vs.name}</div>
                    <div className={styles.valueStreamLeads}>{leads}</div>
                    <div>
                        <div className={styles.pods}>{podViews}</div>
                    </div>
                </div>
            )
        })

        return (
            <div key={platform.id} className={styles.platform}>
                <PlatformHeader platform={platform} showLeads onRoleClick={onRoleClick} />
                <div className={styles.valueStreams}>{valueStreams}</div>
            </div>
        )
    })

    const combinedValueStreams = platforms.reduce((acc, unit) => {
        return acc.concat(unit.valueStreams)
    }, [] as ValueStream[])
    const selectedVS = combinedValueStreams.find((vs) => vs.id === valueStreamId)

    const selectedPlatform = findPlatformByValueStreamId(platforms, valueStreamId)

    const platformValueStreams = selectedPlatform ? selectedPlatform.valueStreams : []
    const valueStreamSelector = valueStreamId ? <ValueStreamSelector valueStreams={platformValueStreams} selectedId={valueStreamId} onSelect={onValueStreamClick} /> : null

    const platformHeader = selectedPlatform ? <PlatformHeader platform={selectedPlatform} onRoleClick={onRoleClick} /> : null
    const valueStreamDetails = selectedVS ? <ValueStreamDetails valueStream={selectedVS} onRoleClick={onRoleClick} /> : null
    const valueSteamContent = (
        <motion.div key={`valueStreamDetails`} className={styles.vsDetailsContainer} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            {platformHeader}
            {valueStreamSelector}
            {valueStreamDetails}
        </motion.div>
    )

    const platformContent = (
        <motion.div key={"platforms"} id="platformScroll" className={styles.platformsContainer} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            {platformViews}
        </motion.div>
    )

    const content = selectedVS ? valueSteamContent : platformContent

    const selectedResource = resourceId ? store.getResourceById(resourceId) : undefined
    const modal = roleId ? (
        <Modal onClose={onModalClose}>
            <RoleDetailView role={store.getRoleById(roleId)} store={store} onSelectResource={onResourceClick} />
        </Modal>
    ) : resourceId && selectedResource ? (
        <Modal onClose={onModalClose}>
            <ResourceDetailView resource={selectedResource} onSelectResource={onResourceClick} />
        </Modal>
    ) : null

    return (
        <PodColorsContext.Provider value={podColors}>
            <div className={styles.simplifiedValueStreams}>
                {modal}
                <AnimatePresence mode="wait">{content}</AnimatePresence>
            </div>
            <Outlet />
        </PodColorsContext.Provider>
    )
}

export function collectPods(valueStream: ValueStream): Pod[] {
    const pods: Pod[] = [...valueStream.pods]

    if (valueStream.areaPaths) {
        for (const areaPath of valueStream.areaPaths) {
            pods.push(...areaPath.pods)
        }
    }

    return pods
}

function findPlatformByValueStreamId(platforms: Platform[], selectedValueStreamId: string | undefined): Platform | undefined {
    if (!selectedValueStreamId) {
        return undefined
    }
    // Iterate through the platforms array
    return platforms.find((platform) =>
        // Check each platform's valueStreams array for a valueStream with the matching ID
        platform.valueStreams.some((valueStream) => valueStream.id === selectedValueStreamId)
    )
}

function getAllPods(platforms: Platform[]): Pod[] {
    const vsPods = platforms.flatMap((p) => p.valueStreams).flatMap((vs) => vs.pods)
    const apPods = platforms
        .flatMap((p) => p.valueStreams)
        .flatMap((vs) => vs.areaPaths)
        .flatMap((ap) => ap?.pods)
        .filter((pod): pod is Pod => pod !== undefined) // Type guard to filter out undefined values

    return [...vsPods, ...apPods]
}

export default SimplifiedValueStreams
