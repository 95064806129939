import React, { useState, useEffect } from "react"
import { ResearchArtifact, ResearchProject } from "./userResearchSlice"
import styles from "./ArtifactCard.module.css"
import { Description, VideoFile } from "@mui/icons-material"

interface ArtifactCardProps {
    artifact: ResearchArtifact
}

const ArtifactCard: React.FC<ArtifactCardProps> = ({ artifact }) => {
    const attachments =
        artifact.attachments.length > 0
            ? artifact.attachments.map((attachment) => {
                  const onAttachmentClick = () => {
                      if (attachment.file !== "") {
                          window.open(attachment.file, "_blank")
                      } else if (attachment.link !== "") {
                          window.open(attachment.link, "_blank")
                      }
                  }

                  const icon = attachment.type === "video/mp4" ? <VideoFile className={styles.documentIcon} /> : <Description className={styles.documentIcon} />

                  return (
                      <div key={attachment.id} className={styles.attachment} onClick={onAttachmentClick}>
                          {icon}
                          <div>{attachment.name}</div>
                      </div>
                  )
              })
            : "--"

    return (
        <div className={styles.artifact} key={artifact.id}>
            <div>
                <div className={styles.name}>{artifact.name}</div>
                <div className={styles.description}>{artifact.description}</div>
            </div>

            <div className={styles.details}>
                <div>
                    <b>Type: </b>
                    {artifact.type}
                </div>
                <div className={styles.attachments}>{attachments}</div>
            </div>
        </div>
    )
}

export default ArtifactCard
