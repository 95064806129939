import React, { useState } from "react"
import Store from "../Model/Store"
import styles from "./ServiceCatalog.module.css"
import RoleChip from "../Components/RoleChip"
import RoleDetailView from "../Components/RoleDetailView"
import Modal from "../Components/Modal"
import ResourceDetailView from "../Components/ResourceDetailView"
import { Tooltip, TooltipProps } from "@mui/material"
import ServiceChip from "../Components/ServiceChip"

interface ServiceCatalogProps {
    store: Store
}

const ServiceCatalog: React.FC<ServiceCatalogProps> = ({ store }) => {
    const [modalContent, setModalContent] = useState<React.ReactNode | undefined>(undefined)

    const platforms = store.getPlatforms()
    const io = platforms.find((platform) => {
        return platform.name === "carrier.io"
    })

    function tooltipContentDescription(description?: string) {
        return <div className={styles.tooltipContentDescription}>{description}</div>
    }

    const tooltipDefaultProps: Partial<TooltipProps> = {
        enterDelay: 500,
        placement: "top-start",
        arrow: true,
    }

    const onResourceClick = (id: string) => {
        const resource = store.getResourceById(id)
        if (resource) {
            setModalContent(<ResourceDetailView resource={resource} onSelectResource={onResourceClick} />)
        }
    }

    const onRoleChipClick = (id: string) => {
        setModalContent(<RoleDetailView role={store.getRoleById(id)} store={store} onSelectResource={onResourceClick} />)
    }

    const onModalClose = () => {
        setModalContent(undefined)
    }

    let hue = 0.4
    const vs = io?.valueStreams
        .filter((valueStream) => {
            return valueStream.areaPaths && valueStream.areaPaths.length > 0
        })
        .map((valueStream) => {
            const ap = valueStream.areaPaths?.map((areaPath) => {
                const apSupport = areaPath.support.map((support) => {
                    return <RoleChip key={support.id} role={support} onClick={onRoleChipClick} />
                })
                const services = areaPath.services?.map((service) => {
                    return <ServiceChip service={service} key={service.id} />
                })
                return (
                    <div key={areaPath.id} className={styles.areaPath}>
                        <Tooltip title={tooltipContentDescription(areaPath.description)} {...tooltipDefaultProps}>
                            <div className={styles.areaPathTitle}>{areaPath.name}</div>
                        </Tooltip>

                        <div className={styles.areaPathContent}>
                            <div className={styles.services}>{services}</div>
                            <div className={styles.areaPathSupport}>{apSupport}</div>
                        </div>
                    </div>
                )
            })
            const vsSupport = valueStream.support.map((support) => {
                return <RoleChip key={support.id} role={support} onClick={onRoleChipClick} />
            })
            const h = hue
            hue = addAndWrap(hue, 0.07)
            return (
                <div key={valueStream.id} className={styles.valueStream} style={{ backgroundColor: generateHexColor(h, 0.2, 0.8) }}>
                    <div className={styles.valueStreamHeader}>
                        <Tooltip title={tooltipContentDescription(valueStream.description)} {...tooltipDefaultProps}>
                            <div className={styles.valueStreamTitle}>{valueStream.name}</div>
                        </Tooltip>

                        <div className={styles.valueStreamSupport}>{vsSupport}</div>
                    </div>

                    <div className={styles.areaPaths}>{ap}</div>
                </div>
            )
        })

    return (
        <div>
            {modalContent && <Modal onClose={onModalClose}>{modalContent}</Modal>}
            <div className={styles.container}>{vs}</div>
        </div>
    )
}

function addAndWrap(value1: number, value2: number): number {
    const sum = value1 + value2
    return sum % 1
}

function generateHexColor(hue: number, saturation: number, value: number): string {
    // Ensure hue is within the range [0, 1)
    const clampedHue = hue >= 0 ? hue % 1 : (hue % 1) + 1

    // Ensure saturation and value are within the range [0, 1]
    const clampedSaturation = Math.max(0, Math.min(1, saturation))
    const clampedValue = Math.max(0, Math.min(1, value))

    // Convert HSV to RGB
    const chroma = clampedValue * clampedSaturation
    const huePrime = clampedHue * 6
    const x = chroma * (1 - Math.abs((huePrime % 2) - 1))
    let red = 0,
        green = 0,
        blue = 0

    if (0 <= huePrime && huePrime < 1) {
        red = chroma
        green = x
    } else if (1 <= huePrime && huePrime < 2) {
        red = x
        green = chroma
    } else if (2 <= huePrime && huePrime < 3) {
        green = chroma
        blue = x
    } else if (3 <= huePrime && huePrime < 4) {
        green = x
        blue = chroma
    } else if (4 <= huePrime && huePrime < 5) {
        red = x
        blue = chroma
    } else if (5 <= huePrime && huePrime < 6) {
        red = chroma
        blue = x
    }

    const lightness = clampedValue - chroma
    red += lightness
    green += lightness
    blue += lightness

    // Convert RGB to hexadecimal color string
    const r = Math.round(red * 255)
        .toString(16)
        .padStart(2, "0")
    const g = Math.round(green * 255)
        .toString(16)
        .padStart(2, "0")
    const b = Math.round(blue * 255)
        .toString(16)
        .padStart(2, "0")

    return `#${r}${g}${b}`
}

export default ServiceCatalog
