// oktaAuthSingleton.ts
import { OktaAuth } from "@okta/okta-auth-js"

const oktaAuth = new OktaAuth({
    issuer: "https://sso.carrier.io",
    clientId: "0oa5iikg5i4GwdpGV697",
    redirectUri: `${window.location.origin}/auth/login/callback`,
    postLogoutRedirectUri: `${window.location.origin}`,
    tokenManager: {
        autoRenew: true,
    },
})

export default oktaAuth
