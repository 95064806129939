import React from "react"
import styles from "./TabButton.module.css"

// Define the props type
interface TabButtonProps {
    title: string
    isSelected: boolean
    onClick: () => void
}

const TabButton: React.FC<TabButtonProps> = ({ title, isSelected, onClick }) => {
    const buttonClass = `${styles.button} ${isSelected ? styles.selected : ""}`
    const barClass = `${styles.bar} ${isSelected ? styles.selected : ""}`

    return (
        <div className={styles.container}>
            <div onClick={onClick} className={buttonClass}>
                {title}
            </div>
            <div className={barClass}></div>
        </div>
    )
}

export default TabButton
