import { StandardRole } from "../processMapSlice"
import styles from "./StandardRoleDetails.module.css"

import { useSelector } from "react-redux"
import { Resource } from "../../Model/types"
import { selectResources } from "../../Model/slices/resourcesSlice"
import ResourceView from "../../Components/ResourceView"
import { CSSProperties } from "styled-components"
import React from "react"
import { RoleColorsContext } from "../ProcessMap"
import { title } from "process"

interface StandardRoleDetailsProps {
    role: StandardRole
    onResourceClick?: (resource: Resource) => void
}

const StandardRoleDetails: React.FC<StandardRoleDetailsProps> = ({ role, onResourceClick }) => {
    const { name, responsibilities, emails } = role
    const resources = useSelector(selectResources)
    const roleColors = React.useContext(RoleColorsContext)

    const resourceViews = emails.map((email) => {
        const resource = resources.find((r) => {
            const thisEmail = r.email?.toLowerCase()
            const targetEmail = email.toLowerCase()
            if (thisEmail && targetEmail) {
                return thisEmail === targetEmail
            }
            return false
        })

        if (resource) {
            return (
                <ResourceView
                    key={resource.id}
                    resource={resource}
                    onClick={() => {
                        onResourceClick?.(resource)
                    }}
                />
            )
        }
    })

    const titleStyle: CSSProperties = {
        backgroundColor: roleColors?.[role.id] ?? "clear",
        padding: "10px",
        // transform: "translateX(-10px)",
        borderRadius: "4px",
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <div style={titleStyle}>{name}</div>
                </div>
                <div className={styles.responsibilities}>
                    <div className={styles.mainResponsibility}>{responsibilities && responsibilities.split("\n\n").map((block, index) => <div key={index}>{block}</div>)[0]}</div>

                    <div className={styles.blockSection}>
                        <div>Responsibilities</div>
                        <div className={styles.otherResponsibilities}>
                            {responsibilities &&
                                responsibilities
                                    .split("\n\n")
                                    .map((block, index) => <div key={index}>{block}</div>)
                                    .slice(1)}
                        </div>
                    </div>
                    <div className={styles.blockSection}>
                        <div>Resources</div>
                        <div className={styles.resources}>{resourceViews}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StandardRoleDetails
