import React, { useEffect, useState, useCallback } from "react"
import styles from "./ProjectYarn.module.css"
import Cookies from "js-cookie"
import PasswordProtected from "./PasswordProtected"
import { fetchProductsData, fetchProductDeviceCountsData } from "./Model/serverConnection"
import { CountSummary } from "./Model/types"
import { makeCountSummary } from "./Model/transformers"
import Dashboard from "./Dashboard"
import { generateDeviceCountData } from "./utils"

export interface ProjectYarnProps {}

const ProjectYarn: React.FC<ProjectYarnProps> = (props) => {
    const [countSummary, setCountSummary] = useState<CountSummary | null>(null)
    const [useRealData, setUseRealData] = useState(true)
    const [realCountSummary, setRealCountSummary] = useState<CountSummary | null>(null)
    const [fakeCountSummary, setFakeCountSummary] = useState<CountSummary | null>(null)

    const clearAccessCookie = () => {
        Cookies.remove("passwordProtectedAccess")
    }

    const getData = async () => {
        const productsData = await fetchProductsData()

        // get real data
        const realDeviceCountData = await fetchProductDeviceCountsData()
        const realCountSummary = makeCountSummary(realDeviceCountData, productsData)
        setRealCountSummary(realCountSummary)

        // generate fake data
        const fakeDeviceCountData = generateDeviceCountData(realCountSummary.products.map((p) => p.key))
        const fakeCountSummary = makeCountSummary(fakeDeviceCountData, productsData)
        setFakeCountSummary(fakeCountSummary)

        updateCountSummary()
    }

    const updateCountSummary = useCallback(() => {
        if (useRealData) {
            setCountSummary(realCountSummary)
        } else {
            setCountSummary(fakeCountSummary)
        }
    }, [useRealData, realCountSummary, fakeCountSummary])

    useEffect(() => {
        document.title = "Project Yarn"
        getData()
    }, [])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "d") {
                setUseRealData((prev) => !prev)
            }
        }

        window.addEventListener("keydown", handleKeyDown)

        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
    }, [])

    useEffect(() => {
        updateCountSummary()
    }, [useRealData, updateCountSummary])

    const loadingView = <div>Loading Data</div>

    const content = countSummary ? <Dashboard countSummary={countSummary} /> : loadingView

    return (
        <PasswordProtected>
            <div className={styles.projectYarn} id="project-yarn">
                {content}
            </div>
        </PasswordProtected>
    )
}

export default ProjectYarn
