import { Tooltip, TooltipProps, Zoom, tooltipClasses } from "@mui/material"
import styles from "./SteppedProgressBar.module.css"
import { styled } from "@mui/material/styles"
import React, { CSSProperties, HTMLAttributes } from "react"
import { motion } from "framer-motion"

interface SteppedProgressBarProps {
    stepCount: number
    currentStep?: number
    selectedStep?: number
    stepColor?: string
    getTooltip?: (index: number) => React.ReactNode
    onStepClick?: (index: number) => void
    getStepColor?: (index: number) => string
    animDelay?: number
    getStepStyle?: (index: number) => CSSProperties
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))

const SteppedProgressBar: React.FC<SteppedProgressBarProps> = ({ stepCount, currentStep, selectedStep, stepColor, getTooltip, onStepClick, getStepColor, animDelay, getStepStyle }) => {
    const tooltipDefaultProps: Partial<TooltipProps> = {
        enterDelay: 10,
        placement: "top",
        arrow: false,
        disableInteractive: true,
    }

    const steps = Array(stepCount)
        .fill(0)
        .map((_, index) => {
            const color = stepColor ? stepColor : "rgba(0, 0, 0, 0.7)"
            let thisStepColor = "rgba(0, 0, 0, 0.1)"
            if (currentStep && index < currentStep) {
                thisStepColor = color
            }

            if (getStepColor) {
                thisStepColor = getStepColor(index)
            }

            const tooltipContent = getTooltip ? getTooltip(index) : null
            const border = index === selectedStep ? "2px solid white" : ""

            let boxStyle: React.CSSProperties = {
                backgroundColor: thisStepColor,
            }
            if (selectedStep !== undefined && index === selectedStep) {
                boxStyle = {
                    backgroundColor: thisStepColor,
                    transform: "scaleY(1.3)",
                }
            }

            if (getStepStyle) {
                boxStyle = getStepStyle(index)
            }

            return (
                <HtmlTooltip key={index} title={<React.Fragment>{tooltipContent}</React.Fragment>} {...tooltipDefaultProps} TransitionComponent={Zoom}>
                    <motion.div
                        key={"motion-id-box-" + index}
                        onClick={() => {
                            if (onStepClick) {
                                onStepClick(index)
                            }
                        }}
                        style={{ ...boxStyle }}
                        className={styles.step}
                        // layout
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 0.3,
                            delay: (index / stepCount) * 0.5 + (animDelay ?? 0),
                            ease: "easeOut",
                        }}
                    ></motion.div>
                </HtmlTooltip>
            )
        })
    return <div className={styles.container}>{steps}</div>
}
export default SteppedProgressBar
