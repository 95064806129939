import React, { CSSProperties, MouseEventHandler } from "react"
import { ResearchProject } from "./userResearchSlice"
import styles from "./RelatedProject.module.css"
import { TagColorsContext } from "./UserResearch"
import { useNavigate } from "react-router-dom"
import { getSubstringBeforeFirstDot } from "./utils"

interface RelatedProjectProps {
    project: ResearchProject
    relatedProject: ResearchProject
    onTagSelect?: (tag: string) => void
}

const RelatedProject: React.FC<RelatedProjectProps> = ({ project, relatedProject, onTagSelect }) => {
    const tagColors = React.useContext(TagColorsContext)
    const navigate = useNavigate()
    const commonTags = findCommonTags(project, relatedProject)

    let sortedTags = [...commonTags].sort()

    const tags = sortedTags.map((tag) => {
        const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation()
            onTagSelect?.(tag)
        }

        const color = tagColors ? tagColors[tag] : "white"
        const style: CSSProperties = {
            backgroundColor: color,
            border: `2px solid ${color}`,
        }

        return (
            <div key={tag} className={styles.tag} style={style} onClick={onClick}>
                {tag}
            </div>
        )
    })

    const onClick = () => {
        navigate(`/user-research/project/${relatedProject.id}`)
    }

    const description = getSubstringBeforeFirstDot(project.description)

    return (
        <div className={styles.relatedProject} onClick={onClick}>
            <div className={styles.name}>{relatedProject.name}</div>
            <div className={styles.description}>{description}</div>
            <div className={styles.tagContainer}>{tags}</div>
        </div>
    )
}

function findCommonTags(project1: ResearchProject, project2: ResearchProject): string[] {
    // Use an empty array as a fallback if tags are undefined
    const tags1 = project1.tags || []
    const tags2 = project2.tags || []

    // Filter tags from project1 that are also present in project2
    const commonTags = tags1.filter((tag) => tags2.includes(tag))

    return commonTags
}

export default RelatedProject
