import styles from "./StatusGrid.module.css"
import { getColorForStatus, isComplete } from "./statusCategories"
import SteppedProgressBar from "../../Components/SteppedProgressBar"
import { CSSProperties, useEffect, useRef, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { TableVirtuoso, TableComponents, VirtuosoHandle } from "react-virtuoso"
import React from "react"
import CancelIcon from "@mui/icons-material/Cancel"
import { formatDateToShortMonthDay } from "./utils"
import { countItemsWithStatusInCompleteCategory } from "./statusCategories"
import { style } from "d3"

interface StatusGridProps {
    title: React.ReactNode
    subtitle?: React.ReactNode
    statusItems: ItemData[]
}

interface ItemData {
    issueKey: string
    summary: string
    status: string
    startDate: string
    committedCompletionDate: string
    projectedCompletionDate: string
    completionDelta: number
    valueStream: string
    tShirtSize: string
    reporter: string
    assignee: string
    pod: string
    isDueInPi: boolean
}

interface ColumnData {
    dataKey: keyof ItemData
    label: string
    numeric?: boolean
    width: number
}

type Sample = [string, number, number, number, number]

const StatusGrid: React.FC<StatusGridProps> = ({ title, subtitle, statusItems }) => {
    const tableContainerDivRef = useRef<HTMLDivElement | null>(null)
    const virtuosoDivRef = useRef<HTMLDivElement | null>(null)
    const virtuoso = useRef<VirtuosoHandle | null>(null)
    const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined)
    const expandedHeight = 400

    const [sortedStatusItems, setSortedStatusItems] = useState<ItemData[]>(statusItems)

    useEffect(() => {
        const div = tableContainerDivRef.current
        if (div) {
            const isElementInView = (el: HTMLDivElement): boolean => {
                const rect = el.getBoundingClientRect()
                return rect.top >= 0 && rect.bottom <= window.innerHeight
            }

            const handleTransitionEnd = () => {
                if (selectedRow !== undefined && !isElementInView(div)) {
                    div.scrollIntoView({ behavior: "smooth", block: "center" })
                }
            }

            div.addEventListener("transitionend", handleTransitionEnd)

            // Cleanup listener on component unmount
            return () => {
                div.removeEventListener("transitionend", handleTransitionEnd)
            }
        }
    }, [selectedRow])

    const getTooltip = (index: number) => {
        const { issueKey, summary, status } = sortedStatusItems[index]
        return (
            <div className={styles.tooltip}>
                <div className={styles.tooltipTitle}>{issueKey}</div>
                <div>{summary}</div>
                <div className={styles.tooltipStatus}>
                    <b>Status:</b> {status}
                </div>
            </div>
        )
    }

    const getStatusColor = (index: number) => {
        const item = sortedStatusItems[index]
        const color = getColorForStatus(item.status)
        return color
    }

    const getStatusStyle = (index: number): CSSProperties => {
        const item = sortedStatusItems[index]
        let color = getColorForStatus(item.status)
        if (!item.isDueInPi) {
            color += "44"
        }

        const style: CSSProperties = {
            background: item.isDueInPi
                ? color
                : `repeating-linear-gradient(
                                    -45deg,
                                    ${color},
                                    ${color} 6px,
                                    #46529800 6px,
                                    #46529800 8px
                                    )`,
        }

        return style
    }

    const onClick = (index: number) => {
        setSelectedRow(index)
    }

    const onClose = () => {
        setSelectedRow(undefined)
    }

    const columns: ColumnData[] = [
        {
            width: 100,
            label: "Issue Key",
            dataKey: "issueKey",
        },
        {
            width: 100,
            label: "Status",
            dataKey: "status",
        },
        {
            width: 200,
            label: "Summary",
            dataKey: "summary",
        },
        {
            width: 80,
            label: "Committed",
            dataKey: "committedCompletionDate",
        },
        {
            width: 80,
            label: "Projected",
            dataKey: "projectedCompletionDate",
        },
        {
            width: 80,
            label: "Delta",
            dataKey: "completionDelta",
        },
        {
            width: 100,
            label: "Pod",
            dataKey: "pod",
        },
        {
            width: 150,
            label: "Assignee",
            dataKey: "assignee",
        },
        {
            width: 150,
            label: "Reporter",
            dataKey: "reporter",
        },
    ]

    const rows: ItemData[] = sortedStatusItems

    const VirtuosoTableComponents: TableComponents<ItemData> = {
        Scroller: React.forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
        Table: (props) => <Table {...props} sx={{ borderCollapse: "separate", tableLayout: "fixed" }} />,
        TableHead,
        TableRow: ({ item: _item, ...props }) => {
            let selected = false
            if (selectedRow !== undefined) {
                selected = _item.issueKey === sortedStatusItems[selectedRow].issueKey
            }
            const color = selected ? "rgb(245, 245, 245)" : "rgb(235, 235, 235)"
            const hoverColor = "rgb(245, 245, 245)"
            return (
                <TableRow
                    selected={selected}
                    sx={{
                        backgroundColor: `${color} !important`,
                        "&:hover": {
                            backgroundColor: `${hoverColor} !important`,
                        },
                    }}
                    {...props}
                />
            )
        },
        TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
    }

    function sortByDataKey(dataKey: keyof ItemData) {
        console.log("sortByDataKey", dataKey)
        // Create a copy of sortedStatusItems and sort it
        const sorted = [...sortedStatusItems].sort((a, b) => {
            if (a[dataKey] < b[dataKey]) {
                return -1 // for ascending order, swap -1 and 1 for descending order
            }
            if (a[dataKey] > b[dataKey]) {
                return 1
            }
            return 0
        })

        // Update the state with the sorted items
        setSortedStatusItems(sorted)
    }

    function fixedHeaderContent() {
        return (
            <TableRow sx={{ backgroundColor: "rgb(245, 245, 245)" }}>
                <TableCell key={"statusColorid"} style={{ width: "0px" }}></TableCell>
                {columns.map((column) => (
                    <TableCell key={column.dataKey} variant="head" align={column.numeric || false ? "right" : "left"} style={{ width: column.width }}>
                        <div
                            className={styles.tableHeaderCell}
                            onClick={() => {
                                // sortByDataKey(column.dataKey)
                            }}
                        >
                            {column.label}
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        )
    }

    function rowContent(_index: number, row: ItemData) {
        const statusChip = <TableCell key={row.issueKey + "statusColor"} align={"left"} style={{ backgroundColor: getColorForStatus(row.status) }}></TableCell>
        const cells = columns.map((column) => {
            let color = "rgb(225, 225, 225)"
            if (selectedRow === _index) {
                color = "rgb(245, 245, 245)"
            }

            const onIssueClick = (issueKey: string) => {
                const url = `https://carrier-digital.atlassian.net/browse/${issueKey}`
                window.open(url, "_blank")
            }

            const value = row[column.dataKey]
            let content = <div>{value ?? "--"}</div>

            if (column.dataKey === "issueKey") {
                content = (
                    <div
                        className={styles.issueLink}
                        onClick={() => {
                            onIssueClick(value as string)
                        }}
                    >
                        {value}
                    </div>
                )
            }

            if (column.dataKey === "committedCompletionDate" || column.dataKey === "projectedCompletionDate") {
                const shortDate = value ? formatDateToShortMonthDay(value as string) : "--"
                content = <div>{shortDate}</div>
            }

            if (column.dataKey === "completionDelta") {
                content = <div>{`${value} Days`}</div>
            }

            return (
                <TableCell key={column.dataKey} align={"left"}>
                    {content}
                </TableCell>
            )
        })
        return (
            <React.Fragment>
                {statusChip}
                {cells}
            </React.Fragment>
        )
    }

    // scroll to the selected row
    setTimeout(() => {
        if (virtuoso.current && selectedRow !== undefined) {
            virtuoso.current.scrollToIndex({
                index: selectedRow,
                align: "center",
                behavior: "auto",
            })
        }
    }, 0)

    const tableStyle: React.CSSProperties = selectedRow !== undefined ? { height: `${expandedHeight}px`, marginTop: "15px" } : { height: "0px", marginTop: "0px" }

    const cancelStyle: React.CSSProperties = selectedRow === undefined ? { transform: "scale(0)" } : { transform: "scale(1)" }

    const statusItemsInPi = sortedStatusItems.filter((statusItem) => statusItem.isDueInPi)
    const numComplete = countItemsWithStatusInCompleteCategory(statusItemsInPi)
    const totalCount = statusItemsInPi.length

    let willComplete = true
    for (const statusItem of sortedStatusItems) {
        if (!statusItem.isDueInPi && !isComplete(statusItem.status)) {
            willComplete = false
        }
    }
    // const countTitle = willComplete ? "Will Complete" : "Will Begin"
    // const countTitle = totalCount === numComplete ? "Complete" : "In Progress"

    const percent = Math.round((numComplete / totalCount) * 100)
    const countTitle = `${percent}% Complete`

    return (
        <div style={{ position: "relative" }}>
            <div className={styles.progressTitle}>
                <div>
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>{title}</div>
                    {subtitle && subtitle}
                </div>
                <div className={styles.progressComplete}>
                    <b>{countTitle}:</b> {numComplete} / {totalCount}
                </div>
            </div>
            <div className={styles.progressBarContainer}>
                <SteppedProgressBar selectedStep={selectedRow} stepCount={sortedStatusItems.length} getTooltip={getTooltip} onStepClick={onClick} getStepColor={getStatusColor} getStepStyle={getStatusStyle} />
            </div>
            <div style={{ position: "relative" }}>
                <div style={cancelStyle} className={styles.closeButton} onClick={onClose}>
                    <CancelIcon sx={{ width: "80%", height: "80%" }} />
                </div>
                <div style={tableStyle} ref={tableContainerDivRef} className={styles.tableContainer}>
                    <Paper ref={virtuosoDivRef} style={{ height: "400px", width: "100%" }}>
                        <TableVirtuoso ref={virtuoso} data={rows} components={VirtuosoTableComponents} fixedHeaderContent={fixedHeaderContent} itemContent={rowContent} />
                    </Paper>
                </div>
            </div>
        </div>
    )
}

export default StatusGrid
