import React from "react"
import styles from "./Pill.module.css"

interface PillProps {
    text: string
    color?: string
}

const Pill: React.FC<PillProps> = ({ text, color }) => {
    return (
        <div className={styles.pill} style={{ backgroundColor: color }}>
            {text}
        </div>
    )
}

export default Pill
