export default function sortByField<T>(field: keyof T, ascending: boolean = false) {
    return function (a: T, b: T) {
        const fieldA = String(a[field]).toLowerCase()
        const fieldB = String(b[field]).toLowerCase()

        const compareResult = fieldA.localeCompare(fieldB)

        if (ascending) {
            return compareResult
        } else {
            return compareResult * -1
        }
    }
}
