import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../Model/types"
import { useSelector } from "react-redux"
import styles from "./LoadingStatus.module.css"

interface LoadingStatusProps {
    title?: string
}

interface LoadingStatusState {
    message: string
}

const initialState: LoadingStatusState = {
    message: "",
}

export const selectLoadingMessage = (state: RootState) => state.loadingStatus.message

const loadingStatusSlice = createSlice({
    name: "loadingStatus",
    initialState,
    reducers: {
        setLoadingMessage: (state, action) => {
            state.message = action.payload
        },
    },
})

export const { setLoadingMessage } = loadingStatusSlice.actions
export const loadingStatusReducer = loadingStatusSlice.reducer

const LoadingStatus: React.FC<LoadingStatusProps> = ({ title }) => {
    const message = useSelector(selectLoadingMessage)
    const preMessage = title ?? "Loading"

    return (
        <div className={styles.container}>
            <span className={styles.loader}>{preMessage}</span>
            <div className={styles.message}>{message}</div>
        </div>
    )
}

export default LoadingStatus
