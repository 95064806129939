import { Tooltip, TooltipProps, Zoom, tooltipClasses } from "@mui/material"
import { CSSProperties, useCallback } from "react"
import { styled } from "@mui/material/styles"
import styles from "./ProcessStepNode.module.css"
import { Handle, Position } from "reactflow"
import React from "react"
import { ProcessNode } from "../processMapSlice"
import { RoleColorsContext } from "../ProcessMap"

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "white",
        color: "black",
        maxWidth: 330,
        fontSize: theme.typography.pxToRem(12),
        boxShadow: "1 1 10 10 black",
        padding: "20px",
    },
}))

function ProcessStepNode({ data }: any) {
    const onChange = useCallback((evt: any) => {
        console.log(evt.target.value)
    }, [])

    const roleColors = React.useContext(RoleColorsContext)

    const processNode: ProcessNode = data.processNode

    const tooltipDefaultProps: Partial<TooltipProps> = {
        enterDelay: 200,
        enterNextDelay: 100,
        placement: "top",
        arrow: false,
        disableInteractive: true,
    }

    const accountableRoleViews = processNode.accountableRoles.map((role) => {
        const style: CSSProperties = {
            backgroundColor: roleColors?.[role.id],
            padding: "3px 6px",
        }
        return (
            <div style={style} key={role.id}>
                {role.name}
            </div>
        )
    })

    const tooltipContent = (
        <div className={styles.tooltip}>
            <div className={styles.header}>
                <div>{processNode.name}</div>
                <div>{processNode.description}</div>
            </div>

            <div>
                <div>
                    <b>Definition of Ready:</b>
                </div>
                <div>{processNode.defOfReady}</div>
            </div>

            <div>
                <div>
                    <b>Definition of Done:</b>
                </div>
                <div>{processNode.defOfDone}</div>
            </div>

            <div className={styles.roleList}>
                <div>Accountable Roles:</div>
                <div className={styles.roleCollection}>{accountableRoleViews}</div>
            </div>
        </div>
    )

    const isAccountableRoleHovered = data.hoveredRoleId ? processNode.accountableRoles.some((role) => role.id === data.hoveredRoleId) : false

    const isInvolvedRoleHovered = data.hoveredRoleId ? processNode.involvedRoles.some((role) => role.id === data.hoveredRoleId) : false

    const isDimmed = !isAccountableRoleHovered && !isInvolvedRoleHovered && data.hoveredRoleId

    const containerClass = `
        ${styles.container} 
        ${data.selected ? styles.selected : ""} 
        ${isAccountableRoleHovered ? styles.highlightedFull : ""} 
        ${isInvolvedRoleHovered ? styles.highlightedHalf : ""}
        ${isDimmed ? styles.dimmed : ""}
    `

    let containerStyle: CSSProperties = {}
    if (isAccountableRoleHovered && roleColors && data.hoveredRoleId) {
        containerStyle = {
            border: `4px solid ${roleColors[data.hoveredRoleId]}`,
        }
    }
    if (isInvolvedRoleHovered && roleColors && data.hoveredRoleId) {
        containerStyle = {
            border: `4px dashed ${roleColors[data.hoveredRoleId]}`,
        }
    }

    return (
        <>
            <Handle type="target" position={Position.Top} />

            <HtmlTooltip key={data.id} title={<React.Fragment>{tooltipContent}</React.Fragment>} {...tooltipDefaultProps} TransitionComponent={Zoom}>
                <div className={containerClass} style={containerStyle}>
                    <div className={styles.label}>{data.label}</div>
                </div>
            </HtmlTooltip>

            <Handle type="source" position={Position.Top} />
        </>
    )
}

export default ProcessStepNode
