import { ProjectCompositionItem } from "./projectCompositionSlice"

export function findLevelById(items: ProjectCompositionItem[], id: string, currentLevel: number = 1): number | null {
    for (const item of items) {
        if (item.id === id) {
            return currentLevel
        }

        const childLevel = findLevelById(item.children, id, currentLevel + 1)
        if (childLevel !== null) {
            return childLevel
        }
    }

    return null
}

export function getLevels(selectedLevel: number = 0, valueFormatter: (params: any) => void): any[] {
    const levels: any[] = [
        // level 0
        [
            {},
            {
                radius: ["20%", "40%"],
                label: {
                    rotate: 0,
                    show: true,
                    formatter: valueFormatter,
                },
                emphasis: {
                    focus: "descendant",
                },
            },
            {
                radius: ["40%", "70%"],
                label: {
                    show: false,
                    formatter: valueFormatter,
                },

                emphasis: {
                    focus: "descendant",
                },
            },
            {
                radius: ["70%", "85%"],
                label: {
                    show: false,
                    formatter: valueFormatter,
                },
                emphasis: {
                    focus: "descendant",
                },
            },
            {
                radius: ["85%", "90%"],
                label: {
                    show: false,
                    formatter: valueFormatter,
                },
                emphasis: {
                    focus: "ancestor",
                },
            },
        ],
        // level 1
        [
            {},
            {
                radius: ["15%", "40%"],
                label: {
                    rotate: 0,
                    show: true,
                },
                emphasis: {
                    focus: "descendant",
                },
            },
            {
                radius: ["40%", "70%"],
                label: {
                    show: false,
                },
                emphasis: {
                    focus: "descendant",
                },
            },
            {
                radius: ["70%", "85%"],
                label: {
                    show: false,
                },
                emphasis: {
                    focus: "descendant",
                },
            },
            {
                radius: ["85%", "90%"],
                label: {
                    show: false,
                },
                emphasis: {
                    focus: "ancestor",
                },
            },
        ],
    ]

    // constrain index to bounds of levels array
    let selectedIndex = selectedLevel
    if (selectedIndex < 0) {
        selectedIndex = 0
    }
    if (selectedIndex > levels.length - 1) {
        selectedIndex = levels.length - 1
    }

    return levels[selectedIndex]
}
