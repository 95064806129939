import ReactFlow, { MarkerType, Node, Edge } from "reactflow"
import "reactflow/dist/style.css"
import styles from "./ProcessMap.module.css"
import { useSelector } from "react-redux"
import { useDispatch } from "../Model/hooks"
import { ProcessNode, StandardRole, fetchProcessMap, selectTiers } from "./processMapSlice"
import { selectLoading, selectNodes } from "./processMapSlice"
import { ReactNode, useEffect, useState } from "react"
import "./reactFlowStyles.css"

import FloatingEdge from "./FloatingEdge.js"
import FloatingConnectionLine from "./FloatingConnectionLine.js"
import ArcEdge from "./ArcEdge"
import LoadingStatus from "../Components/LoadingStatus/LoadingStatus"
import ProcessStepNode from "./CustomNodes/ProcessStepNode"
import SideBar from "./SideBar/SideBar"
import ProcessMapDetails from "./SideBar/ProcessMapDetails"
import StandardRoleDetails from "./SideBar/StandardRoleDetails"
import { Resource } from "../Model/types"
import Modal from "../Components/Modal"
import ResourceDetailView from "../Components/ResourceDetailView"
import { selectResources } from "../Model/slices/resourcesSlice"
import Color from "color"
import React from "react"
import TierContainerNode from "./CustomNodes/TierContainerNode"
import PeopleIcon from "@mui/icons-material/People"
import AllRoles from "./SideBar/AllRoles"

const edgeTypes = {
    floating: FloatingEdge,
    arc: ArcEdge,
}

const nodeTypes = {
    processStep: ProcessStepNode,
    tierContainer: TierContainerNode,
}

export const RoleColorsContext = React.createContext<{ [id: string]: string } | undefined>(undefined)

interface ProcessMapProps {}

const ProcessMap: React.FC<ProcessMapProps> = () => {
    const loading = useSelector(selectLoading)
    const processNodes = useSelector(selectNodes)
    const tiers = useSelector(selectTiers)
    const resources = useSelector(selectResources)

    const [selectedNode, setSelectedNode] = useState<ProcessNode | undefined>()
    const [hoveredRole, setHoveredRole] = useState<StandardRole | undefined>()
    const [selectedRole, setSelectedRole] = useState<StandardRole | undefined>()
    const [modalView, setModalView] = useState<ReactNode | undefined>()
    const [sideBarViews, setSideBarViews] = useState<ReactNode[]>([])
    const [sideBarVisible, setSideBarVisible] = useState(false)
    const [isTierSelected, setIsTierSelected] = useState<Record<string, boolean>>({})

    const dispatch = useDispatch()

    useEffect(() => {
        if (loading === "idle") {
            dispatch(fetchProcessMap())
        }
    }, [loading, dispatch])

    if (loading !== "loaded") return <LoadingStatus />

    const uniqueRoles = getUniqueRoles(processNodes)
    uniqueRoles.sort((a, b) => {
        if (a.name < b.name) {
            return -1
        }
        if (a.name > b.name) {
            return 1
        }
        return 0
    })

    const roleColors: { [id: string]: string } = {}
    const roleCount = uniqueRoles.length
    let roleIndex = 0
    for (const role of uniqueRoles) {
        const hue = (roleIndex / roleCount) * 360
        const color = Color(`hsl(${hue}, 50%, 70%)`)

        roleIndex++
        roleColors[role.id] = color.hex()
    }

    const toggleTierSelected = (key: string) => {
        setIsTierSelected((prevDict) => ({
            ...prevDict,
            [key]: !prevDict[key],
        }))
    }

    const onNodeClick = (event: React.MouseEvent, node: Node) => {
        if (node.type === "processStep") {
            if (selectedNode?.id === node.id) {
                onSideBarClose()
            } else {
                event.stopPropagation()
                setSelectedNode(node.data.processNode)
                addNodeSidebarView(node.data.processNode)
                setSideBarVisible(true)
                setHoveredRole(undefined)
                setSelectedRole(undefined)
            }
        }

        if (node.type === "tierContainer") {
            event.stopPropagation()
            toggleTierSelected(node.id)
        }
    }

    const addNodeSidebarView = (processNode: ProcessNode) => {
        const detailView = <ProcessMapDetails key={processNode.id} node={processNode} onRoleHover={onRoleHover} onRoleHoverEnd={onRoleHoverEnd} onRoleClick={onRoleChipClick} />

        setSideBarViews([detailView])
    }

    const onSideBarClose = () => {
        setSelectedNode(undefined)
        setSideBarVisible(false)
        setHoveredRole(undefined)
        setSelectedRole(undefined)
    }

    const onSideBarBack = () => {
        setSideBarViews((prevViews) => [prevViews.slice(0, -1)])
        setSelectedRole(undefined)
        setHoveredRole(undefined)
    }

    const onCanvasClick = () => {
        onSideBarClose()
    }

    const onRoleHover = (role: StandardRole) => {
        setHoveredRole(role)
    }

    const onRoleHoverEnd = () => {
        setHoveredRole(undefined)
    }

    const onResourceClick = (resource: Resource) => {
        console.log("🚀 ~ file: ProcessMap.tsx:197 ~ onResourceClick ~ resource:", resource)
        setModalView(
            <ResourceDetailView
                resource={resource}
                onSelectResource={(id) => {
                    const newResource = resources.find((r) => r.id === id)
                    if (newResource) {
                        onResourceClick(newResource)
                    }
                }}
            />
        )
    }

    const onRoleChipClick = (role: StandardRole) => {
        const roleDetail = <StandardRoleDetails key={role.id} role={role} onResourceClick={onResourceClick} />
        setSelectedRole(role)
        setSideBarViews((prevViews) => [prevViews[0], roleDetail])
    }

    const onRoleBarClick = (role: StandardRole) => {
        if (selectedRole?.id === role.id) {
            onSideBarClose()
        } else {
            const roleDetail = <StandardRoleDetails key={role.id} role={role} onResourceClick={onResourceClick} />
            setSelectedRole(role)
            setSelectedNode(undefined)
            setSideBarVisible(true)
            setSideBarViews([roleDetail])
        }
    }

    const onModalClose = () => {
        setModalView(undefined)
    }

    const onShowAllRoles = () => {
        const allRolesView = <AllRoles key={"all-roles-view"} roles={uniqueRoles} onRoleHover={onRoleHover} onRoleHoverEnd={onRoleHoverEnd} onRoleClick={onRoleChipClick} />

        setSideBarViews([allRolesView])
        setSideBarVisible(true)
        setHoveredRole(undefined)
        setSelectedRole(undefined)
    }

    let edges: Edge[] = []

    // manage the y position of the containers
    let yInsert = 0
    const sortedTiers = [...tiers].sort((a, b) => a.level - b.level)
    const nodeWidth = 160
    const sideMargin = 60
    const nodeSpacing = 80

    const shownNodes: Node[] = sortedTiers.flatMap((tier) => {
        // find the nodes that are in this tier
        const tierNodes = processNodes.filter((node) => {
            return node.workflowTier.id === tier.id
        })
        // skip tiers that have no nodes
        if (tierNodes.length === 0) return []

        // get the longest path through this tier's nodes
        const longestPath = getLongestPath(tierNodes)
        if (!longestPath) return []

        // return a node object for each of the nodes in this tier
        const childNodes = longestPath.map((node, index) => {
            // create edges
            if (index < longestPath.length - 1) {
                const nextId = longestPath[index + 1].id
                let linkCount = 0
                for (let feeds of node.feedsInto) {
                    let type = ""
                    if (feeds === nextId) {
                        type = "floating"
                    } else {
                        type = "arc"
                    }

                    const targetNode = processNodes.find((item) => {
                        return item.id === feeds
                    })
                    if (node.workflowTier.id !== targetNode?.workflowTier.id) {
                        type = "floating"
                    }

                    edges.push({
                        id: `${node.id}-${nextId}-${linkCount}`,
                        source: node.id,
                        target: feeds,
                        animated: true,
                        type: type,
                        interactionWidth: 0,
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            width: 10,
                            height: 10,
                            color: "gray",
                        },
                        style: {
                            strokeWidth: 2,
                            stroke: "gray",
                            transition: "all 0.3s ease-in-out",
                        },
                    })

                    linkCount++
                }
            }

            const isSelected = selectedNode ? selectedNode.id === node.id : false
            let x = sideMargin
            if (index > 0) {
                x += index * (nodeWidth + nodeSpacing)
            }

            // create nodes
            return {
                id: node.id,
                position: {
                    x: x,
                    y: 90,
                },
                type: "processStep",
                data: {
                    label: node.name,
                    processNode: node,
                    selected: isSelected,
                    hoveredRoleId: hoveredRole?.id ?? selectedRole?.id,
                },
                style: { transition: "all 0.3s ease-in-out" },
                parentNode: node.workflowTier.id,
                draggable: false,
                // selectable: false,
            } as Node
        })

        const parentWidth = childNodes.length * nodeWidth + sideMargin * 2 + (childNodes.length - 1) * nodeSpacing

        const roles = getUniqueRoles(longestPath)
        const roleCount = roles.length

        const parentHeightExpanded = 280 + roleCount * 40
        const parentHeightContracted = 200
        const parentHeight = isTierSelected[tier.id] ? parentHeightExpanded : parentHeightContracted

        const parentNode = {
            id: tier.id,
            type: "tierContainer",
            position: {
                x: -parentWidth,
                y: yInsert,
            },
            data: {
                label: tier.name,
                isSelected: isTierSelected[tier.id],
                processNodes: longestPath,
                nodeWidth: nodeWidth,
                nodeSpacing: nodeSpacing,
                onClick: (role: StandardRole) => {
                    // console.log("role bar click", role)
                    onRoleBarClick(role)
                },
                selectedRoleId: selectedRole?.id ?? hoveredRole?.id,
            },
            style: { width: parentWidth, height: parentHeight, transition: "all 0.3s ease-in-out" },
            selectable: false,
            draggable: false,
        } as Node

        yInsert += isTierSelected[tier.id] ? parentHeight + 60 : 240

        return [parentNode, ...childNodes]
    })

    const showSideBarBackButton = sideBarViews.length > 1

    return (
        <RoleColorsContext.Provider value={roleColors}>
            <div className={styles.container}>
                {modalView && <Modal onClose={onModalClose}>{modalView}</Modal>}
                <SideBar onClose={onSideBarClose} onBack={onSideBarBack} visible={sideBarVisible} showBackButton={showSideBarBackButton}>
                    {sideBarViews}
                </SideBar>

                <div
                    className={styles.showAllRoles}
                    onClick={() => {
                        onShowAllRoles()
                    }}
                >
                    <div className={styles.iconHolder}>
                        <PeopleIcon className={styles.icon} />
                    </div>
                    <div className={styles.iconTitle}>Show All Roles</div>
                </div>

                <div
                    onClick={() => {
                        onCanvasClick()
                    }}
                    style={{ width: "100vw", height: "100vh" }}
                >
                    <ReactFlow nodes={shownNodes} edges={edges} fitView edgeTypes={edgeTypes as any} nodeTypes={nodeTypes} onNodeClick={onNodeClick} connectionLineComponent={FloatingConnectionLine as any} />
                </div>
            </div>
        </RoleColorsContext.Provider>
    )
}

function getLongestPath(nodes: ProcessNode[]): ProcessNode[] {
    const nodeMap: Map<string, ProcessNode> = new Map()
    let maxPath: ProcessNode[] = []

    // Build the graph representation.
    nodes.forEach((node) => nodeMap.set(node.id, node))

    function dfs(currentNode: ProcessNode, visitedSet: Set<string>, currentPath: ProcessNode[]): void {
        if (visitedSet.has(currentNode.id)) {
            // This node has already been visited in the current path; hence, we found a cycle. Return.
            return
        }

        // Mark the node as visited for the current path.
        visitedSet.add(currentNode.id)

        // Update the current path.
        const newPath = [...currentPath, currentNode]

        // Update the maxPath if the current path is longer.
        if (newPath.length > maxPath.length) {
            maxPath = newPath
        }

        // Iterate over the nodes that this node feeds into.
        ;(currentNode.feedsInto || []).forEach((feedId) => {
            const nextNode = nodeMap.get(feedId)
            if (nextNode) {
                dfs(nextNode, new Set(visitedSet), newPath)
            }
        })

        // Backtrack: Remove the node from the visited set for the current path.
        visitedSet.delete(currentNode.id)
    }

    // Start DFS from each node.
    nodes.forEach((node) => dfs(node, new Set(), []))

    return maxPath
}

function isAncestor(sourceId: string, targetId: string, nodes: ProcessNode[]): boolean {
    const nodeMap: Map<string, ProcessNode> = new Map()
    nodes.forEach((node) => nodeMap.set(node.id, node))

    const visited: Set<string> = new Set()

    function dfs(currentId: string): boolean {
        // If we have already visited this node, return false to avoid cycles.
        if (visited.has(currentId)) return false

        const currentNode = nodeMap.get(currentId)
        if (!currentNode) return false

        visited.add(currentId)

        if (currentNode.feedsInto && currentNode.feedsInto.includes(sourceId)) return true

        for (const downstreamNode of currentNode.feedsInto || []) {
            if (dfs(downstreamNode)) return true
        }

        return false
    }

    return dfs(targetId)
}

function getUniqueRoles(nodes: ProcessNode[]): StandardRole[] {
    // Extract and flatten all roles from each ProcessNode
    const allRoles = nodes.flatMap((node) => [...node.accountableRoles, ...node.involvedRoles])

    // Use reduce to filter out duplicates based on the id property
    const uniqueRoles: { [id: string]: StandardRole } = allRoles.reduce((acc: any, role) => {
        if (!acc[role.id]) {
            acc[role.id] = role
        }
        return acc
    }, {})

    // Convert the object back to an array
    return Object.values(uniqueRoles)
}

export default ProcessMap
