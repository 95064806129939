import { Node } from './data';
import * as d3 from 'd3';

export const drawCircles = (
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  width: number,
  height: number,
  nodes: Node[],
  sizeScale: d3.ScalePower<number, number, never>
) => {
  // Clear previous circles
  svg.selectAll("g").remove();

  const nodeGroups = svg.selectAll("g")
    .data(nodes)
    .enter()
    .append("g")
    .attr("transform", (d) => `translate(${d.x},${d.y})`);

  nodeGroups.append("circle")
    .attr("r", (d) => sizeScale(d.value))
    .attr("fill", (d) => d.color);

  nodeGroups.each(function(d: Node) {
    const group = d3.select(this);
    const radius = sizeScale(d.value);

    // Draw the text
    const text = group.append("text")
      .style("fill", "#fff") // Set text color to white for better contrast
      .style("text-anchor", "middle")
      .style("dominant-baseline", "middle")
      .style("font-family", "GibsonCustom")
      .style("font-weight", "bold")
      .style("font-size", `${radius / 4}px`);

    const words = d.name.split(' ');
    let lines: string[] = [];
    let currentLine = words[0];

    // Temporary tspan to measure text width
    let tempTspan = text.append("tspan").text(currentLine);
    let tempWidth = tempTspan.node()?.getComputedTextLength() ?? 0;

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      tempTspan.text(currentLine + ' ' + word);
      const newWidth = tempTspan.node()?.getComputedTextLength() ?? 0;
      if (newWidth < radius * 1.8) {
        currentLine += ' ' + word;
        tempWidth = newWidth;
      } else {
        lines.push(currentLine);
        currentLine = word;
        tempTspan.text(currentLine);
        tempWidth = tempTspan.node()?.getComputedTextLength() ?? 0;
      }
    }
    lines.push(currentLine);
    tempTspan.remove();

    const lineHeight = radius / 4 * 1.2;
    const textHeight = lines.length * lineHeight;
    let y = -textHeight / 2 + lineHeight / 2;

    lines.forEach((line) => {
      text.append("tspan")
        .attr("x", 0)
        .attr("y", y)
        .text(line);
      y += lineHeight;
    })
  })
}
