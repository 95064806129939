import { ReactComponent as HomeIcon } from "../Assets/home.svg"
import { ReactComponent as ValueStreamsIcon } from "../Assets/tableChart.svg"
import { ReactComponent as ReportingStructureIcon } from "../Assets/tenancy.svg"
import { ReactComponent as FunctionalStructureIcon } from "../Assets/accountTree.svg"
import { ReactComponent as GridIcon } from "../Assets/grid.svg"

import { ReactComponent as ServicesIcon } from "../Assets/services.svg"
import AssessmentIcon from "@mui/icons-material/Assessment"
import MediationIcon from "@mui/icons-material/Mediation"
import TodayIcon from "@mui/icons-material/Today"
import { Science, Waves, Person, Home, DeviceHub, Lan, PersonSearch } from "@mui/icons-material"
import { OktaUser } from "../App"

const approvedEmails = [
    "jason.smith1@carrier.com",
    "christian.tepley@carrier.com",
    "lisa.vossler@carrier.com",
    "dan.levine@carrier.com",
    "andy.sousa@carrier.com",
    "luciana.ruiz@carrier.com",
    "jennifer.beske@carrier.com",
    "benjamin.hillson@carrier.com",
    "shannon.johnson1@carrier.com",
    "theresa.lafranchise@carrier.com",
]

export function getMenuData(oktaUser: OktaUser) {
    const home = {
        name: "Home",
        icon: <Home className="sidebar-svg" />,
        path: "/",
        end: true,
    }

    const metrics = {
        name: "Metrics",
        icon: <AssessmentIcon className="sidebar-svg" />,
        path: "/metrics",
    }

    const processMap = {
        name: "Process Map",
        icon: <MediationIcon className="sidebar-svg" />,
        path: "/process",
    }

    const valueStreams = {
        name: "Value Streams",
        icon: <Waves className="sidebar-svg" />,
        path: "/value-streams",
    }

    const resourceFinder = {
        name: "Resource Finder",
        icon: <PersonSearch className="sidebar-svg" />,
        path: "/resource-finder",
    }

    const calendar = {
        name: "Calendar",
        icon: <TodayIcon className="sidebar-svg" />,
        path: "/calendar",
    }

    const functionalStructure = {
        name: "Functional Structure",
        icon: <DeviceHub className="sidebar-svg" />,
        path: "/functional-structure",
    }

    const reportingStructure = {
        name: "Reporting Structure",
        icon: <Lan className="sidebar-svg" />,
        path: "/reporting-structure",
    }

    const userResearch = {
        name: "User Research",
        icon: <Science className="sidebar-svg" />,
        path: "/user-research",
    }

    if (oktaUser.email) {
        if (approvedEmails.includes(oktaUser.email.toLowerCase())) {
            return [home, metrics, processMap, valueStreams, resourceFinder, calendar, functionalStructure, reportingStructure, userResearch]
        }
    }

    return [home, metrics, processMap, valueStreams, resourceFinder, calendar, reportingStructure, userResearch]
}
