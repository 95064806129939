// localStorageUtils.ts
import { LayoutData } from "./types"

const CURRENT_LAYOUT_KEY = "currentLayoutData"
const SAVED_LAYOUTS_KEY = "savedLayoutData"

export const loadCurrentLayoutData = (): LayoutData | null => {
    const data = localStorage.getItem(CURRENT_LAYOUT_KEY)
    return data ? JSON.parse(data) : null
}

export const saveCurrentLayoutData = (layoutData: LayoutData) => {
    localStorage.setItem(CURRENT_LAYOUT_KEY, JSON.stringify(layoutData))
}

export const loadSavedLayouts = (): LayoutData[] => {
    const data = localStorage.getItem(SAVED_LAYOUTS_KEY)
    return data ? JSON.parse(data) : []
}

export const saveNewLayoutData = (layout: LayoutData) => {
    const layouts = loadSavedLayouts()
    layouts.push(layout)
    localStorage.setItem(SAVED_LAYOUTS_KEY, JSON.stringify(layouts))
}

export const clearAllLocalStorage = () => {
    localStorage.removeItem(CURRENT_LAYOUT_KEY)
    localStorage.removeItem(SAVED_LAYOUTS_KEY)
}
// clearAllLocalStorage()
