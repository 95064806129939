import React, { useState, useEffect } from "react"
import styles from "./ProjectCard.module.css"
import { ResearchProject } from "./userResearchSlice"
import { TagColorsContext } from "./UserResearch"
import { getSubstringBeforeFirstDot } from "./utils"

interface ResearchProjectCardProps {
    project: ResearchProject
    onSelect: (project: ResearchProject) => void
    onHover: (project: ResearchProject) => void
    onHoverEnd: () => void
}

const ProjectCard: React.FC<ResearchProjectCardProps> = ({ project, onSelect, onHover, onHoverEnd }) => {
    const tagColors = React.useContext(TagColorsContext)
    let sortedTags = project.tags ? [...project.tags].sort() : []
    const colorBars = sortedTags.map((tag) => {
        const color = tagColors ? tagColors[tag] : "lightgray"
        return <div key={tag} className={styles.colorBar} style={{ backgroundColor: color }}></div>
    })

    const description = getSubstringBeforeFirstDot(project.description)

    return (
        <div
            className={styles.researchProjectCard}
            onClick={() => {
                onSelect(project)
            }}
            onMouseOver={() => {
                onHover(project)
            }}
            onMouseOut={() => {
                onHoverEnd()
            }}
        >
            <div className={styles.colorBarContainer}>{colorBars}</div>
            <div className={styles.name}>{project.name}</div>
            <div className={styles.description}>{description}</div>
            <div className={styles.details}>
                <b>Output: </b>
                {project.output ?? "--"}
            </div>
            {/* <div className={styles.question}>{project.question}</div> */}
        </div>
    )
}

export default ProjectCard
