import React from "react"
import { BaseEdge, BezierEdge, EdgeProps } from "reactflow"

export default function ArcEdge(props: EdgeProps) {
    // we are using the default bezier edge when source and target ids are different
    // if (props.source !== props.target) {
    //     return <BezierEdge {...props} />
    // }

    const { sourceX, sourceY, targetX, targetY, id, markerEnd, style } = props
    const dist = Math.abs(targetX - sourceX)
    const radiusX = (sourceX - targetX) * 0.5
    const radiusY = dist * 0.1
    const edgePath = `M ${sourceX} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${targetX} ${targetY}`

    return <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
}
