import React from "react"
import styles from "./ResourceTile.module.css"
import ProfileImage from "../Components/ProfileImage"
import { Resource } from "../Model/types"
import { motion } from "framer-motion"

interface ResourceTileProps {
    resource: Resource
    onClick: (resourceId: string) => void
    index: number
}

const ResourceTile: React.FC<ResourceTileProps> = ({
    resource,
    onClick,
    index,
}) => {
    const imageUrl = resource.slackUser?.profile.image_192

    return (
        <motion.div
            className={styles.container}
            onClick={() => {
                onClick(resource.id)
            }}
            initial={{ opacity: 0, scale: 0.9, translateY: 100 }}
            animate={{ opacity: 1, scale: 1, translateY: 0 }}
            transition={{
                duration: 0.3,
                delay: Math.min(index * 0.02, 1),
                ease: "easeOut",
            }}
        >
            <div className={styles.profileImage}>
                <ProfileImage imageUrl={imageUrl} />
            </div>
            <div>
                <div className={styles.profileName}>{resource.name}</div>
                <div className={styles.profileTitle}>{resource.title}</div>
            </div>
        </motion.div>
    )
}

export default ResourceTile
