import { Dispatch } from "@reduxjs/toolkit"
import { serverUrl } from "../../Model/serverConfig"
import { AirtableIds } from "./airtableIds"

export const fetchYarnData = async (tableId: string, viewId?:string) => {
    let url = `${serverUrl}/yarn-data/${tableId}/?`
    if (viewId) {
        url += `viewId=${viewId}`
    }
    try {
        const response = await fetch(url, {
            headers: {
            },
        })
        const data = await response.json()
        return data
    } catch (error) {
        console.error("Error fetching data from server:", error)
        return []
    }
}

export const fetchProductsData = async () => {
    const timer = new Timer("fetchProductData")
    const data = await fetchYarnData(AirtableIds.productsTableId)
    timer.logTime()  
    return data
}

export const fetchProductDeviceCountsData = async () => {
    const timer = new Timer("fetchProductData")
    const data = await fetchYarnData(AirtableIds.productDeviceCountsTableId)
    timer.logTime()  
    return data
}


class Timer {

    start: Date
    title: string

    constructor(title: string = "Fetching Data") {
        this.start = new Date()
        this.title = title
    }

    logTime() {
        const endTime = new Date()
        const timeTaken = (endTime.getTime() - this.start.getTime()) / 1000  // Calculate the difference in seconds

        console.log(`${this.title} took ${timeTaken} seconds.`);
    }
}