import React, { ReactNode } from "react"
import styles from "./ValueStreamDetails.module.css"
import { Role, ValueStream } from "../Model/types"
import RoleView from "./Views/RoleView"
import { collectPods } from "./SimplifiedValueStreams"
import PodCard from "./PodCard"
import AreaPathCard from "./AreaPathCard"

interface ValueStreamDetailsProps {
    valueStream: ValueStream
    onRoleClick: (role: Role) => void
}

const ValueStreamDetails: React.FC<ValueStreamDetailsProps> = ({ valueStream, onRoleClick }) => {
    const leads = valueStream.support.map((role) => {
        return (
            <div key={"platformLead-" + role.id} className={styles.roleContainer}>
                <RoleView role={role} onClick={onRoleClick} />
            </div>
        )
    })

    const pods = collectPods(valueStream)
    const podViews = pods.map((pod) => {
        return <PodCard key={pod.id} pod={pod} onRoleClick={onRoleClick} />
    })

    const apCards = valueStream.areaPaths?.map((ap) => {
        return <AreaPathCard key={ap.id} areaPath={ap} onRoleClick={onRoleClick} />
    })

    const description = valueStream.description ? formatBoldText(valueStream.description) : undefined

    return (
        <div className={styles.valueStreamDetails}>
            <div className={styles.title}>{valueStream.name}</div>
            {description && <div className={styles.description}>{description}</div>}

            <div className={styles.leads}>{leads}</div>
            {apCards && apCards.length > 0 && (
                <div>
                    <div className={styles.sectionHeader}>Area Paths</div>
                    <div className={styles.areaPaths}>{apCards}</div>
                </div>
            )}
            {pods.length > 0 && (
                <div>
                    <div className={styles.sectionHeader}>Pods</div>
                    <div className={styles.pods}>{podViews}</div>
                </div>
            )}
        </div>
    )
}

function formatBoldText(text: string): ReactNode[] {
    // Split the text based on "**" into an array
    const parts = text.split(/\*\*/)

    // Reduce the parts array to a single array of ReactNode with bold formatting applied appropriately
    return parts.reduce<ReactNode[]>((acc, part, index) => {
        // Add bold for even indexes (inside **)
        if (index % 2 === 1) {
            acc.push(<strong key={index}>{part}</strong>)
        } else {
            acc.push(part)
        }
        return acc
    }, [])
}

export default ValueStreamDetails
