import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import styles from "./PasswordProtected.module.css"

interface PasswordProtectedProps {
    children: React.ReactNode
}

const PasswordProtected: React.FC<PasswordProtectedProps> = ({ children }) => {
    const [password, setPassword] = useState<string>("")
    const [accessGranted, setAccessGranted] = useState<boolean>(false)

    const correctPassword = "yarn" // Set your password here
    const cookieKey = "passwordProtectedAccess" // Cookie key

    useEffect(() => {
        // Check cookie on component mount
        const hasAccess = Cookies.get(cookieKey)
        if (hasAccess) {
            setAccessGranted(true)
        }
    }, [])

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (password === correctPassword) {
            setAccessGranted(true)
            Cookies.set(cookieKey, "true", { expires: 7 }) // Set cookie to expire in 7 days
        } else {
            alert("Incorrect password, try again!")
            setPassword("")
        }
    }

    if (!accessGranted) {
        return (
            <div className={styles.passwordProtected}>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <input className={styles.input} type="password" value={password} onChange={handlePasswordChange} placeholder="Enter password" />
                    <button className={styles.button} type="submit">
                        Submit
                    </button>
                </form>
            </div>
        )
    }

    return <div>{children}</div>
}

export default PasswordProtected
