import React, { useState } from "react"
import styles from "./MainMenu.module.css"
import { DashboardCustomize, Input, MoreHoriz, Save, ViewComfy } from "@mui/icons-material"
import { Edit, EditOff, Addchart } from "@mui/icons-material"

export interface MainMenuProps {
    isEditing: boolean
    toggleEdit: () => void
    addTile: () => void
    loadLayout: () => void
    saveLayout: () => void
}

const MainMenu: React.FC<MainMenuProps> = ({ isEditing, toggleEdit, addTile, loadLayout, saveLayout }) => {
    const [isOpen, setIsOpen] = useState(false)

    const enableEdit = (
        <div key={"enable-edit"} className={styles.button} onClick={toggleEdit}>
            <div>
                <Edit />
            </div>
            <div>Edit Layout</div>
        </div>
    )

    const disableEdit = (
        <div key={"disable-edit"} className={styles.button} onClick={toggleEdit}>
            <div>
                <EditOff />
            </div>
            <div>Done Editing</div>
        </div>
    )

    const addTileButton = (
        <div key={"add-tile"} className={styles.button} onClick={addTile}>
            <div>
                <Addchart />
            </div>
            <div>Add Tile</div>
        </div>
    )

    const loadLayoutButton = (
        <div key={"load-layout"} className={styles.button} onClick={loadLayout}>
            <div>
                <Input />
            </div>
            <div>Load Layout</div>
        </div>
    )

    const saveLayoutButton = (
        <div key={"save-layout"} className={styles.button} onClick={saveLayout}>
            <div>
                <Save />
            </div>
            <div>Save Layout</div>
        </div>
    )

    const toggleEditButton = isEditing ? disableEdit : enableEdit

    const buttons = isEditing ? [toggleEditButton, saveLayoutButton, loadLayoutButton, addTileButton] : [toggleEditButton, saveLayoutButton, loadLayoutButton]

    return (
        <div className={styles.mainMenu}>
            <div className={styles.toggle} onMouseEnter={() => setIsOpen(true)}>
                <MoreHoriz />
            </div>
            {isOpen && (
                <div className={styles.menu} onMouseLeave={() => setIsOpen(false)}>
                    {buttons}
                </div>
            )}
        </div>
    )
}

export default MainMenu
