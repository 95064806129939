import React, { useEffect, useRef, useState } from "react"
import styles from "./Tile.module.css"
import { Close, MoreHoriz } from "@mui/icons-material"
import { TileData } from "./Utils/types"

export interface TileProps {
    tileData: TileData
    onRemoveTile: (i: string) => void
    isEditing: boolean
    children: (size: { width: number; height: number }) => React.ReactNode
}

const Tile: React.FC<TileProps> = ({ tileData, onRemoveTile, children, isEditing }) => {
    const [size, setSize] = useState({ width: 0, height: 0 })
    const tileRef = useRef<HTMLDivElement>(null)
    const key = tileData.layout.i
    

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            if (!entries[0].target) return

            const { width, height } = entries[0].contentRect
            // Debounce the setSize function
            const timeoutId = setTimeout(() => {
                setSize({ width, height })
            }, 100) // 100ms delay

            return () => clearTimeout(timeoutId) // Cleanup the timeout
        })

        if (tileRef.current) {
            resizeObserver.observe(tileRef.current)
        }

        return () => {
            resizeObserver.disconnect()
            if (tileRef.current) {
                resizeObserver.unobserve(tileRef.current)
            }
        }
    }, [])

    const onRemoveClick = () => {
        onRemoveTile(key)
    }

    let tileClassName = `${styles.tile}`
    if (isEditing) {
        tileClassName += ` ${styles.editing}`
    }

    const onSettingsButton = () => {}

    const settingsButton = (
        <div key={"settings-button"} className={`no-drag ${styles.closeButton}`} onClick={onSettingsButton}>
            <MoreHoriz style={{ width: "100%" }} />
        </div>
    )

    const closeButton = (
        <div key={"close-button"} className={`no-drag ${styles.closeButton}`} onClick={onRemoveClick}>
            <Close style={{ width: "100%" }} />
        </div>
    )

    const buttons = tileData.params ? [settingsButton, closeButton] : [closeButton]

    return (
        <div ref={tileRef} className={tileClassName}>
            {children(size)}

            {isEditing && <div className={styles.buttonGroup}>{buttons}</div>}
        </div>
    )
}

export default Tile
