import { StandardRole } from "../processMapSlice"
import styles from "./AllRoles.module.css"
import React, { CSSProperties } from "react"
import { RoleColorsContext } from "../ProcessMap"

interface AllRolesProps {
    roles: StandardRole[]
    onRoleHover?: (role: StandardRole) => void
    onRoleHoverEnd?: () => void
    onRoleClick?: (role: StandardRole) => void
}

const AllRoles: React.FC<AllRolesProps> = ({ roles, onRoleHover, onRoleHoverEnd, onRoleClick }) => {
    const roleColors = React.useContext(RoleColorsContext)

    const getRoleStyle = (role: StandardRole) => {
        const style: CSSProperties = {
            backgroundColor: roleColors?.[role.id],
            border: `2px solid ${roleColors?.[role.id]}`,
        }
        return style
    }

    const RoleView = (props: { role: StandardRole }) => {
        const { role } = props
        const style = getRoleStyle(role)
        return (
            <div
                style={style}
                onMouseEnter={() => {
                    onRoleHover?.(role)
                }}
                onMouseLeave={() => {
                    onRoleHoverEnd?.()
                }}
                onClick={() => {
                    onRoleClick?.(role)
                }}
            >
                {role.name}
            </div>
        )
    }

    const roleViews = roles.map((role) => {
        return <RoleView key={role.id} role={role} />
    })

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.title}>Standard Roles</div>
            </div>
            <div className={styles.roles}>{roleViews}</div>
        </div>
    )
}

export default AllRoles
