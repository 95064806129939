import React from "react"
import styles from "./ValueStreamSelector.module.css"
import RoleView from "./Views/RoleView"
import { ValueStream } from "../Model/types"

interface ValueStreamSelectorProps {
    valueStreams: ValueStream[]
    selectedId: string
    onSelect: (valueStream: ValueStream) => void
}

const ValueStreamSelector: React.FC<ValueStreamSelectorProps> = ({ valueStreams, selectedId, onSelect }) => {
    const selections = valueStreams.map((vs) => {
        const isSelected = vs.id === selectedId
        let className = `${styles.selection}`
        if (isSelected) {
            className += ` ${styles.selected}`
        }

        return (
            <div
                key={vs.id}
                className={className}
                onClick={() => {
                    onSelect(vs)
                }}
            >
                {vs.name}
            </div>
        )
    })

    return <div className={styles.valueStreamSelector}>{selections}</div>
}

export default ValueStreamSelector
