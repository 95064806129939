import { CountSummary, DeviceCount, Product } from "./types"

export function makeCountSummary(deviceCountData: any[], productsData: any[]): CountSummary {
    let deviceCounts: DeviceCount[] = deviceCountData.map(data => {
        const id = data.id
        const uuid = data.fields["UUID"]
        const connectedCount = data.fields["Connected Count"]
        const totalCount = data.fields["Total Count"]
        const productKey = data.fields["Product Key"]
        const timestamp = Math.floor(data.fields["Timestamp"] / 10000) * 10000

        const deviceCount:DeviceCount = {
            id, uuid, connectedCount, totalCount, productKey, timestamp
        } 

        return deviceCount
    })

    // temporarily adjust sensitech until we get accurate data
    // for(const deviceCount of deviceCounts) {
    //     if (deviceCount.productKey === "sensitech") {
    //         deviceCount.connectedCount = 390000
    //         deviceCount.totalCount = 390000
    //     }
    // }

    // Ensure there are at least 2 records for each product key
    deviceCounts = ensureMinimumRecords(deviceCounts)

    const products: Product[] = productsData.map(productData => {
        const name = productData.fields["Name"]
        const key = productData.fields["Key"]
        const historicalCounts = getHistoricalCounts(key, deviceCounts)
        const latestCount = getLatestCount(historicalCounts)
        const hasDeviceData = productData.fields["Has Device Data"]
        const conformsToConnectedDefinition = productData.fields["Conforms to Connected Definition"]

        const hasSimpleLocation = productData.fields["Has Simple Location"]
        const hasExactLocation = productData.fields["Has Exact Location"]
        const errorCount = productData.fields["Error Count"]
        const alarmCount = productData.fields["Alarm Count"]
        const category = productData.fields["Category"]
        const description = productData.fields["Description"]
        const urlLink = productData.fields["URL Link"]

        return { name, key, latestCount, historicalCounts, hasDeviceData, conformsToConnectedDefinition, hasExactLocation, hasSimpleLocation, errorCount, alarmCount, category, description, urlLink }

    })

    products.sort((a, b) => {
        if (a.category === b.category) {
            // Both products are in the same category
            const aConnectedCount = a.latestCount?.connectedCount ?? 0;
            const bConnectedCount = b.latestCount?.connectedCount ?? 0;
            return bConnectedCount - aConnectedCount; // Sort in descending order
        } else {
            // Different categories
            return b.category.localeCompare(a.category);
        }
    });
    


    const total = getTotalCount(products)

    const countSummary: CountSummary = {
        totalConnected: total, products
    }

    return countSummary
}

function ensureMinimumRecords(deviceCounts: DeviceCount[]): DeviceCount[] {
    const productKeyCounts: { [key: string]: DeviceCount[] } = {}

    // Group device counts by product key
    deviceCounts.forEach(dc => {
        if (!productKeyCounts[dc.productKey]) {
            productKeyCounts[dc.productKey] = []
        }
        productKeyCounts[dc.productKey].push(dc)
    })

    // Create a new array to hold the final device counts
    const finalDeviceCounts: DeviceCount[] = []

    // Process each group of device counts
    Object.keys(productKeyCounts).forEach(productKey => {
        const counts = productKeyCounts[productKey]
        finalDeviceCounts.push(...counts)
        if (counts.length === 1) {
            const singleCount = counts[0]
            const duplicateCount = { ...singleCount, timestamp: singleCount.timestamp - 6 * 60 * 60 * 1000 } // Move timestamp back 24 hours
            finalDeviceCounts.push(duplicateCount)
        }
    })

    return finalDeviceCounts
}

function getUniqueProductKeys(deviceCounts: DeviceCount[]): string[] {
    const uniqueKeys = new Set(deviceCounts.map(deviceCount => deviceCount.productKey))
    return Array.from(uniqueKeys)
}

function getHistoricalCounts(productKey: string, deviceCounts: DeviceCount[]): DeviceCount[] {
    return deviceCounts.filter(deviceCount => deviceCount.productKey === productKey)
}

function getLatestCount(deviceCounts: DeviceCount[]): DeviceCount | undefined {
    if (deviceCounts.length === 0) {
        return undefined
    }
    return deviceCounts.reduce((latest, current) => {
        return (latest.timestamp > current.timestamp) ? latest : current;
    })
}


function getTotalCount(products: Product[]): number {
    return products.reduce((total, product) => {
        const latestCount = product.latestCount?.connectedCount ?? 0
        return total + latestCount
    }, 0)
}