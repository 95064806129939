import { ReactNode, useState, useEffect, useRef } from "react"
import { ReactComponent as ModalCloseSvg } from "../Assets/modalClose.svg"
import styles from "./Modal.module.css"
import { motion } from "framer-motion"

type ModalProps = {
    children: ReactNode
    onClose: () => void
}

function Modal(props: ModalProps) {
    const contentRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (contentRef.current !== null) {
            contentRef.current.scrollTop = 0
        }
    }, [props.children])

    return (
        <div className={styles.container}>
            <motion.div
                className={styles.content}
                ref={contentRef}
                initial={{ opacity: 0, translateY: 1000 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
            >
                <div className={styles.closeButton} onClick={props.onClose}>
                    <ModalCloseSvg className={styles.closeSvg} />
                </div>
                {props.children}
            </motion.div>
            <motion.div
                className={styles.bg}
                onClick={props.onClose}
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.8 }}
                transition={{ duration: 0.2, ease: "easeOut" }}
            ></motion.div>
        </div>
    )
}

export default Modal
