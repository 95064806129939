import { TimelineChunk } from "../foundryCalendarSlice"
import TimelineChunkView from "./TimelineChunkView"
import styles from "./TimelineStack.module.css"

interface TimelineStackProps {
    timelineChunks: TimelineChunk[]
}

const TimelineStack: React.FC<TimelineStackProps> = ({ timelineChunks }) => {
    const chunkViews = timelineChunks.map((chunk) => {
        return <TimelineChunkView key={chunk.id} timelineChunk={chunk} />
    })

    return <div className={styles.container}>{chunkViews}</div>
}

export default TimelineStack
