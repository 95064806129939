import { Resource } from "../Model/types"
import styles from "./ResourceDetails.module.css"
import SlackIcon from "../Assets/slackIcon.png"
import EmailIcon from "../Assets/emailIcon.png"
import LocalTime from "./LocalTime"
import ResourceView from "./ResourceView"
import Store from "../Model/Store"
import getTimeElapsed from "../Utils/getTimeElapsed"
import { Email } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { selectResources } from "../Model/slices/resourcesSlice"

type ResourceDetailsProps = {
    resource: Resource
    onSelectResource: (id: string) => void
}

function ResourceDetails({ resource, onSelectResource }: ResourceDetailsProps): JSX.Element {
    const resources = useSelector(selectResources)

    const manager = resources.find((r) => r.id === resource.managerId)
    const managerView = manager ? (
        <ResourceView
            resource={manager}
            onClick={() => {
                onSelectResource(manager.id)
            }}
        />
    ) : (
        <div>--</div>
    )

    const directReports = resource.directReportIds?.map((id) => {
        return resources.find((r) => r.id === id)
    })
    const directReportViews =
        directReports && directReports.length > 0 ? (
            directReports.map((resource) => {
                return (
                    <ResourceView
                        resource={resource!}
                        onClick={() => {
                            onSelectResource(resource!.id)
                        }}
                        key={resource?.id}
                    />
                )
            })
        ) : (
            <div>--</div>
        )

    const teamId = "T014J7H9F0D" // replace with your team ID
    const userId = resource?.slackUser?.id // replace with the user ID
    const tz = resource?.slackUser?.tz ?? ""
    const valueStreams = resource.valueStreams && resource.valueStreams.length > 0 ? resource.valueStreams.join(", ") : "--"
    const areaPaths = resource.areaPaths && resource.areaPaths.length > 0 ? resource.areaPaths.join(", ") : "--"
    const pods = resource.pods && resource.pods.length > 0 ? resource.pods.join(", ") : "--"
    let tenure = getTimeElapsed(resource.startDate) ?? "--"

    return (
        <div className={styles.resourceDetails}>
            <div className={styles.profileOverview}>
                <div className={styles.profileDetails}>
                    <div className={styles.profileNameAndTitle}>
                        <div className={styles.name}>{resource.name}</div>
                        <div className={styles.title}>{resource.title}</div>
                        <div className={styles.slackLink}>
                            <img className={styles.slackIcon} src={SlackIcon} alt={SlackIcon}></img>
                            <a href={`slack://user?team=${teamId}&id=${userId}`}>@{resource.slackUser?.name} </a>
                            <LocalTime tz={tz} />
                        </div>
                        <div className={styles.slackLink}>
                            <Email style={{ color: "dodgerblue", maxWidth: "18px" }} />
                            <a href={`mailto:${resource.email}`}>{resource.email} </a>
                        </div>
                    </div>

                    <table className={styles.profileDetailsTable}>
                        <tbody>
                            <tr className={styles.tableRow}>
                                <td className={styles.propertyLabel}>Value Streams:</td>
                                <td className={styles.propertyValue}>{valueStreams}</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <td className={styles.propertyLabel}>Area Paths:</td>
                                <td className={styles.propertyValue}>{areaPaths}</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <td className={styles.propertyLabel}>Discipline:</td>
                                <td className={styles.propertyValue}>
                                    {resource.discipline} {resource.subDiscipline}
                                </td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <td className={styles.propertyLabel}>Tenure:</td>
                                <td className={styles.propertyValue}>{tenure}</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <td className={styles.propertyLabel}>Category:</td>
                                <td className={styles.propertyValue}>{resource.category}</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <td className={styles.propertyLabel}>Employer:</td>
                                <td className={styles.propertyValue}>{resource.employer}</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <td className={styles.propertyLabel}>Location:</td>
                                <td className={styles.propertyValue}>{resource.location}</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <td className={styles.propertyLabel}>Pods:</td>
                                <td className={styles.propertyValue}>{pods}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className={styles.profileOrgHierarchy}>
                <div>
                    <div className={styles.profileOrgHierarchyTitle}>Manager:</div>
                    <div>{managerView}</div>
                </div>
                <div>
                    <div className={styles.profileOrgHierarchyTitle}>Direct Reports:</div>
                    <div className={styles.profileDirectReports}>{directReportViews}</div>
                </div>
            </div>
        </div>
    )
}

export default ResourceDetails
