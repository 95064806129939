import React, { CSSProperties } from "react"
import styles from "./PodCard.module.css"
import RoleView from "./Views/RoleView"
import { Pod, Role } from "../Model/types"
import { PodColorsContext } from "./SimplifiedValueStreams"

interface PodCardProps {
    pod: Pod
    onRoleClick: (role: Role) => void
}

const PodCard: React.FC<PodCardProps> = ({ pod, onRoleClick }) => {
    const podColors = React.useContext(PodColorsContext)

    const support = pod.support.map((role) => {
        return <RoleView role={role} key={"support-" + role.id} onClick={onRoleClick} />
    })

    const devs = pod.developers.map((role) => {
        return <RoleView role={role} key={"dev-" + role.id} onClick={onRoleClick} />
    })

    const color = podColors?.[pod.id] ?? "white"
    const style: CSSProperties = { backgroundColor: color }

    return (
        <div className={styles.podCard}>
            <div className={styles.line} style={style}></div>
            <div className={styles.content}>
                <div className={styles.name}>{pod.name}</div>
                <div className={styles.roles}>
                    <div className={styles.support}>{support}</div>
                    <div className={styles.devs}>{devs}</div>
                </div>
            </div>
        </div>
    )
}

export default PodCard
