import { createSlice } from "@reduxjs/toolkit"
import { Resource, RootState } from "../types"

export interface ResourcesState {
    resources: Resource[]
}

const initialState: ResourcesState = {
    resources: [],
}

export const selectResources = (state: RootState) => state.resources.resources

const resourcesSlice = createSlice({
    name: "resources",
    initialState,
    reducers: {
        setResources: (state, action) => {
            state.resources = action.payload
        },
    },
})

export const { setResources } = resourcesSlice.actions
export const resourcesReducer = resourcesSlice.reducer
