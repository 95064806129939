const getTimeElapsed = (dateString: string | undefined): string | undefined => {
    if (!dateString) {
        return undefined
    }
    const inputDate = new Date(dateString)
    const currentDate = new Date()

    // calculate total number of months
    const months = (currentDate.getFullYear() - inputDate.getFullYear()) * 12
    const totalMonths = months - inputDate.getMonth() + currentDate.getMonth()
    const years = Math.floor(totalMonths / 12)
    const remainingMonths = totalMonths % 12

    // calculate weeks and days
    const weeks = Math.floor((currentDate.getTime() - inputDate.getTime()) / (1000 * 60 * 60 * 24 * 7))
    const days = Math.floor((currentDate.getTime() - inputDate.getTime()) / (1000 * 60 * 60 * 24))

    if (years > 0) {
        // check for plural or singular
        const yearString = years > 1 ? "years" : "year"
        const monthString = remainingMonths > 1 ? "months" : "month"

        return `${years} ${yearString}, ${remainingMonths} ${monthString}`
    } else if (remainingMonths > 0) {
        const monthString = remainingMonths > 1 ? "months" : "month"

        return `${remainingMonths} ${monthString}`
    } else if (weeks > 0) {
        const weekString = weeks > 1 ? "weeks" : "week"

        return `${weeks} ${weekString}`
    } else {
        const dayString = days > 1 ? "days" : "day"

        return `${days} ${dayString}`
    }
}

export default getTimeElapsed
