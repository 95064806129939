import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../Model/types"
import { fetchFoundryCalendarData, fetchJiraEpicsData, fetchJiraStoriesData, fetchProductEpicsData } from "../../Model/helpers"
import { isDateInRange } from "../ProductIncrement/utils"
import Color from "color"
import dayjs from "dayjs"

export interface ProjetCompositionState {
    productEpicsData: any[]
    jiraEpicsData: any[] 
    calendarData: any[]
    jiraStoriesData: any[]
    filters: string[]
    currentFilter: string
    loading: string
}

export interface ProjectCompositionItem {
    id: string
    type: string
    name: string
    value: number
    storyPoints: number
    description: string
    children: ProjectCompositionItem[]
    itemStyle?: {
        color?: string
    },
    tShirtSize?: string
    issueKey?: string
}

const initialState: ProjetCompositionState = {
    productEpicsData: [],
    jiraEpicsData: [],
    calendarData: [],
    jiraStoriesData: [],
    filters: [],
    currentFilter: "",
    loading: "idle",
}

export const selectLoading = (state: RootState) => state.projectComposition.loading
export const selectItems = (state: RootState) => {
    const {productEpicsData,jiraEpicsData,calendarData,jiraStoriesData} = state.projectComposition

    // const piItem: any = calendarData.filter((item) => {
    //     return item.fields["Current"] === "Current PI"
    // })[0]
    let piItem: any = calendarData.find((item) => {
        return item.fields["Name"] === state.projectComposition.currentFilter
    })

    // fallback to the current PI
    if (piItem === undefined) {
        piItem = calendarData.filter((item) => {
            return item.fields["Current"] === "Current PI"
        })[0]
    }

    const projects = ["carrier.io", "AHP", "Abound"]

    const products: ProjectCompositionItem[] = projects.map((projectName) => {
        const productEpics = productEpicsData
            .filter((productEpicItem) => {
                const projectField = productEpicItem.fields["Project"] ?? ""
                const completionDate = productEpicItem.fields["Committed Completion Date"] ?? ""
                const isInPi = isDateInRange(completionDate, piItem.fields.Start, piItem.fields.End)

                return  projectField === projectName && isInPi
            })
            .map((productEpicItem) => {

                const jiraEpics = jiraEpicsData
                .filter((jiraEpicItem) => {
                    const parentIssueKey = jiraEpicItem.fields["Parent"]
                    const completionDate = jiraEpicItem.fields["Committed Completion Date"] ?? ""
                    const isInPi = isDateInRange(completionDate, piItem.fields.Start, piItem.fields.End)

                    return parentIssueKey === productEpicItem.fields["Issue Key"] && isInPi
                })
                .map(jiraEpicItem => {

                    const jiraStories = jiraStoriesData.filter(jiraStoriesItem => {
                        const parentIssueKey = jiraStoriesItem.fields["Parent"] ?? ""
                        
                        return parentIssueKey === jiraEpicItem.fields["Issue Key"]
                    }).map(jiraStoriesItem => {
                        let storyPoints = parseFloat(jiraStoriesItem.fields["Story Points"])
                        if (isNaN(storyPoints)) {
                            storyPoints = 0
                        }

                        let value = Math.max(storyPoints, 1)
                        const color = storyPoints > 0 ? undefined : "#D7BD38"
                        return {
                            id: jiraStoriesItem.id,
                            type: "Jira Story",
                            name: jiraStoriesItem.fields["Issue Key"],
                            value: value,
                            storyPoints: storyPoints,
                            description: jiraStoriesItem.fields["Summary"],
                            children: [],
                            itemStyle: {
                                color: color
                              },
                            issueKey: jiraStoriesItem.fields["Issue Key"]
                        }
                    })

                    let totalValue = jiraStories.reduce((acc, child) => acc + (child.value), 0)
                    totalValue = Math.max(totalValue, 1)
                    let totalStoryPoints = jiraStories.reduce((acc, child) => acc + (child.storyPoints), 0)
                    const tShirtSize = jiraEpicItem.fields["T-Shirt Size"]
                    const color = getColor(tShirtSize)
                    return {
                        id: jiraEpicItem.id,
                        type: "Jira Epic",
                        name: jiraEpicItem.fields["Issue Key"],
                        value: totalValue,
                        storyPoints: totalStoryPoints,
                        description: jiraEpicItem.fields["Summary"],
                        children: jiraStories,
                        itemStyle: {
                            color: color
                          },
                        tShirtSize: tShirtSize,
                        issueKey: jiraEpicItem.fields["Issue Key"]
                    }
                })

                let totalValue = jiraEpics.reduce((acc, child) => acc + (child.value), 0)
                totalValue = Math.max(totalValue, 1)
                let totalStoryPoints = jiraEpics.reduce((acc, child) => acc + (child.storyPoints), 0)
                const tShirtSize = productEpicItem.fields["T-Shirt Size"]
                const color = getColor(tShirtSize)
                return {
                    id: productEpicItem.id,
                    type: "Product Epic",
                    name: productEpicItem.fields["Issue Key"],
                    value: totalValue,
                    storyPoints: totalStoryPoints,
                    description: productEpicItem.fields["Summary"],
                    children: jiraEpics,
                    itemStyle: {
                        color: color
                    },
                    tShirtSize: tShirtSize,
                    issueKey: productEpicItem.fields["Issue Key"]
                }
            })

        let totalValue = productEpics.reduce((acc, child) => acc + (child.value), 0)
        totalValue = Math.max(totalValue, 1)
        let totalStoryPoints = productEpics.reduce((acc, child) => acc + (child.storyPoints), 0)
        return {
            id: "id-" + projectName,
            type: "Project",
            name: projectName,
            value: totalValue,
            storyPoints: totalStoryPoints,
            description: "",
            children: productEpics,
        }
    })

    return products
} 
export const selectFilters = (state: RootState) => state.projectComposition.filters
export const selectCurrentFilter = (state: RootState) => state.projectComposition.currentFilter

export const fetchProjectComposition = createAsyncThunk("metrics/productComposition", async (args, thunkAPI) => {
    const dispatch = thunkAPI.dispatch

    //  load the neccesary airtable data
    const productEpicsData: any[] = await fetchProductEpicsData(dispatch)
    const jiraEpicsData: any[] = await fetchJiraEpicsData(dispatch)
    const calendarData: any[] = await fetchFoundryCalendarData(dispatch)
    const jiraStoriesData: any[] = await fetchJiraStoriesData(dispatch)
    console.log("🚀 ~ fetchProjectComposition ~ jiraStoriesData:", jiraStoriesData)

    const piItems = calendarData.filter((item) => {
        const isFuture = isFutureDate(item.fields.End)
        return item.fields.Type === "PI" && isFuture
    })
    const filters = piItems.map(item => {
        return item.fields.Name
    })
    filters.sort()

    const currentFilter = filters[0]

    return {productEpicsData, jiraEpicsData, calendarData, jiraStoriesData, filters, currentFilter}
    
})

const projectCompositionSlice = createSlice({
    name: "productIncrement",
    initialState,
    reducers: {
        setCurrentFilter: (state, action) => {
            state.currentFilter = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectComposition.pending, (state) => {
                state.loading = "loading"
            })
            .addCase(fetchProjectComposition.fulfilled, (state, action) => {
                state.productEpicsData = action.payload.productEpicsData
                state.jiraEpicsData = action.payload.jiraEpicsData
                state.calendarData = action.payload.calendarData
                state.jiraStoriesData = action.payload.jiraStoriesData
                state.filters = action.payload.filters
                state.currentFilter = action.payload.currentFilter
                state.loading = "loaded"
            })
            .addCase(fetchProjectComposition.rejected, (state) => {
                state.loading = "failed"
            })
    },
})

// Function to check if a given date string is in the future
function isFutureDate(dateStr: string): boolean {
    // Parse the date string using dayjs
    const date = dayjs(dateStr);
  
    // Compare the parsed date with the current date
    return date.isAfter(dayjs());
  }

/////////////////////////
// color generation
/////////////////////////

export interface TShirtColor {
    name: string,
    key: string,
    color: string,
}
const gradient = generateGradient("#530070", "#FF008A", 5)
export const tShirtColors: TShirtColor[] = [
    {
        name: "XS", 
        key: "XS-  ≤1 Sprint ",
        color: gradient[0],
    },
    {
        name: "S", 
        key: "Small- 2 Sprints",
        color: gradient[1],
    },
    {
        name: "M", 
        key: "Medium- 3 Sprints",
        color: gradient[2],
    },
    {
        name: "L", 
        key: "Large- 4 Sprints ",
        color: gradient[3],
    },
    {
        name: "XL", 
        key: "X-large- 5+ Sprints",
        color: gradient[4],
    },
]

function generateGradient(colorStart: string, colorEnd: string, stepCount: number): string[] {
    let gradient: string[] = [];
    const start = Color(colorStart);
    const end = Color(colorEnd);
  
    for (let step = 0; step < stepCount; step++) {
      // Calculate the ratio for the current step
      const ratio = step / (stepCount - 1);
      // Interpolate between the start and end colors by the ratio
      const gradientColor = start.mix(end, ratio).rgb().string();
      // Add the interpolated color to the gradient array
      gradient.push(gradientColor);
    }
  
    return gradient;
  }

function getColor(tSize: string): string | undefined {
    const size = tSize
    if (size === undefined) {
        return undefined
    }

    const color = tShirtColors.find(color => {
        return color.key === tSize
    })?.color

    return color;
}

export const {setCurrentFilter} = projectCompositionSlice.actions
export default projectCompositionSlice.reducer
