import React, { CSSProperties, ReactNode, useEffect, useState } from "react"

interface CursorFollowerProps {
    children?: ReactNode
}

const CursorFollower: React.FC<CursorFollowerProps> = ({ children }) => {
    const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })

    const handleMouseMove = (event: MouseEvent) => {
        setPosition({ x: event.clientX, y: event.clientY })
    }

    useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove)

        return () => {
            window.removeEventListener("mousemove", handleMouseMove)
        }
    }, [])

    const followerStyle: CSSProperties = {
        position: "fixed", // Position fixed to move with the cursor
        top: `${position.y}px`, // Y-coordinate
        left: `${position.x}px`, // X-coordinate
        pointerEvents: "none",
        transform: "translate(-50%, -150%)", // Center the content
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "4px",
        fontSize: "14px",
        fontWeight: "bold",
        zIndex: 1000,
    }

    // Render the children within the positioned element
    return <div style={followerStyle}>{children}</div>
}

export default CursorFollower
