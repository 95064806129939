import styles from "./SideBar.module.css"
import CloseIcon from "@mui/icons-material/Close"
import ArrowBack from "@mui/icons-material/ArrowBackIosNew"
import { useState } from "react"

interface SideBarProps {
    visible: boolean
    children?: React.ReactNode[]
    onClose?: () => void
    onBack?: () => void
    showBackButton?: boolean
}

const SideBar: React.FC<SideBarProps> = ({ visible, children, onClose, onBack, showBackButton }) => {
    const [page, setPage] = useState(2)

    let childHolderClass = `${styles.childHolder}`
    if (children) {
        if (children.length > 1) {
            childHolderClass += ` ${styles.page2}`
        }
    }

    return (
        <div className={`${styles.container} ${visible ? styles.visible : ""}`}>
            {showBackButton && (
                <div
                    className={styles.backButton}
                    onClick={() => {
                        setPage(1)
                        if (onBack) onBack()
                    }}
                >
                    <ArrowBack sx={{ width: "30px", height: "24px" }} />
                </div>
            )}

            <div
                className={styles.closeButton}
                onClick={() => {
                    if (onClose) onClose()
                }}
            >
                <CloseIcon sx={{ width: "30px", height: "30px" }} />
            </div>
            <div className={childHolderClass}>{children}</div>
        </div>
    )
}

export default SideBar
