import { createNodeView } from "../OrgChart/NodeView"
import { Role, Pod, AreaPath, Platform, ValueStream, Resource, SlackUser, Service } from "./types"
import sortByField from "../Utils/sortByField"
import OktaAuth from "@okta/okta-auth-js"
import AirtableFilters from "./AirtableFilters"
import { serverUrl } from "./serverConfig"
import { setLoadingMessage } from "../Components/LoadingStatus/LoadingStatus"
import reduxStore from "./ReduxStore"
import { setResources } from "./slices/resourcesSlice"
import { AirtableConfig } from "./AirtableConfig"

enum RoleField {}

enum ResourceField {
    Name = "Name",
}

export default class Store {
    teamData: any[] = []
    orgData: any[] = []
    slackUsers: SlackUser[] = []
    roles: Role[] = []
    foundryCalendarData: any[] = []
    jiraOverviewData: any[] = []
    productEpicsData: any[] = []
    jiraEpicsData: any[] = []
    disciplinesData: any[] = []
    subDisciplinesData: any[] = []
    employerData: any[] = []
    dataDidRefresh: (() => void) | undefined = undefined

    public async loadData(oktaAuth: OktaAuth) {
        reduxStore.dispatch(setLoadingMessage("Resources"))
        const team = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.foundryOrgResourcesTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("Roles"))
        const rolesData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.foundryOrgRolesTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("Pods"))
        const podsData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.podsTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("Area Paths"))
        const areaPathsData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.areaPathsTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("Value Streams"))
        const valueStreamsData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.valueStreamsTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("Platforms"))
        const platformsData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.platformsTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("Domains"))
        const domainsData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.domainsTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("Groups"))
        const groupsData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.groupsTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("Developer Groups"))
        const devGroupsData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.developerGroupsTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("io Services"))
        const ioServicesData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.ioServicesTableId, oktaAuth)

        this.orgData = [...rolesData, ...podsData, ...areaPathsData, ...valueStreamsData, ...platformsData, ...domainsData, ...groupsData, ...devGroupsData, ...ioServicesData]

        reduxStore.dispatch(setLoadingMessage("Disciplines"))
        this.disciplinesData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.disciplinesTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("Subdisciplines"))
        this.subDisciplinesData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.subDisciplinesTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("Employers"))
        this.employerData = await this.fetchAirtableData(AirtableConfig.foundryOrgBaseId, AirtableConfig.employersTableId, oktaAuth)

        reduxStore.dispatch(setLoadingMessage("Slack Profiles"))
        try {
            this.slackUsers = await this.fetchSlackUsers(oktaAuth)
        } catch (error) {
            // Handle the error here
            console.error("Error fetching Slack users:", error)
        }

        const activeTeam = team.filter((item: any) => {
            return item.fields.Status === "Active"
        })
        this.teamData = activeTeam

        // this.logSlackUsers()

        reduxStore.dispatch(setResources(this.getAllResources()))

        this.dataDidRefresh?.()
    }

    private async fetchAirtableData(baseId: string, tableId: string, oktaAuth: OktaAuth) {
        try {
            const accessToken = await oktaAuth.getIdToken()
            // Replace with your server's base URL if it's different
            const response = await fetch(`${serverUrl}/airtable-data/${baseId}/${tableId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            const data = await response.json()
            return data
        } catch (error) {
            console.error("Error fetching data from server:", error)
            return []
        }
    }

    async fetchSlackUsers(oktaAuth: OktaAuth) {
        const accessToken = await oktaAuth.getIdToken()

        const response = await fetch(`${serverUrl}/slack-users`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })

        if (!response.ok) {
            throw new Error("Error fetching Slack users")
        }

        const data = await response.json()
        return data
    }

    logSlackUsers() {
        const activeTeam = this.teamData.filter((item: any) => {
            return item.fields.Status === "Active"
        })
        let missing = 0
        for (const data of activeTeam) {
            const dataName = data.fields["Name"]

            if (dataName) {
                const foundUser = this.getSlackUserForEmail(data.fields["Carrier Email"])
                if (foundUser) {
                    console.log(`${dataName}: `, foundUser.name)
                } else {
                    console.log("!!!!!!!!!!!! could not find user:", dataName)
                    missing++
                }
            }
        }
        console.log("missing slack profiles", missing)
    }

    public getSlackUserForEmail(email: string | undefined): SlackUser | undefined {
        let user: SlackUser | undefined
        if (email) {
            const userEmail = email.toLowerCase()

            user = this.slackUsers.find((user) => {
                const slackEmail = user.profile?.email?.toLowerCase()
                if (user.profile && slackEmail) {
                    return slackEmail === userEmail
                }
                return false
            })
        }
        return user
    }

    public getSlackUserForResourceId(id: string): SlackUser | undefined {
        const resource = this.getResourceById(id)
        return this.getSlackUserForEmail(resource?.email)
    }

    private getTeamD3DataRecursive(item: any): any[] {
        const data: any[] = []

        const nodeView = createNodeView(item, this)
        const parentIds = item.fields["Manager"]
        const parentId = parentIds ? parentIds[0] : undefined
        data.push({
            id: item.id,
            fields: item.fields,
            parentId: parentId,
            view: nodeView,
        })

        // find children
        const childItems = this.teamData.filter(AirtableFilters.resources.children(item))

        // add data from children
        if (childItems.length > 0) {
            childItems.forEach((childItem) => {
                data.push(...this.getTeamD3DataRecursive(childItem))
            })
        }

        return data
    }

    public getTeamD3Data(): any[] {
        const activeTeam = this.teamData.filter((item: any) => {
            return item.fields.Status === "Active"
        })

        const rootData = activeTeam.find(AirtableFilters.resources.rootData)
        return this.getTeamD3DataRecursive(rootData)
    }

    public getNameById(id: string): string | undefined {
        const item = this.teamData.find((item) => {
            return item.id === id
        })
        if (item) {
            return item.fields["Name"]
        } else {
            return undefined
        }
    }

    private getOrgD3DataRecursive(item: any, parentId: string | undefined): any[] {
        const data: any[] = []

        data.push({
            id: item.id,
            fields: item.fields,
            parentId: parentId,
            view: createNodeView(item, this),
        })

        // find children
        const childItems = AirtableFilters.roles.children(item, this.orgData)

        // add data from children
        if (childItems.length > 0) {
            childItems.forEach((childItem) => {
                data.push(...this.getOrgD3DataRecursive(childItem, item.id))
            })
        }

        return data
    }

    public getOrgD3Data(): any[] {
        const rootData = this.orgData.find(AirtableFilters.roles.rootData)
        const recursiveData = this.getOrgD3DataRecursive(rootData, undefined)
        return recursiveData
    }

    getResourceById(id: string): Resource | undefined {
        const resourceItem = this.teamData.find((item) => {
            return item.id === id
        })

        if (resourceItem === undefined) {
            return undefined
        }

        const directReports = this.teamData.filter(AirtableFilters.resources.directReports(id))
        const directReportIds = directReports.map((item) => {
            return item.id
        })

        const rolesArr = resourceItem.fields.Roles ?? []
        const roleData = rolesArr.map((roleId: any) => {
            return this.orgData.find((item) => {
                return item.id === roleId
            })
        })

        const valueStreams: string[] = roleData
            .map((item: any) => {
                return item?.fields["Value Stream"] ?? ""
            })
            .filter((stream: string) => stream && stream.trim() !== "")

        const areaPaths: string[] = roleData
            .map((item: any) => {
                return item?.fields["Area Path"] ?? ""
            })
            .filter((stream: string) => stream && stream.trim() !== "")

        const discipline = this.disciplinesData.find((item) => {
            const arr = resourceItem.fields.Discipline ?? ["0"]
            return item.fields.Primary === arr[0]
        })

        const subDiscipline = this.subDisciplinesData.find((item) => {
            const arr = resourceItem.fields.Subdiscipline ?? ["0"]
            return item.fields.Primary === arr[0]
        })

        const employer = this.employerData.find((item) => {
            const arr = resourceItem.fields["Employers"] ?? ["0"]
            return item.id === arr[0]
        })
        const employerName = employer?.fields.Name ?? ""

        const pods = resourceItem.fields["Pod (from Roles)"]

        if (resourceItem) {
            return {
                id: resourceItem.id,
                name: resourceItem.fields["Name"],
                title: resourceItem.fields["Title"],
                discipline: discipline?.fields.Name,
                subDiscipline: subDiscipline?.fields.Name,
                managerId: resourceItem.fields["Manager"]?.[0] ?? undefined,
                category: resourceItem.fields["Category"],
                employer: employerName,
                location: resourceItem.fields["Country"],
                startDate: resourceItem.fields["Start Date"],
                pods: pods,
                email: resourceItem.fields["Carrier Email"],
                slackUser: this.getSlackUserForEmail(resourceItem.fields["Carrier Email"]),
                directReportIds: directReportIds,
                valueStreams: valueStreams,
                areaPaths: areaPaths,
            }
        } else {
            return undefined
        }
    }

    getRoleForItem(item: any, breadcrumb?: string): Role {
        const resourceId = item.fields["Resources"]?.[0]
        // const interimResourceId = item.fields["Interim Resource"]?.[0]

        let resource: Resource | undefined = undefined
        // let interimResource: Resource | undefined = undefined

        if (resourceId) {
            resource = this.getResourceById(resourceId)
        }

        // if (interimResourceId) {
        //     interimResource = this.getResourceById(interimResourceId)
        // }

        const role: Role = {
            roleName: item.fields["Name"],
            id: item.id,
            resource: resource,
            interimResource: undefined,
            breadcrumb: breadcrumb,
        }
        // save roles for access later
        if (!this.roles.some((item) => item.id === role.id)) {
            this.roles.push(role)
        }
        return role
    }

    getRoleById(id: string) {
        return this.roles.find((role) => role.id === id)!
    }

    getSupportForItem(parentItem: any, breadcrumb?: string): Role[] {
        const support = this.orgData
            .filter(AirtableFilters.roles.support(parentItem))
            .map((supportItem) => {
                return this.getRoleForItem(supportItem, breadcrumb)
            })
            .sort(sortByField<Role>("roleName"))
        return support
    }

    getPodsForItem(parentItem: any, breadcrumb?: string): Pod[] {
        return this.orgData.filter(AirtableFilters.roles.pods(parentItem)).map((podItem) => {
            const podSupport = this.getSupportForItem(podItem, breadcrumb)
            const podDevs = this.getDevsForPodItem(podItem, breadcrumb)
            return {
                id: podItem.id,
                name: podItem.fields["Name"],
                developers: podDevs,
                support: podSupport,
            } as Pod
        })
    }

    getDevsForPodItem(parentPodItem: any, breadcrumb?: string): Role[] {
        const devPodItems = this.orgData.filter(AirtableFilters.roles.devPods(parentPodItem))

        if (devPodItems.length === 0) {
            return []
        }
        const devPodItem = devPodItems[0]

        return this.orgData
            .filter(AirtableFilters.roles.devsForDevPod(devPodItem))
            .map((devItem) => {
                return this.getRoleForItem(devItem, breadcrumb)
            })
            .sort(sortByField<Role>("roleName"))
    }

    getServicesForAreaPathItem(areaPathItem: any): Service[] {
        return this.orgData.filter(AirtableFilters.roles.services(areaPathItem)).map((serviceItem) => {
            return {
                id: serviceItem.id,
                name: serviceItem.fields["Name"],
                description: serviceItem.fields["Description"] ?? "Description coming soon.",
                jiraKey: serviceItem.fields["Jira Issue Key"],
            } as Service
        })
    }

    getAreaPathsForValueStreamItem(vsItem: any, breadcrumb?: string): AreaPath[] {
        const areaPaths: AreaPath[] = this.orgData.filter(AirtableFilters.roles.areaPaths(vsItem)).map((areaPathItem) => {
            const areaPathBreadcrumb = `${breadcrumb} > ${areaPathItem.fields["Name"]}`
            const support = this.getSupportForItem(areaPathItem, areaPathBreadcrumb)
            const pods = this.getPodsForItem(areaPathItem, areaPathBreadcrumb)
            const services = this.getServicesForAreaPathItem(areaPathItem)
            const description = areaPathItem.fields["Notes"]
            return {
                id: areaPathItem.id,
                name: areaPathItem.fields["Name"],
                pods: pods,
                support: support,
                services: services,
                description,
            } as AreaPath
        })
        return areaPaths
    }

    // used by the ValueStreams component
    public getPlatforms(): Platform[] {
        const platformItems = this.orgData.filter(AirtableFilters.roles.platforms)

        const returnItems = platformItems.map((platformItem) => {
            const platformName = platformItem.fields["Name"]

            const platformId = platformItem.id
            const platformBreadcrumb = `${platformName}`

            const valueStreamIds = platformItem.fields["Value Streams - Child"]
            const valueStreamItems = valueStreamIds.map((vsId: any) => {
                return this.orgData.find((item) => {
                    return item.id === vsId
                })
            })

            // const valueStreamItems = this.orgData.filter(
            //     AirtableFilters.roles.valueStreams(platformItem)
            // )

            const valueStreams: ValueStream[] = valueStreamItems.map((vsItem: any) => {
                const valueStreamName = vsItem.fields["Name"]
                const valueStreamId = vsItem.id
                const valueStreamBreadcrumb = `${platformBreadcrumb} > ${valueStreamName}`
                const support = this.getSupportForItem(vsItem, valueStreamBreadcrumb)
                const pods: Pod[] = this.orgData.filter(AirtableFilters.roles.pods(vsItem)).map((podItem) => {
                    const podSupport = this.getSupportForItem(podItem, valueStreamBreadcrumb)
                    const devs = this.getDevsForPodItem(podItem, valueStreamBreadcrumb)
                    return {
                        id: podItem.id,
                        name: podItem.fields["Name"],
                        support: podSupport,
                        developers: devs,
                    } as Pod
                })

                const areaPaths = this.getAreaPathsForValueStreamItem(vsItem, valueStreamBreadcrumb)
                const description = vsItem.fields["Description"]
                return {
                    id: valueStreamId,
                    name: valueStreamName,
                    support,
                    pods,
                    areaPaths,
                    description,
                } as ValueStream
            })

            // alphabetize and move Shared Services to bottom
            valueStreams.sort((a, b) => {
                if (a.name.startsWith("Shared Services") && !b.name.startsWith("Shared Services")) {
                    return 1 // move a to the end
                } else if (!a.name.startsWith("Shared Services") && b.name.startsWith("Shared Services")) {
                    return -1 // move b to the end
                } else {
                    return a.name.localeCompare(b.name) // sort alphabetically in ascending order
                }
            })

            const productSupport = this.getSupportForItem(platformItem, platformBreadcrumb)

            return {
                name: platformName,
                id: platformId,
                valueStreams,
                support: productSupport,
            } as Platform
        })

        return returnItems
    }

    // used by the ResourceFinder component
    public getAllResources(): (Resource | undefined)[] {
        return this.teamData.map((teamItem) => {
            return this.getResourceById(teamItem.id)
        })
    }

    public getRolesFromPlatform(platforms: Platform[], platformName: string): Role[] {
        const platform = platforms.find((p) => p.name === platformName)

        if (!platform) {
            throw new Error(`Platform with name ${platformName} not found`)
        }

        let roles: Role[] = [...platform.support]

        platform.valueStreams.forEach((valueStream) => {
            roles = [...roles, ...this.getRolesFromValueStream(valueStream)]
        })

        return roles
    }

    private getRolesFromValueStream(valueStream: ValueStream): Role[] {
        let roles: Role[] = [...valueStream.support]

        valueStream.areaPaths?.forEach((areaPath) => {
            roles = [...roles, ...this.getRolesFromAreaPath(areaPath)]
        })

        return roles
    }

    private getRolesFromAreaPath(areaPath: AreaPath): Role[] {
        let roles: Role[] = [...areaPath.support]

        areaPath.pods.forEach((pod) => {
            roles = [...roles, ...pod.support, ...pod.developers]
        })

        return roles
    }

    public rolesToCSV(roles: Role[]): string {
        const header = "Role Name,Resource Name,Resource Email"
        const data = roles
            .map((role) => {
                const roleName = role.roleName
                const resourceName = role.resource?.name || ""
                const resourceEmail = role.resource?.email || ""
                return `${roleName},${resourceName},${resourceEmail}`
            })
            .join("\n")
        return `${header}\n${data}`
    }
}
