import dayjs from "dayjs"
import { JiraOverviewModel, Sprint } from "../../Model/types"

export function formatShortDate(date: Date): string {
    const shortMonths: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    const day: number = date.getDate()
    const shortMonth: string = shortMonths[date.getMonth()]

    return `${shortMonth} ${day}`
}

export function addDaysToDate(inputDate: Date, index: number): Date {
    const newDate = new Date(inputDate)
    newDate.setDate(inputDate.getDate() + index)
    return newDate
}

export function daysBetween(startDate: Date, endDate: Date): number {
    // Convert both dates to midnight (start of the day) to ignore time portions
    startDate.setHours(0, 0, 0, 0)
    endDate.setHours(0, 0, 0, 0)

    const oneDayInMillis = 1000 * 60 * 60 * 24 // milliseconds in a day

    // Calculate the difference in milliseconds and then convert to days
    const diffInDays = (endDate.getTime() - startDate.getTime()) / oneDayInMillis

    // Return the difference in days + 1 to include both start and end dates
    return Math.round(diffInDays) + 1
}

export function daysSince(inputDate: Date): number {
    const today = new Date()

    // Make use of the previously defined function
    return daysBetween(inputDate, today)
}

export function countCompletedSprints(sprints: Sprint[]): number {
    const today = new Date()
    today.setHours(0, 0, 0, 0) // Set the time to midnight to just compare dates

    return sprints.filter((sprint) => {
        const endDate = new Date(sprint.end)
        return endDate < today
    }).length
}

export function formatDateToShortMonthDay(dateString: string): string {
    // Parse the date string into a Date object
    const date = new Date(dateString)

    // Array of short month names
    const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    // Get the short month name and the day from the Date object
    const shortMonth = shortMonths[date.getMonth()]
    const day = date.getDate()

    // Return the formatted string
    return `${shortMonth} ${day}`
}

export function isDateInRange(targetDateStr: string, startDateStr: string, endDateStr: string): boolean {
    // Check for empty strings or invalid dates
    if (!targetDateStr || !dayjs(targetDateStr).isValid() ||
        !startDateStr || !dayjs(startDateStr).isValid() ||
        !endDateStr || !dayjs(endDateStr).isValid()) {
      return false;
    }
  
    const targetDate = dayjs(targetDateStr).startOf('day');
    const startDate = dayjs(startDateStr).startOf('day');
    const endDate = dayjs(endDateStr).startOf('day');
  
    // Check if the target date is on or after the start date AND on or before the end date
    return !targetDate.isBefore(startDate) && !targetDate.isAfter(endDate);
    // return targetDate.isAfter(endDate);
  }

  export function isDateBeforeTarget(givenDateStr: string, targetDateStr: string ): boolean {
    // Check for empty strings or invalid dates
    if (!targetDateStr || !dayjs(targetDateStr).isValid() ||
        !givenDateStr || !dayjs(givenDateStr).isValid()) {
      return false;
    }
  
    const targetDate = dayjs(targetDateStr).startOf('day');
    const givenDate = dayjs(givenDateStr).startOf('day');
  
    // Check if the given date is before the target date
    return givenDate.isBefore(targetDate);
  }

 export function daysBetweenDates(date1: string, date2: string): number {
    // Parse the date strings into dayjs objects
    const day1 = dayjs(date1);
    const day2 = dayjs(date2);
  
    // Calculate the difference in milliseconds
    const diffInMilliseconds = day2.diff(day1);
  
    // Convert milliseconds to days
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
  
    // Return the absolute value to ensure a positive number of days
    return Math.abs(Math.round(diffInDays));
  }
