import React, { useContext } from "react"
import styles from "./StackedAreaTile.module.css"
import baseStyles from "../Tile.module.css"
import { CountSummary } from "../Model/types"
import { EchartsReactWrapper } from "../../Components/EchartReactWrapper"
import { aggregateCountsByTimestamp, fillMissingData, generateGradientColors, getAllTimestamps } from "../utils"
import { ProductColorsContext } from "../Dashboard"

export interface StackedAreaTileProps {
    size: { width: number; height: number }
    countSummary: CountSummary
}

const StackedAreaTile: React.FC<StackedAreaTileProps> = ({ size, countSummary }) => {
    const layoutStyle = size.width > 300 ? styles.largeLayout : styles.smallLayout

    const productColors = useContext(ProductColorsContext)

    const options = getOptions(countSummary, productColors)

    return (
        <div className={styles.stackedAreaTile}>
            <div className={baseStyles.title} style={{ paddingBottom: "20px" }}>
                Connected Device History
            </div>
            <div style={{ flexGrow: 1 }}>
                <div className={styles.chart}>
                    <EchartsReactWrapper option={options as any} />
                </div>
            </div>
        </div>
    )
}

function getOptions(countSummary: CountSummary, colors: { [id: string]: string }): any {
    const allTimestamps = getAllTimestamps(countSummary.products)
    const filledProducts = fillMissingData(countSummary.products, allTimestamps)

    const series = filledProducts.map((product, index) => {
        const data = product.historicalCounts.map((count) => ({
            name: new Date(count.timestamp).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            }),
            value: [new Date(count.timestamp), count.connectedCount],
        }))

        return {
            name: product.name,
            type: "line",
            stack: "Total",
            data: data,
            symbol: "none",
            emphasis: {
                focus: "series",
            },
            areaStyle: {
                opacity: 1,
            },
            lineStyle: {
                width: 0.5,
                color: "rgb(19 13 31)",
            },
            color: colors[product.key],
        }
    })

    const legendData = countSummary.products.map((product) => {
        return product.name
    })

    const option = {
        textStyle: {
            fontFamily: "GibsonCustom",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
            formatter: function (params: any) {
                // Sort the params array based on the value in descending order
                params.sort((a: any, b: any) => b.value[1] - a.value[1])

                // Calculate the total value for the hovered timestamp
                const total = params.reduce((sum: number, item: any) => sum + item.value[1], 0)

                // Create the tooltip content
                let content = `<div style="margin-bottom: 5px; font-size: 18px; font-family:'GibsonCustom' ">Total: <b>${total?.toLocaleString()}</b></div>`
                params.forEach((item: any) => {
                    content += `<div style="font-size: 14px; font-family:'GibsonCustom' ">${item.marker} ${item.seriesName}: <b>${item.value[1]?.toLocaleString()}</b></div>`
                })

                return content
            },
        },
        legend: {
            data: legendData,
            itemGap: 20,
            selectedMode: "multiple",
            textStyle: {
                color: "#aaa",
                fontSize: "16px",
            },
            lineStyle: {
                width: 0,
            },
        },
        xAxis: {
            type: "time",
            axisLabel: {
                color: "#aaa",
            },
        },
        yAxis: {
            type: "value",
            splitLine: {
                lineStyle: {
                    color: "rgba(255, 255, 255, 0.2)",
                },
            },
            axisLabel: {
                color: "#aaa",
            },
        },
        grid: {
            left: "0%",
            right: "1%",
            bottom: "0%",
            top: "20%",
            containLabel: true,
        },
        series: series,
    }

    return option
}

export default StackedAreaTile
