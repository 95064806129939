import { LayoutData, TileType } from "./types"

export const defaultLayoutData: LayoutData = {
    id: "00df6a5e-33f2-4e8c-99f6-f955dd00da1f",
    name: "best yet",
    tiles: [
        {
            type: "Grand Total" as TileType,
            layout: {
                w: 10,
                h: 8,
                x: 1,
                y: 0,
                i: "grand-total",
                minW: 2,
                minH: 4,
                moved: false,
                static: false,
            },
        },
        {
            type: "Product Totals" as TileType,
            layout: {
                w: 10,
                h: 20,
                x: 1,
                y: 23,
                i: "product-totals",
                minW: 2,
                minH: 4,
                moved: false,
                static: false,
            },
        },
        {
            type: "Spacer" as TileType,
            layout: {
                w: 10,
                h: 2,
                x: 1,
                y: 113,
                i: "id-0ed8325e-689d-4bc7-95af-1b70a161c626",
                minW: 1,
                minH: 1,
                moved: false,
                static: false,
            },
        },
        {
            type: "Percent Connected" as TileType,
            layout: {
                w: 10,
                h: 18,
                x: 1,
                y: 115,
                i: "id-a375c0f6-3169-4d30-ae24-376268f5fd80",
                minW: 2,
                minH: 4,
                moved: false,
                static: false,
            },
        },
        {
            type: "Feature Availability" as TileType,
            layout: {
                w: 10,
                h: 18,
                x: 1,
                y: 139,
                i: "id-d2bb6f4b-8cb2-440d-828f-2f3109f5a299",
                minW: 2,
                minH: 4,
                moved: false,
                static: false,
            },
        },
        {
            type: "Spacer" as TileType,
            layout: {
                w: 10,
                h: 6,
                x: 1,
                y: 133,
                i: "id-a788eac6-39ac-41eb-91fe-d301ea28c27f",
                minW: 1,
                minH: 1,
                moved: false,
                static: false,
            },
        },
        {
            type: "Spacer" as TileType,
            layout: {
                w: 10,
                h: 4,
                x: 1,
                y: 84,
                i: "id-d7dbf10b-a12b-4334-8254-be8bc3419e31",
                minW: 1,
                minH: 1,
                moved: false,
                static: false,
            },
        },
        {
            type: "Device Ratio" as TileType,
            layout: {
                w: 10,
                h: 15,
                x: 1,
                y: 8,
                i: "id-81acee14-371e-4498-b0a6-c0cf0ec201e6",
                minW: 2,
                minH: 4,
                moved: false,
                static: false,
            },
        },
        {
            type: "Spacer" as TileType,
            layout: {
                w: 10,
                h: 3,
                x: 1,
                y: 65,
                i: "id-7214f5a9-1e5b-4b22-9814-5a75b6bfbbf6",
                minW: 1,
                minH: 1,
                moved: false,
                static: false,
            },
        },
        {
            type: "Historical Grid" as TileType,
            layout: {
                w: 10,
                h: 25,
                x: 1,
                y: 88,
                i: "id-b4edd3d5-4080-4cc7-9134-3204c44a309a",
                minW: 2,
                minH: 4,
                moved: false,
                static: false,
            },
        },
        {
            type: "Stacked Area" as TileType,
            layout: {
                w: 10,
                h: 16,
                x: 1,
                y: 68,
                i: "id-c7f9a589-b709-4d13-bd99-53b5c75c92fc",
                minW: 2,
                minH: 4,
                moved: false,
                static: false,
            },
        },
        {
            type: "Spacer" as TileType,
            layout: {
                w: 10,
                h: 6,
                x: 1,
                y: 157,
                i: "id-59787726-f308-4a06-9747-9a7045aada1f",
                minW: 1,
                minH: 1,
                moved: false,
                static: false,
            },
        },
        {
            type: "Spacer" as TileType,
            layout: {
                w: 10,
                h: 4,
                x: 1,
                y: 43,
                i: "id-eb7906c4-173e-432e-b22d-acdf745fdc86",
                minW: 1,
                minH: 1,
                moved: false,
                static: false,
            },
        },
        {
            type: "Category Pie" as TileType,
            layout: {
                w: 10,
                h: 18,
                x: 1,
                y: 47,
                i: "id-886d171a-fd4d-4b74-9ac3-e798580f4e40",
                minW: 2,
                minH: 4,
                moved: false,
                static: false,
            },
        },
    ],
}
