import React from "react"
import ProfileImage from "./ProfileImage"
import { Resource } from "../Model/types"
import styles from "./ResourceView.module.css"

interface ResourceViewProps {
    resource: Resource
    onClick: (resource: Resource) => void
}

const ResourceView: React.FC<ResourceViewProps> = ({ resource, onClick }) => {
    const imageUrl = resource?.slackUser?.profile.image_192

    return (
        <div
            className={styles.resourceView}
            key={resource.id}
            onClick={(event) => {
                event.stopPropagation()
                onClick(resource)
            }}
        >
            <div className={styles.resourceImage}>
                <ProfileImage imageUrl={imageUrl} />
            </div>
            <div>
                <div>
                    <b>{resource.name}</b>
                </div>
                <div style={{ display: "flex", marginTop: "2px", alignItems: "flex-end", maxWidth: "300px" }}>{resource.title}</div>
            </div>
        </div>
    )
}

export default ResourceView
