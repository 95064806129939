import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { useSelector } from "react-redux"
import EchartsReactWrapper from "../../Components/EchartReactWrapper"
import { EChartOption, ECharts } from "echarts"
import styles from "./ProjectComposition.module.css"
import { useDispatch } from "../../Model/hooks"
import {
    ProjectCompositionItem,
    fetchProjectComposition,
} from "./projectCompositionSlice"
import { selectLoading, selectItems } from "./projectCompositionSlice"
import { findLevelById, getLevels } from "./utils"
import LoadingStatus from "../../Components/LoadingStatus/LoadingStatus"
import DetailPanel from "./DetailPanel"
import { motion, AnimatePresence } from "framer-motion"
import DetailStack from "./DetailStack"
import CursorFollower from "../../Components/CursorFollower"
import { tShirtColors, TShirtColor } from "./projectCompositionSlice"

interface ProjectCompositionProps {}

const ProjectComposition: React.FC<ProjectCompositionProps> = () => {
    const loading = useSelector(selectLoading)
    const items = useSelector(selectItems)
    const dispatch = useDispatch()
    const [hoveredPanelItems, setHoveredPanelItems] = useState<
        ProjectCompositionItem[]
    >([])
    const [selectedPanelItems, setSelectedPanelItems] = useState<
        ProjectCompositionItem[]
    >([])
    const [showBack, setShowBack] = useState(false)

    const data = items
    const valueFormatter = (params: any) => {
        const depth = findLevelById(items, params.data.id) ?? 0
        switch (depth) {
            case 2:
                return `{bold|Product Epic}\nStory Points: ${params.data.storyPoints}`

            case 3:
                return `{bold|Jira Epic}\nStory Points: ${params.data.storyPoints}`

            case 4:
                return `{bold|Jira Story}\nStory Points: ${params.data.storyPoints}`

            default:
                return `{bold|${params.data.name}}\nStory Points: ${params.data.storyPoints}`
        }
    }

    const option = useMemo(
        () => ({
            series: {
                type: "sunburst",
                data: data,
                radius: [0, "100%"],
                label: {
                    rotate: "radial",
                    lineHeight: 15,
                    rich: {
                        bold: {
                            fontWeight: "bold",
                        },
                    },
                },
                blur: {
                    itemStyle: {
                        opacity: 0.5,
                    },
                },
                itemStyle: {
                    borderWidth: "0.5",
                },
                startAngle: 0,
                color: ["#38A3C5", "#223250", "#223250", "#223250"],
                levels: getLevels(0, valueFormatter),
            },
        }),
        [data]
    )

    useEffect(() => {
        if (loading === "idle") {
            dispatch(fetchProjectComposition())
        }
    }, [loading, dispatch])

    const onChartClick = useCallback(
        (params: any, chart: ECharts) => {
            if (params.treePathInfo.length === 1) {
                // center was clicked - go back
                setSelectedPanelItems((prevSelectedPanelItems) => {
                    // take the last item off the selectedPanelItems
                    if (prevSelectedPanelItems.length > 0) {
                        const newPanelItems = prevSelectedPanelItems.slice(
                            0,
                            -1
                        )
                        if (newPanelItems.length === 0) {
                            setShowBack(false)
                        }

                        return newPanelItems
                    }

                    //
                    return []
                })
            } else {
                const pathToRoot =
                    getPathToItemFromRoot(items, params.data.id) ?? []
                setSelectedPanelItems(pathToRoot)
            }
        },
        [items]
    )

    const onChartHover = useCallback(
        (params: any, chart: ECharts) => {
            if (params.treePathInfo.length === 1) {
                setShowBack(true)
            } else {
                setShowBack(false)
            }

            const pathToRoot =
                getPathToItemFromRoot(items, params.data.id) ?? []
            setHoveredPanelItems(pathToRoot)
        },
        [items]
    )

    const onChartHoverEnd = useCallback(
        (params: any, chart: ECharts) => {
            setShowBack(false)
            setHoveredPanelItems([])
        },
        [items]
    )

    if (loading !== "loaded") return <LoadingStatus />

    let panelItems =
        hoveredPanelItems.length > 0 ? hoveredPanelItems : selectedPanelItems

    // panelItems.unshift({ id: "digital-foundry-item", name: "Digital Foundry", description: "", value: 1, children: [] })
    if (panelItems.length === 0) {
        const totalValue = data.reduce((acc, child) => acc + child.value, 0)
        const totalStoryPoints = data.reduce(
            (acc, child) => acc + child.storyPoints,
            0
        )
        panelItems = [
            {
                id: "digital-foundry-item",
                type: "Organization",
                name: "Digital Foundry",
                description: "",
                value: totalValue,
                storyPoints: totalStoryPoints,
                children: [],
            },
        ]
    }

    const tShirtKey = tShirtColors.map((color) => {
        return (
            <div className={styles.tShirtSize} key={color.key}>
                <div
                    className={styles.colorBlock}
                    style={{ backgroundColor: color.color }}
                ></div>
                <div>{color.name}</div>
            </div>
        )
    })

    const onFilterChange = () => {
        console.log("filter change")
        setSelectedPanelItems([])
    }

    return (
        <div className={styles.container}>
            <div className={styles.sidePanel}>
                <DetailStack
                    items={panelItems}
                    onFilterChange={onFilterChange}
                />
                <div className={styles.dataInfo}>
                    <div className={styles.colorKey}>
                        <div className={styles.tShirtKeyTitle}>
                            T-Shirt Size
                        </div>
                        <div className={styles.tShirtKey}>{tShirtKey}</div>
                    </div>
                    <div className={styles.storyPointKey}>
                        <div
                            className={styles.colorBlock}
                            style={{ backgroundColor: "#D7BD38" }}
                        ></div>
                        <div>Indicates No Story Points</div>
                    </div>
                    <div style={{ fontStyle: "italic" }}>
                        * Pie slice sizes are based on Story Points.
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                {showBack && <CursorFollower>Back</CursorFollower>}
                <EchartsReactWrapper
                    option={option as any}
                    onChartClick={onChartClick}
                    onChartHover={onChartHover}
                    onChartHoverEnd={onChartHoverEnd}
                />
            </div>
        </div>
    )
}

// Recursive function to search for the item and build the path
function findPathToRoot(
    currentItems: ProjectCompositionItem[],
    idToFind: string,
    path: ProjectCompositionItem[] = []
): ProjectCompositionItem[] | null {
    for (const item of currentItems) {
        // Add the current item to the path array
        const newPath = [...path, item]

        // Check if the current item is the one we're looking for
        if (item.id === idToFind) {
            return newPath
        }

        // Recurse into children if they exist
        if (item.children.length > 0) {
            const childPath = findPathToRoot(item.children, idToFind, newPath)
            if (childPath !== null) {
                return childPath // We've found the correct item in the children
            }
        }
    }

    // The item was not found in this branch of the tree
    return null
}

// Function to initiate the search process from the root items
function getPathToItemFromRoot(
    rootItems: ProjectCompositionItem[],
    idToFind: string
): ProjectCompositionItem[] | null {
    return findPathToRoot(rootItems, idToFind)
}

export default ProjectComposition
