import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { fetchAirtableData } from "../../Model/helpers"
import { createSelector } from "reselect"
import { RootState } from "../../Model/types"

interface SystemUptimeState {
    value: number
    staticMetricsData: any[]
    loading: string
}

const initialState: SystemUptimeState = {
    value: 0,
    staticMetricsData: [],
    loading: "idle",
}

export const fetchStaticMetrics = createAsyncThunk("metrics/fetchStaticMetrics", async () => {
    const foundryDeliveryOperationsBaseId = "appa1vuoFW7g08rw2"
    const staticMetricsTableId = "tbldKApgnbyulrWHG"
    const data = await fetchAirtableData(foundryDeliveryOperationsBaseId, staticMetricsTableId)
    return data
})

export const selectStaticMetricsData = (state: RootState) => state.systemUptime.staticMetricsData
export const selectValue = (state: RootState) => state.systemUptime.value
export const selectLoading = (state: RootState) => state.systemUptime.loading

export const selectHalfValue = createSelector([selectValue], (value) => {
    return value / 2
})

const systemUptimeSlice = createSlice({
    name: "systemUptime",
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1
        },
        decrement: (state) => {
            state.value -= 1
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStaticMetrics.pending, (state) => {
                state.loading = "loading"
            })
            .addCase(fetchStaticMetrics.fulfilled, (state, action) => {
                state.loading = "loaded"
                state.staticMetricsData = action.payload
            })
            .addCase(fetchStaticMetrics.rejected, (state) => {
                state.loading = "failed"
            })
    },
})

export const { increment, decrement, incrementByAmount } = systemUptimeSlice.actions
export default systemUptimeSlice.reducer
