export class AirtableConfig {
    static readonly foundryTeamBaseId = "appSCtlOeno48bEVV" // old
    static readonly rolesTableId = "tblHTqQE1ZOrM0aPT"
    static readonly resourcesTableId = "tblSrpdwJCZdXtH8h"

    static readonly foundryOrgBaseId = "app3PD38ExgWlzWZL" // new
    static readonly foundryOrgResourcesTableId = "tblrsut6eYdVWZW94"

    static readonly foundryOrgRolesTableId = "tblUCXBfAjhXxGQPz"
    static readonly podsTableId = "tblRqAtE3Dr6oqFC6"
    static readonly areaPathsTableId = "tbldYeHdbd5IQcm8X"
    static readonly valueStreamsTableId = "tblnQYp8cV5zEAEsW"
    static readonly platformsTableId = "tblOczY8LlCB2YrGU"
    static readonly domainsTableId = "tblZuPFNDwHp1acrw"
    static readonly groupsTableId = "tbluU7LgDD4F0oR0C"
    static readonly disciplinesTableId = "tblwQA3MryQtksRBm"
    static readonly subDisciplinesTableId = "tblsKw0C5tU2b8tIe"
    static readonly employersTableId = "tblXlHhcTCQIvDeSs"
    static readonly developerGroupsTableId = "tblzdYJYwYXOO0L1J"
    static readonly ioServicesTableId = "tblbKJJ1835JpRMuv"

    
    

    static readonly foundryDeliveryOperationsBaseId = "appa1vuoFW7g08rw2"
    static readonly productEpicsTableId = "tbl91Y2Rdxri9fWWy"
    static readonly jiraEpicsTableId = "tblnf4uZeVNxCq5rf"
    static readonly jiraStoriesTableId = "tbll7AKnesuD2R4rv"
    static readonly foundryCalendarTableId = "tblSDnN4Q7Lcm9KK4"
    
    // insert new base here
    static readonly processOperationsBaseId = "appzU62rhGXY0FPzr"
    static readonly featureWorkflowTableId = "tblHHO1BY2EoLBEgz" //
    static readonly workflowPhasesTableId = "tblvwKiKuRk1Qye4K" //
    static readonly workflowTiersTableId = "tblvNzqAe0oI3nI2H" //
    static readonly standardRoleTypesTableId = "tblKjktHlDc7MLyF4" //
   

    static readonly researchBaseId = "appPXxAdqaytWbElc"
    static readonly researchProjectsTableId = "tblFj09k8uCGZdCcl"
    static readonly researchArtifactsTableId = "tbltKEv0esWBM6XWC"
    static readonly researchAttachmentsTableId = "tbl5m6Jng0O6KSVFH"
    static readonly researchTagsTableId = "tbll7lQeCPrk8tGKG"
}
