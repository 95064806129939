import React, { createContext, useContext, ReactNode } from "react"

interface AnimationContextProps {
    animation: boolean
}

const AnimationContext = createContext<AnimationContextProps>({ animation: true })

export const useAnimation = () => useContext(AnimationContext)

export const AnimationProvider: React.FC<{ animation: boolean; children: ReactNode }> = ({ animation, children }) => {
    return <AnimationContext.Provider value={{ animation }}>{children}</AnimationContext.Provider>
}
