import DetailPanel from "./DetailPanel"
import { ProjectCompositionItem, selectCurrentFilter, selectFilters, setCurrentFilter } from "./projectCompositionSlice"
import styles from "./DetailStack.module.css"
import { AnimatePresence, motion } from "framer-motion"
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useSelector } from "react-redux"
import { useDispatch } from "../../Model/hooks"
import { v4 as uuidv4 } from "uuid"

interface DetailStackProps {
    items: ProjectCompositionItem[]
    onFilterChange: () => void
}

const DetailStack: React.FC<DetailStackProps> = ({ items, onFilterChange }) => {
    const filters = useSelector(selectFilters)
    const currentFilter = useSelector(selectCurrentFilter)
    const dispatch = useDispatch()

    const panels = items.map((item, index) => {
        return (
            // prettier-ignore
            <motion.div
                style={{}}
                // layout
                key={`detail-motion-div-${item.id}-${index}`}
                initial={{ opacity: 0, scale: 0.9, translateX: -50 }}
                animate={{ opacity: 1, scale: 1, translateX: 0 }}
                exit={{ opacity: 0, scale: 0.9, translateX: 50 }}
                transition={{ 
                    duration: 0.2, 
                    delay: 0.1, // Add delay here for the "in" animation
                    exit: { duration: 0.2 }  }}>
                <DetailPanel key={`detail-panel-${item.id}`} projectCompositionItem={item} />
            </motion.div>
        )
    })

    const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value
        dispatch(setCurrentFilter(value))
        onFilterChange()
    }

    const menuItems = filters.map((filter, index) => {
        return (
            <MenuItem value={filter} key={"menuItem-" + index}>
                {filter}
            </MenuItem>
        )
    })

    return (
        <div className={styles.container}>
            <div className={styles.dateSelector}>
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={currentFilter}
                        onChange={handleChange}
                        sx={{
                            // Standard styles
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "&.Mui-focused": {
                                boxShadow: "none",
                            },
                            // Hover styles
                            "&:hover": {
                                backgroundColor: "white", // Change background color on hover
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "gray", // Optional: change border color on hover for visibility
                                },
                                ".MuiOutlinedInput-input": {
                                    color: "black", // Ensure text color is visible against white background
                                },
                                ".MuiSvgIcon-root": {
                                    color: "black", // Ensure arrow icon is visible
                                },
                            },
                            // Adjust text position, padding, and line height
                            ".MuiSelect-select": {
                                fontWeight: "bold",
                                fontSize: "24px",
                                lineHeight: "34px", // Adjust the line height as needed
                                paddingLeft: "20px", // Adjust the padding value as needed
                                // You may also need to adjust the vertical padding to ensure the text is centered:
                                paddingTop: "10px", // Adjust the top padding as needed
                                paddingBottom: "10px", // Adjust the bottom padding as needed
                            },
                        }}
                    >
                        {menuItems}
                    </Select>
                </FormControl>
            </div>
            <AnimatePresence mode="popLayout">{panels}</AnimatePresence>
        </div>
    )
}

export default DetailStack
