import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { Resource, RootState } from "../Model/types"
import { fetchFeatureWorkflowData, fetchStandardRolesData, fetchWorkflowPhasesData, fetchWorkflowTiersData } from "../Model/helpers"

export interface ProcessNode {
    id: string
    name: string
    description: string
    workflowPhase: {
        id: string
        name: string
        order: number
    }
    workflowTier: WorkflowTier
    feedsInto: string[]
    accountableRoles: StandardRole[]
    involvedRoles: StandardRole[]
    defOfDone: string
    defOfReady: string
}

export interface StandardRole {
    id: string
    name: string
    responsibilities: string
    emails: string[]
}

export interface WorkflowTier {
    id: string
    name: string
    level: number
    featureWorkflow: string[]
}

export interface ProcessMapState {
    nodes: ProcessNode[]
    tiers: WorkflowTier[]
    loading: string
}

const initialState: ProcessMapState = {
    nodes: [],
    tiers: [],
    loading: "idle",
}

export const selectNodes = (state: RootState) => state.processMap.nodes
export const selectTiers = (state: RootState) => state.processMap.tiers
export const selectLoading = (state: RootState) => state.processMap.loading

export const fetchProcessMap = createAsyncThunk("metrics/processMap", async (args, thunkAPI) => {
    const dispatch = thunkAPI.dispatch
    //  load the neccesary airtable data
    let featureWorkflowData: any[] = []
    let workflowPhasesData: any[] = []
    let workflowTiersData: any[] = []
    let standardRolesData: any[] = []

    try {
        featureWorkflowData = await fetchFeatureWorkflowData(dispatch)
        console.log("🚀 ~ fetchProcessMap ~ featureWorkflowData:", featureWorkflowData)
    } catch (error) {
        console.error("Error fetching featureWorkflowData:", error)
    }

    try {
        workflowPhasesData = await fetchWorkflowPhasesData(dispatch)
        console.log("🚀 ~ fetchProcessMap ~ workflowPhasesData:", workflowPhasesData)
    } catch (error) {
        console.error("Error fetching workflowPhasesData:", error)
    }

    try {
        workflowTiersData = await fetchWorkflowTiersData(dispatch)
        console.log("🚀 ~ fetchProcessMap ~ workflowTiersData:", workflowTiersData)
    } catch (error) {
        console.error("Error fetching workflowTiersData:", error)
    }

    try {
        standardRolesData = await fetchStandardRolesData(dispatch)
        console.log("🚀 ~ fetchProcessMap ~ standardRolesData:", standardRolesData)
    } catch (error) {
        console.error("Error fetching standardRolesData:", error)
    }

    const nodes: ProcessNode[] = featureWorkflowData.map((item: any) => {
        const phaseItem = item.fields["Workflow Phases"] && Array.isArray(item.fields["Workflow Phases"]) 
            ? workflowPhasesData.find((phaseItem) => phaseItem.id === item.fields["Workflow Phases"][0]) 
            : undefined

        console.log("phaseItem", phaseItem)

        const tierItem = item.fields["Workflow Tiers"] && Array.isArray(item.fields["Workflow Tiers"]) 
            ? workflowTiersData.find((tierItem) => tierItem.id === item.fields["Workflow Tiers"][0]) 
            : undefined

        console.log("tierItem", tierItem)

        function createRoles(ids: string[]) {
            const roles: StandardRole[] = ids
                .map((roleId) => {
                    const standardRoleData = standardRolesData.find((standardRolesItem) => {
                        return standardRolesItem.id === roleId
                    })

                    return {
                        id: standardRoleData.id,
                        name: standardRoleData.fields["Name"],
                        responsibilities: standardRoleData.fields["Responsibilities"],
                        emails: standardRoleData.fields["Resource Emails"] ?? [],
                    }
                })
                .sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name) {
                        return 1
                    }
                    return 0
                })
            return roles
        }

        const accountableRoleIds: string[] = item.fields["Accountable Role(s) Standard"] ?? []
        const accountableRoles = createRoles(accountableRoleIds)

        const involvedRoleIds: string[] = item.fields["Involved Role(s) Standard"] ?? []
        const involvedRoles = createRoles(involvedRoleIds)

        const processNode: ProcessNode = {
            id: item.id,
            name: item.fields["Process Step"],
            description: item.fields["Description"] ?? "Description",
            workflowPhase: {
                id: phaseItem?.id,
                name: phaseItem?.fields["Name"],
                order: phaseItem?.fields["Order"],
            },
            workflowTier: {
                id: tierItem?.id,
                name: tierItem?.fields["Name"],
                level: tierItem?.fields["Level"],
                featureWorkflow: tierItem?.fields["Feature Workflow"],
            },
            feedsInto: item.fields["Feeds Into"] ?? [],
            accountableRoles: accountableRoles,
            involvedRoles: involvedRoles,
            defOfDone: item.fields["DOD - Definition of Done"],
            defOfReady: item.fields["DOR - Definition of Ready"]
        }

        return processNode
    })
    console.log("🚀 ~ constnodes:ProcessNode[]=featureWorkflowData.map ~ nodes:", nodes)

    const tiers: WorkflowTier[] = workflowTiersData.map((item: any) => {
        const tier: WorkflowTier = {
            id: item.id,
            name: item.fields["Name"],
            level: item.fields["Level"],
            featureWorkflow: item.fields["Feature Workflow"],
        }
        return tier
    })
    console.log("🚀 ~ consttiers:WorkflowTier[]=workflowTiersData.map ~ tiers:", tiers)

    return {
        nodes,
        tiers,
    } as ProcessMapState
})

const processMapSlice = createSlice({
    name: "processMap",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProcessMap.pending, (state) => {
                state.loading = "loading"
            })
            .addCase(fetchProcessMap.fulfilled, (state, action) => {
                state.nodes = action.payload.nodes
                state.tiers = action.payload.tiers
                state.loading = "loaded"
            })
            .addCase(fetchProcessMap.rejected, (state) => {
                state.loading = "failed"
            })
    },
})

export default processMapSlice.reducer
