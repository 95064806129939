import React, { useEffect, useState } from "react"

interface LocalTimeProps {
    tz: string
}

const LocalTime: React.FC<LocalTimeProps> = ({ tz }) => {
    const [time, setTime] = useState(new Date())
    const [adjustedTime, setAdjustedTime] = useState<Date | null>(null)

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date())
        }, 60 * 1000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    useEffect(() => {
        // Check if tz is defined and a string
        if (typeof tz !== "string") {
            console.warn("Invalid time zone specified: " + tz + ". Assuming it is outside of working hours.")
            setAdjustedTime(null)
            return
        }

        // Attempt to adjust time to target timezone
        try {
            const newAdjustedTime = new Date(new Date().toLocaleString("en-US", { timeZone: tz }))
            setAdjustedTime(newAdjustedTime)
        } catch (e) {
            console.warn("Invalid time zone specified: " + tz + ". Assuming it is outside of working hours.")
            setAdjustedTime(null)
        }
    }, [tz, time])

    const isOutsideWorkingHours = () => {
        if (adjustedTime === null) {
            return true
        }

        const hour = adjustedTime.getHours()
        // Compare with the working hours
        return hour < 8 || hour > 18
    }

    return (
        <div
            style={{
                display: "inline",
                color: isOutsideWorkingHours() ? "darkred" : "inherit",
            }}
        >
            {isOutsideWorkingHours() && "! "}
            {adjustedTime ? adjustedTime.toLocaleString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true }) : "--:--"} local time
        </div>
    )
}

export default LocalTime
