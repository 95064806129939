import * as d3 from "d3"
import { useEffect, useRef } from "react"
import { Node } from "./data"
import { scaleSqrt, extent } from "d3"
import { drawCircles } from "./drawCircles"

const BUBBLE_MIN_SIZE = 30
const BUBBLE_MAX_SIZE = 160
const MOUSE_PUSH_FORCE = 10 // Control the magnitude of the mouse push force

type CirclePackingProps = {
    width: number
    height: number
    data: Node[]
}

export const CirclePacking = ({ width, height, data }: CirclePackingProps) => {
    const nodes: Node[] = data.map((d) => ({ ...d }))
    const svgRef = useRef<SVGSVGElement>(null)
    const [min, max] = extent(nodes.map((d) => d.value)) as [number, number]
    const sizeScale = scaleSqrt().domain([min, max]).range([BUBBLE_MIN_SIZE, BUBBLE_MAX_SIZE])

    useEffect(() => {
        const svg = d3.select(svgRef.current as SVGSVGElement)
        svg.selectAll("*").remove() // Clear previous content

        const simulation = d3
            .forceSimulation(nodes)
            // .alphaTarget(0.3) // stay hot
            .velocityDecay(0.6)
            .force("center", d3.forceCenter(width / 2, height / 3))
            .force(
                "collide",
                d3
                    .forceCollide()
                    .radius((node) => sizeScale((node as Node).value) + 1)
                    .strength(1)
            )
            .force("y", d3.forceY(0).strength(0.1))
            // .force("charge", d3.forceManyBody().strength(10))
            .force("boundary", forceBoundary(0, 0, width, height, sizeScale, 0.1))
            .on("tick", () => {
                drawCircles(svg, width, height, nodes, sizeScale)
            })

        // Add mouse responsiveness
        svg.on("touchmove", (event) => event.preventDefault()).on("pointermove", pointermoved)

        function pointermoved(event: PointerEvent) {
            const [mouseX, mouseY] = d3.pointer(event)
            simulation.force("mouse", mouseForce(mouseX, mouseY))
            simulation.alpha(0.1).restart()
        }

        function mouseForce(mouseX: number, mouseY: number) {
            return () => {
                for (const node of nodes) {
                    if (node.x === undefined || node.y === undefined || node.vx === undefined || node.vy === undefined) continue

                    const dx = node.x - mouseX
                    const dy = node.y - mouseY
                    const distance = Math.sqrt(dx * dx + dy * dy)
                    const radius = sizeScale(node.value) + 1

                    if (distance < radius) {
                        const strength = ((radius - distance) / radius) * MOUSE_PUSH_FORCE
                        node.vx += (dx / distance) * strength
                        node.vy += (dy / distance) * strength
                    }
                }
            }
        }

        return () => {
            simulation.stop()
            svg.on("pointermove", null)
        }
    }, [width, height, nodes, sizeScale])

    return <svg ref={svgRef} width={width} height={height} style={{ overflow: "visible" }}></svg>
}

function forceBoundary(x0: number, y0: number, x1: number, y1: number, sizeScale: any, strength: number = 0.1) {
    let nodes: any

    function force() {
        for (const node of nodes) {
            const radius = sizeScale(node.value) + 1
            if (node.x === undefined || node.y === undefined) continue

            if (node.x - radius < x0) node.vx += (x0 + radius - node.x) * strength
            if (node.x + radius > x1) node.vx -= (node.x - (x1 - radius)) * strength
            if (node.y - radius < y0) node.vy += (y0 + radius - node.y) * strength
            if (node.y + radius > y1) node.vy -= (node.y - (y1 - radius)) * strength
        }
    }

    force.initialize = function (_: any) {
        nodes = _
    }

    return force
}
