import React from "react"
import styles from "./GrandTotalTile.module.css"
import logo from "../../Assets/carrierLogo.svg"
import { CountSummary } from "../Model/types"

export interface GrandTotalTileProps {
    countSummary: CountSummary
    size: { width: number; height: number }
}

const GrandTotalTile: React.FC<GrandTotalTileProps> = ({ countSummary, size }) => {
    const sizeBucket = size.width < 400 ? "small" : size.width < 800 ? "medium" : "large"
    // console.log("🚀 ~ sizeBucket:", sizeBucket)

    const total = countSummary.totalConnected.toLocaleString()

    const totalStyle = `${styles.total} ${styles[sizeBucket]}`
    const titleStyle = `${styles.title} ${styles[sizeBucket]}`
    const logoStyle = `${styles.logo} ${styles[sizeBucket]}`

    return (
        <div className={styles.grandTotalTile}>
            <div className={totalStyle}>{total}</div>
            <div className={titleStyle}>
                <img src={logo} className={logoStyle} alt="Carrier" />
                <div>Connected Devices</div>
            </div>
        </div>
    )
}

export default GrandTotalTile
