import { useEffect, useState } from "react"
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react"

// Put your API key here
builder.init("9e6b3475d8e748c3a7a3c7c1165658ef")

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function Home() {
    const isPreviewingInBuilder = useIsPreviewing()
    const [notFound, setNotFound] = useState(false)
    const [content, setContent] = useState(null)

    // get the page content from Builder
    useEffect(() => {
        async function fetchContent() {
            const content = await builder
                .get("homepage", {
                    url: window.location.pathname,
                })
                .promise()

            setContent(content)
            setNotFound(!content)

            // if the page title is found,
            // set the document title
            if (content?.data.title) {
                document.title = content.data.title
            }
        }
        fetchContent()
    }, [window.location.pathname])

    // If no page is found, return
    // a 404 page from your code.
    // The following hypothetical
    // <FourOhFour> is placeholder.
    if (notFound && !isPreviewingInBuilder) {
        return <div>404 Error - No builder.io page found. </div>
    }

    // return the page when found
    return (
        <div style={{ margin: "40px" }}>
            {/* Render the Builder page */}
            {content && <BuilderComponent model="homepage" content={content} />}
        </div>
    )
}
