import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchFoundryCalendarData } from "../Model/helpers"
import { RootState } from "../Model/types"
import dayjs from "dayjs"
import Color from "color"

export interface CalendarEvent {
    id: string
    name: string
    category: string
    type: string
    start: string
    end: string
    numDays: number
}

export interface TimelineChunk {
    id: string
    parentEvent: CalendarEvent
    childEvents: CalendarEvent[]
}

export interface EventCategory {
    name: string
    color: string
    visible: boolean
}

export interface FoundryCalendarState {
    timelineChunks: TimelineChunk[]
    eventCategories: EventCategory[]
    loading: string
}

const initialState: FoundryCalendarState = {
    timelineChunks: [],
    eventCategories: [],
    loading: 'idle'
}

export const selectTimelineChunks = (state: RootState) => state.foundryCalendar.timelineChunks
export const selectEventCategories = (state: RootState) => state.foundryCalendar.eventCategories
export const selectLoading = (state: RootState) => state.foundryCalendar.loading

export const fetchFoundryCalendar = createAsyncThunk("foundryCalendar", async (_args, thunkAPI) => {
    const dispatch = thunkAPI.dispatch
    //  load the neccesary airtable data
    const calendarData = await fetchFoundryCalendarData(dispatch)

    return createFoundryCalendarState(calendarData)
})

const createFoundryCalendarState = (calendarData: any[]) => {

    const uniqueTypes = new Set(calendarData.map(item => item.fields["Type"]).filter(type => type !== undefined).filter(type => type !== "PI") );
    const uniqueCategories = Array.from(uniqueTypes).sort((a, b) => {
        // Compare in descending order
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
    });

    const eventCategories = uniqueCategories.map((name, index) => {
        const hue = 200 + (index * 40) 
        const color = Color(`hsl(${hue}, 50%, 70%)`)
        const eventCategory: EventCategory = {
            name: name,
            color: color.hex(),
            visible: true,
        }
        return eventCategory
    })

    const piEvents = calendarData.filter(item => {
        const type = item.fields["Type"] ?? ""
        return type === "PI"
    }).map(item => {
        const numDays = daysBetweenDates(item.fields["Start"], item.fields["End"])
        const piEvent: CalendarEvent = {
            id: item.id,
            name: item.fields["Name"],
            category: item.fields["Category"],
            type: item.fields["Type"],
            start: item.fields["Start"],
            end: item.fields["End"],
            numDays: numDays,
        }
        return piEvent
    })

    const chunks: TimelineChunk[] = piEvents.map((piEvent, index) => {

        const childItems = filterItemsByDateRange(calendarData, piEvent.start, piEvent.end)
        const childEvents = childItems.map(item => {
            const numDays = daysBetweenDates(item.fields["Start"], item.fields["End"])
            const childEvent: CalendarEvent = {
                id: item.id,
                name: item.fields["Name"],
                category: item.fields["Category"],
                type: item.fields["Type"],
                start: item.fields["Start"],
                end: item.fields["End"],
                numDays: numDays,
            }
            return childEvent
        })

        const chunk: TimelineChunk = {
            id: "id-chunk-" + index,
            parentEvent: piEvent,
            childEvents: childEvents,
        }
        return chunk
    })

    const sortedChunks = sortTimelineChunks(chunks)

    return {
        timelineChunks: sortedChunks,
        eventCategories: eventCategories,
    } as FoundryCalendarState
}

function sortTimelineChunks(timelineChunks: TimelineChunk[]): TimelineChunk[] {
    return timelineChunks.sort((a, b) => {
        // Parse the start dates using dayjs
        const startDateA = dayjs(a.parentEvent.start);
        const startDateB = dayjs(b.parentEvent.start);

        // Compare the dates
        return startDateA.isBefore(startDateB) ? -1 : startDateA.isAfter(startDateB) ? 1 : 0;
    });
}

function daysBetweenDates(startDateStr: string, endDateStr: string): number {
    // Parse the start and end dates
    const startDate = dayjs(startDateStr);
    const endDate = dayjs(endDateStr);

    // Calculate the difference in days and add 1 for inclusivity
    return endDate.diff(startDate, 'day') + 1;
}

function filterItemsByDateRange(items: any[], startDateStr: string, endDateStr: string): any[] {
    // Parse the input start and end dates
    const startDate = dayjs(startDateStr);
    const endDate = dayjs(endDateStr);

    return items.filter(item => {

        if (item.fields["Start"] === undefined || 
            item.fields["End"] === undefined || 
            item.fields["Type"] === "PI") {
            return false
        }

        // Parse the item's start and end dates
        const itemStartDate = dayjs(item.fields["Start"]);
        const itemEndDate = dayjs(item.fields["End"]);

        // Check if the item's start or end date falls within or is the same as the input date range
        const isStartInRange = itemStartDate.isSame(startDate) || itemStartDate.isAfter(startDate) && itemStartDate.isBefore(endDate);
        const isEndInRange = itemEndDate.isSame(endDate) || itemEndDate.isAfter(startDate) && itemEndDate.isBefore(endDate);

        return isStartInRange || isEndInRange;
    });
}


const foundryCalendarSlice = createSlice({
    name: "productIncrement",
    initialState,
    reducers: {
        toggleCagetory: (state, action) => {
            const catName = action.payload

            // Find the index of the category in the eventCategories array
            const categoryIndex = state.eventCategories.findIndex(cat => cat.name === catName);

            // If the category is found, toggle its visible property
            if (categoryIndex !== -1) {
                state.eventCategories[categoryIndex].visible = !state.eventCategories[categoryIndex].visible;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFoundryCalendar.pending, (state) => {
                state.loading = "loading"
            })
            .addCase(fetchFoundryCalendar.fulfilled, (state, action) => {
                state.timelineChunks = action.payload.timelineChunks
                state.eventCategories = action.payload.eventCategories
                state.loading = "loaded"
            })
            .addCase(fetchFoundryCalendar.rejected, (state) => {
                state.loading = "failed"
            })
    },
})

export const { toggleCagetory } = foundryCalendarSlice.actions
export default foundryCalendarSlice.reducer