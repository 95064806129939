import React, { useContext } from "react"
import styles from "./HistoricalCount.module.css"
import { CountSummary, Product } from "../Model/types"
import { EchartsReactWrapper } from "../../Components/EchartReactWrapper"
import { ProductColorsContext } from "../Dashboard"

export interface HistoricalCountParams {
    productKey: string
}

export interface HistorialCountTileProps {
    size: { width: number; height: number }
    countSummary: CountSummary
    params: HistoricalCountParams
}

const HistoricalCountTile: React.FC<HistorialCountTileProps> = ({ size, countSummary, params }) => {
    const layoutStyle = size.width > 300 ? styles.largeLayout : styles.smallLayout

    const productColors = useContext(ProductColorsContext)

    const product = countSummary.products.find((product) => product.key === params.productKey)
    const color = productColors[params.productKey]

    const options = getOptions(product!, color)

    return (
        <div className={`${styles.historicalCountTile} ${layoutStyle}`}>
            <div className={styles.title}>{product?.name}</div>
            <div style={{ height: "100%" }}>
                <div className={styles.chart}>
                    <EchartsReactWrapper option={options as any} />
                </div>
            </div>
        </div>
    )
}

function getOptions(product: Product, color: string): any {
    const data = product.historicalCounts
        .map((count) => {
            const date = new Date(count.timestamp)
            const readableDate = date.toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            })

            return {
                name: readableDate,
                value: [date, count.connectedCount] as [Date, number],
            }
        })
        .sort((a, b) => (a.value[0] as Date).getTime() - (b.value[0] as Date).getTime())

    // Add placeholder data if no data exists
    const placeholderData = [{ value: [new Date(), 0] }]

    const series = {
        name: product.name,
        type: "line",
        data: data.length > 0 ? data : placeholderData,
        symbol: "circle",
        emphasis: {
            focus: "series",
        },
        color: color,
        showSymbol: data.length > 0, // Hide symbol if using placeholder data
        lineStyle: {
            opacity: data.length > 0 ? 1 : 0, // Hide line if using placeholder data
        },
    }

    const option = {
        textStyle: {
            fontFamily: "GibsonCustom",
        },
        title: {
            show: data.length === 0,
            text: "No Data",
            left: "center",
            top: "middle",
            textStyle: {
                color: "gray",
                fontSize: 16,
            },
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        xAxis: {
            type: "time",
            axisLabel:
                data.length > 0
                    ? { show: true, color: "#aaa" }
                    : {
                          formatter: (value: any) => {
                              return data.length > 0 ? new Date(value).toLocaleDateString() : ""
                          },
                          color: "#aaa",
                      },
        },
        yAxis: {
            type: "value",
            splitLine: {
                lineStyle: {
                    color: "rgba(255, 255, 255, 0.2)",
                },
            },
            axisLabel: {
                show: data.length > 0,
                color: "#aaa",
                formatter: (value: any) => {
                    if (value >= 1000) {
                        return Math.round(value / 1000) + "k"
                    }
                    return value
                },
            },
            min: "dataMin",
        },
        grid: {
            left: "0%",
            right: "1%",
            bottom: "0%",
            top: "5%",
            containLabel: true,
        },
        series: series,
    }

    return option
}

export default HistoricalCountTile
