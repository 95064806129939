import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { fetchFoundryCalendarData, fetchJiraEpicsData, fetchProductEpicsData } from "../../Model/helpers"
import { RootState } from "../../Model/types"
import { JiraEpic, JiraOverviewModel, ProductEpic, Sprint } from "../../Model/types"
import { setLoadingMessage } from "../../Components/LoadingStatus/LoadingStatus"
import { statusCategories } from "./statusCategories"
import { daysBetween, daysBetweenDates, isDateBeforeTarget, isDateInRange } from "./utils"
import dayjs from "dayjs"

export interface ProductIncrementState {
    currentPi: ProgramIncrement
    jiraOverviews: JiraOverviewModel[]
    loading: string
}

export interface ProgramIncrement {
    name: string
    start: string
    end: string
    daysIn: number
    daysLeft: number
    totalDays: number
    sprints: Sprint[]
  }

const initialState: ProductIncrementState = {
    currentPi: {
        name: "",
        start: new Date().toISOString(),
        end: new Date().toISOString(),
        daysIn: 0,
        daysLeft: 0,
        totalDays: 0,
        sprints: [],
    },
    jiraOverviews: [],
    loading: "idle",
}

export const selectCurrentPi = (state: RootState) => state.productIncrement.currentPi
export const selectJiraOverviews = (state: RootState) => state.productIncrement.jiraOverviews
export const selectLoading = (state: RootState) => state.productIncrement.loading

export const fetchProductIncrement = createAsyncThunk("metrics/productIncrement", async (_args, thunkAPI) => {
    const dispatch = thunkAPI.dispatch
    //  load the neccesary airtable data
    const calendarData = await fetchFoundryCalendarData(dispatch)
    // const jiraOverviewData = await fetchJiraOverviewData(dispatch)
    const productEpicsData = await fetchProductEpicsData(dispatch)
    const jiraEpicsData = await fetchJiraEpicsData(dispatch)

    // const filteredJiraOverviewData = jiraOverviewData.filter((item:any) => {
    //     return item.fields["Project"] === "carrier.io" ||
    //             item.fields["Project"] === "AHP" ||
    //             item.fields["Project"] === "Abound"
    // })
    // console.log("🚀 ~ filteredJiraOverviewData ~ filteredJiraOverviewData:", filteredJiraOverviewData)

    return createProductIncrementState(calendarData, productEpicsData, jiraEpicsData)
})

const productIncrementSlice = createSlice({
    name: "productIncrement",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductIncrement.pending, (state) => {
                state.loading = "loading"
            })
            .addCase(fetchProductIncrement.fulfilled, (state, action) => {
                state.currentPi = action.payload.currentPi
                state.jiraOverviews = action.payload.jiraOverviews
                state.loading = "loaded"
            })
            .addCase(fetchProductIncrement.rejected, (state) => {
                state.loading = "failed"
            })
    },
})

const createProductIncrementState = (calendarData: any[], productEpicsData: any[], jiraEpicsData: any[]) => {
    // find the current PI item
    const piItem: any = calendarData.filter((item) => {
        return item.fields["Current"] === "Current PI"
    })[0]

    const sprints: Sprint[] = calendarData
        .filter((item) => {
            const isSprint = item.fields["Type"] === "Sprint" 
            || item.fields["Type"] === "I&P Sprint" 
            || item.fields["Type"] === "Hardening Sprint"
            return item.fields["Program Increment"] === piItem.fields["Program Increment"] && isSprint
        })
        .map((item) => {
            return {
                id: item.id,
                name: item.fields["Name"],
                start: new Date(item.fields["Start"]).toISOString(),
                end: new Date(item.fields["End"]).toISOString(),
            }
        })
        .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())

    const currentPi: ProgramIncrement = {
        name: piItem.fields["Name"],
        start: piItem.fields["Start"],
        end: piItem.fields["End"],
        daysIn: piItem.fields["Days In"],
        daysLeft: piItem.fields["Days Left"],
        totalDays: piItem.fields["Total Days in Pi"],
        sprints: sprints,
    }

    const projects = ["carrier.io", "AHP", "Abound"]

    return {
        currentPi: currentPi,
        jiraOverviews: projects.map((projectName) => {

            const project = projectName

            // get all jira epics due in pi
            const jiraEpics: JiraEpic[] = jiraEpicsData.filter(jiraEpicItem => {
                const projectField = jiraEpicItem.fields["Project"] ?? ""
                const committedDate = jiraEpicItem.fields["Committed Completion Date"] ?? ""
                const projectedDate = jiraEpicItem.fields["Projected Completion Date"] ?? ""
                const isDueInPi = isDateInRange(committedDate, currentPi.start, currentPi.end)
                const wasCompletedEarly = isDateBeforeTarget(projectedDate, currentPi.start)
                return projectField === project && isDueInPi && !wasCompletedEarly 
            }).map(jiraEpicItem => {
                const jiraEpic = createJiraEpicFromData(jiraEpicItem)
                jiraEpic.isDueInPi = true
                return jiraEpic
            })
           
            // get all product epics keys with jira epics due in pi
            const productEpicKeys = Array.from(new Set(jiraEpics.map(epic => epic.parent)))

            // create product epics and include all child jira epics
            const productEpics: ProductEpic[] = productEpicKeys
                .map((key) => {
                    return productEpicsData.find(item => item.fields["Issue Key"] === key);
                })
                .filter(productEpicData => productEpicData !== undefined)
                .sort(sortByNumericStringField("AHA PE RANK"))
                .map((productEpicItem) => {
                    return createProductEpicFromData(productEpicItem, jiraEpicsData, currentPi);
                })


            // const completedStatuses = ["Deployed", "Accepted", "Closed", "Done", "Ready for Release"]
            const completedStatuses = statusCategories.find(category => category.name === "Complete")?.statuses ?? []

            const completeProductEpics = productEpics.filter((productEpic) => completedStatuses.includes(productEpic.status))

            const jiraOverview: JiraOverviewModel = {
                id: "id-" + projectName,
                name: projectName,
                productEpics: productEpics,
                productEpicTotal: productEpics.length,
                productEpicComplete: completeProductEpics.length,
                jiraEpics: jiraEpics
            }

            return jiraOverview
        }),
    } as ProductIncrementState
}

const createProductEpicFromData = (productEpicData: any, jiraEpicsData: any[], currentPi: ProgramIncrement): ProductEpic => {
    const projectedCompletionDate = productEpicData.fields["Committed Completion Date"]
    const committedCompletionDate = productEpicData.fields["Projected Completion Date"]
    const completionDelta = daysBetweenDates(committedCompletionDate, projectedCompletionDate)

    const childIssues = jiraEpicsData.filter(jiraEpicData => {
        
        return jiraEpicData.fields["Parent"] === productEpicData.fields["Issue Key"]
    })
    const childJiraEpics = childIssues.map(jiraData => {
        const jiraEpic = createJiraEpicFromData(jiraData)
        const dueDate = jiraData.fields["Committed Completion Date"] ?? ""
        jiraEpic.isDueInPi = isDateInRange(dueDate, currentPi.start, currentPi.end)

        return jiraEpic
    })

    


    const dueDate = productEpicData.fields["Committed Completion Date"] ?? ""
    const isDueInPi = isDateInRange(dueDate, currentPi.start, currentPi.end)

    return {
        id: productEpicData.id,
        issueKey: productEpicData.fields["Issue Key"],
        summary: productEpicData.fields["Summary"],
        status: productEpicData.fields["Status"],
        jiraEpics: childJiraEpics,
        project: productEpicData.fields["Project"],
        description: productEpicData.fields["Description"],
        startDate: productEpicData.fields["Start date"],
        committedCompletionDate: committedCompletionDate,
        projectedCompletionDate: projectedCompletionDate,
        completionDelta: completionDelta,
        valueStream: productEpicData.fields["Value Stream"],
        tShirtSize: productEpicData.fields["T-Shirt Size"],
        reporter: productEpicData.fields["Reporter"],
        assignee: productEpicData.fields["Assignee"],
        pod: productEpicData.fields["Pod"],
        rank: productEpicData.fields["AHA PE RANK"],
        isDueInPi: isDueInPi
    }
}

const createJiraEpicFromData = (jiraEpicData: any): JiraEpic => {
    const projectedCompletionDate = jiraEpicData.fields["Committed Completion Date"]
    const committedCompletionDate = jiraEpicData.fields["Projected Completion Date"]
    const completionDelta = daysBetweenDates(committedCompletionDate, projectedCompletionDate)
    return {
        id: jiraEpicData.id,
        issueKey: jiraEpicData.fields["Issue Key"],
        summary: jiraEpicData.fields["Summary"],
        status: jiraEpicData.fields["Status"],
        project: jiraEpicData.fields["Project"],
        description: jiraEpicData.fields["Description"],
        parent: jiraEpicData.fields["Parent"],
        startDate: jiraEpicData.fields["Start date"],
        committedCompletionDate: committedCompletionDate,
        projectedCompletionDate: projectedCompletionDate,
        completionDelta: completionDelta,
        valueStream: jiraEpicData.fields["(ABOUND) Value Stream"],
        tShirtSize: jiraEpicData.fields["T-Shirt Size"],
        reporter: jiraEpicData.fields["Reporter"],
        assignee: jiraEpicData.fields["Assignee"],
        pod: jiraEpicData.fields["Pod"],
        isDueInPi: false
    }
}

function sortByNumericStringField(fieldName: string) {
    return (a: any, b: any): number => {
      const valueA = a.fields[fieldName];
      const valueB = b.fields[fieldName];
  
      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);
  
      const isNumA = !isNaN(numA) && isFinite(numA);
      const isNumB = !isNaN(numB) && isFinite(numB);
  
      if (isNumA && isNumB) {
        // Both are numbers, sort numerically
        return numA - numB;
      } else if (isNumA) {
        // Only A is a number, sort A before B
        return -1;
      } else if (isNumB) {
        // Only B is a number, sort B before A
        return 1;
      } else {
        // Neither A nor B is a number, maintain original order (stable sort)
        return 0;
      }
    };
  }
  
 
  

export default productIncrementSlice.reducer
