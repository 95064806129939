import React, { useContext } from "react"
import styles from "./CirclesTile.module.css"
import { CountSummary } from "../../Model/types"
import { CirclePacking } from "./CirclePacking"
import { data, generateNodes } from "./data"
import { ProductColorsContext } from "../../Dashboard"

export interface CirclesTileProps {
    size: { width: number; height: number }
    countSummary: CountSummary
}

const CirclesTile: React.FC<CirclesTileProps> = ({ size, countSummary }) => {
    const title = "Circles"
    const layoutStyle = size.width > 300 ? styles.largeLayout : styles.smallLayout

    const productColors = useContext(ProductColorsContext)
    const productData = generateNodes(countSummary.products, productColors)

    return (
        <div className={`${styles.circlesTile} ${layoutStyle}`}>
            <CirclePacking width={size.width} height={size.height} data={productData} />
        </div>
    )
}

export default CirclesTile
