import { Product } from "../../Model/types";

export interface Node extends d3.SimulationNodeDatum {
    id: string;
    name: string;
    value: number;
    color: string;
}


  
export const data: Node[] = [
    { id: '1', name: 'Myriel', value: 90, color: '#e0ac2b' },
    { id: '2', name: 'Anne', value: 10, color: '#e85252' },
    { id: '3', name: 'Gabriel', value: 34, color: '#6689c6' },
    { id: '4', name: 'Mel', value: 9, color: '#9a6fb0' },
    { id: '5', name: 'Yan', value: 16, color: '#a53253' },
    { id: '6', name: 'Tom', value: 93, color: '#69b3a2' },
    { id: '7', name: 'Cyril', value: 23, color: '#e0ac2b' },
    { id: '8', name: 'Tuck', value: 54, color: '#e85252' },
    { id: '9', name: 'Antoine', value: 9, color: '#6689c6' },
    { id: '10', name: 'Rob', value: 78, color: '#9a6fb0' },
    { id: '11', name: 'Napoleon', value: 45, color: '#a53253' },
    { id: '12', name: 'Toto', value: 78, color: '#69b3a2' },
    { id: '13', name: 'Tutu', value: 98, color: '#e0ac2b' },
    { id: '14', name: 'Titi', value: 9, color: '#e85252' },
    { id: '15', name: 'Tata', value: 5, color: '#6689c6' },
    { id: '16', name: 'Turlututu', value: 45, color: '#9a6fb0' },
    { id: '17', name: 'Tita', value: 59, color: '#a53253' },
];


export const generateNodes = (products: Product[], colors: { [id: string]: string }): Node[] => {
    return products.map((product, index) => {
        const value = product.latestCount?.connectedCount ?? 0; // Use latestCount as the value
        const color = colors[product.key] || '#000'; // Default to black if no color is found

        return {
            id: product.key,
            name: product.name,
            value: value,
            color: color,
        };
    });
};