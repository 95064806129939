import { configureStore } from "@reduxjs/toolkit"
import systemUptimeReducer from "../Metrics/SystemUptime/systemUptimeSlice"
import productIncrementReducer from "../Metrics/ProductIncrement/productIncrementSlice"
import processMapReducer from "../ProcessMap/processMapSlice"
import projectCompositionReducer from "../Metrics/ProjectComposition/projectCompositionSlice"
import { loadingStatusReducer } from "../Components/LoadingStatus/LoadingStatus"
import { resourcesReducer } from "./slices/resourcesSlice"
import foundryCalendarReducer from '../Calendar/foundryCalendarSlice'
import userResearchReducer from '../UserResearch/userResearchSlice'

const store = configureStore({
    reducer: {
        systemUptime: systemUptimeReducer,
        productIncrement: productIncrementReducer,
        processMap: processMapReducer,
        projectComposition: projectCompositionReducer,
        loadingStatus: loadingStatusReducer,
        resources: resourcesReducer,
        foundryCalendar: foundryCalendarReducer,
        userResearch: userResearchReducer
    },
})

export type AppDispatch = typeof store.dispatch
export default store
