import { Role } from "../Model/types"
import styles from "./RoleDetailView.module.css"
import ProfileImage from "./ProfileImage"
import Store from "../Model/Store"
import ResourceDetails from "./ResourceDetails"

type RoleDetailViewProps = {
    role: Role
    store: Store
    onSelectResource: (id: string) => void
}

function RoleDetailView({ role, store, onSelectResource }: RoleDetailViewProps): JSX.Element {
    const resource = role.resource ?? role.interimResource ?? undefined

    const resourceDetails = resource ? <ResourceDetails resource={resource} onSelectResource={onSelectResource} /> : null

    const resourceTitle = role.resource ? "Resource:" : role.interimResource ? "Interim Resource:" : "Open Role"
    return (
        <div className={styles.container}>
            <div className={styles.profileImage}>
                <ProfileImage imageUrl={resource?.slackUser?.profile.image_512} />
            </div>
            <div className={styles.roleSummary}>
                <div className={styles.roleTitle}>{role.roleName}</div>
                <div className={styles.roleBreadcrumb}>{role.breadcrumb}</div>
            </div>

            {resourceDetails}
        </div>
    )
}

export default RoleDetailView
