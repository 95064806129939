import React, { CSSProperties, useContext } from "react"
import styles from "./FeatureAvailability.module.css"
import baseStyles from "../Tile.module.css"
import { CountSummary, Product } from "../Model/types"
import { EchartsReactWrapper } from "../../Components/EchartReactWrapper"
import { generateGradientColors } from "../utils"
import { EChartOption } from "echarts"
import { ProductColorsContext } from "../Dashboard"
import { axisLeft } from "d3"

export interface FeatureAvailabilityTileProps {
    size: { width: number; height: number }
    countSummary: CountSummary
}

const FeatureAvailabilityTile: React.FC<FeatureAvailabilityTileProps> = ({ size, countSummary }) => {
    const layoutStyle = size.width > 300 ? styles.largeLayout : styles.smallLayout

    const productColors = useContext(ProductColorsContext)

    const options = getChartOptions(countSummary, productColors)

    return (
        <div className={styles.featureAvailabilityTile}>
            <div className={baseStyles.title}>Current Product Feature Availability</div>
            <div style={{ flexGrow: 1 }}>
                <div className={styles.chart}>
                    <EchartsReactWrapper option={options as any} />
                </div>
            </div>
        </div>
    )
}

function getChartOptions(countSummary: CountSummary, colors: { [id: string]: string }): any {
    const products = countSummary.products.map((product) => product.name)

    const features: [string, string][] = [
        ["hasDeviceData", "Device Data"],
        ["conformsToConnectedDefinition", "Conforms to Connected"],
        ["hasSimpleLocation", "Simple Location"],
        ["hasExactLocation", "Exact Location"],
        ["errorCount", "Error Data"],
        ["alarmCount", "Alarm Data"],
    ]

    const featureNames = features.map((feature) => feature[1])

    const data: { x: number; y: number; enabled: boolean; key: string }[] = []

    countSummary.products.forEach((product, productIndex) => {
        features.forEach(([featureKey, _], featureIndex) => {
            let enabled: boolean

            if (featureKey === "errorCount" || featureKey === "alarmCount") {
                enabled = (product as any)[featureKey] !== undefined
            } else {
                enabled = (product as any)[featureKey] as boolean
            }

            data.push({ x: featureIndex, y: productIndex, enabled, key: product.key })
        })
    })

    const options = {
        textStyle: {
            fontFamily: "GibsonCustom",
        },
        tooltip: {
            position: "top",
            formatter: function (params: any) {
                const productIndex = params.value[1]
                const featureIndex = params.value[0]
                const enabled = params.value[2] ? "Enabled" : "Disabled"
                return `${products[productIndex]}<br/>${featureNames[featureIndex]}: ${enabled}`
            },
        },
        grid: {
            left: "0%",
            right: "0%",
            bottom: "0%",
            top: "3%",
            containLabel: true,
        },
        xAxis: {
            type: "category",
            data: featureNames,
            splitArea: {
                show: true,
                areaStyle: {
                    color: ["rgba(255,255,255, 0.01)", "rgba(255,255,255, 0.02)"],
                },
            },
            axisLabel: {
                interval: 0,
                color: "#aaa",
            },
        },
        yAxis: {
            type: "category",
            data: products,
            splitArea: {
                show: true,
                areaStyle: {
                    color: ["rgba(255,255,255, 0.04)", "rgba(255,255,255, 0.02)"],
                },
            },
            axisLabel: {
                color: "#aaa",
            },
        },
        series: [
            {
                name: "Features",
                type: "custom",
                renderItem: function (params: any, api: any) {
                    const featureIndex = api.value(0)
                    const productIndex = api.value(1)
                    const enabled = api.value(2)
                    const key = api.value(3)

                    const x = api.coord([featureIndex, productIndex])[0]
                    const y = api.coord([featureIndex, productIndex])[1]
                    const size = Math.min(api.size([1, 0])[0], api.size([0, 1])[1]) / 1.5

                    return {
                        type: "circle",
                        shape: {
                            cx: x,
                            cy: y,
                            r: size / 2, // Adjust the radius as needed
                        },
                        style: {
                            fill: enabled ? colors[key] : "rgba(255, 255, 255, 0.0)",
                        },
                    }
                },
                data: data.map((d) => [d.x, d.y, d.enabled, d.key]),
            },
        ],
    }

    return options
}

export default FeatureAvailabilityTile
