import React, { CSSProperties } from "react"
import styles from "./AreaPathCard.module.css"
import RoleView from "./Views/RoleView"
import { AreaPath, Pod, Role } from "../Model/types"
import { PodColorsContext } from "./SimplifiedValueStreams"

interface AreaPathCardProps {
    areaPath: AreaPath
    onRoleClick: (role: Role) => void
}

const AreaPathCard: React.FC<AreaPathCardProps> = ({ areaPath, onRoleClick }) => {
    const podColors = React.useContext(PodColorsContext)

    const leads = areaPath.support.map((role) => {
        return (
            <div key={"areaPathLead-" + role.id} className={styles.roleContainer}>
                <RoleView role={role} onClick={onRoleClick} />
            </div>
        )
    })

    const podViews = areaPath.pods.map((pod) => {
        const color = podColors?.[pod.id]
        const style: CSSProperties = { backgroundColor: color }
        return (
            <div key={pod.id} className={styles.pod} style={style}>
                {pod.name}
            </div>
        )
    })

    const services = areaPath.services?.map((service) => {
        const onServiceClick = () => {
            const url = `https://carrier-digital.atlassian.net/browse/${service.jiraKey}`
            window.open(url, "_blank")
        }

        return (
            <div key={service.id} className={styles.service} onClick={onServiceClick}>
                {service.name}
            </div>
        )
    })

    return (
        <div className={styles.areaPathCard}>
            <div className={styles.name}>{areaPath.name}</div>
            {leads.length > 0 && <div className={styles.leads}>{leads}</div>}
            {podViews.length > 0 && <div className={styles.pods}>{podViews}</div>}
            {services && services.length > 0 && (
                <div className={styles.services}>
                    <b>Services:</b>
                    {services}
                </div>
            )}
        </div>
    )
}

export default AreaPathCard
