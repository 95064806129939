import React, { useState } from "react"
import styles from "./RoleChip.module.css"
import { Role } from "../Model/types"
import ProfileImage from "./ProfileImage"
import { Tooltip } from "@mui/material"
import Pill from "./Pill"

type RoleChipProps = {
    role: Role
    onClick: (id: string) => void
}

const RoleChip: React.FC<RoleChipProps> = ({ role, onClick }) => {
    const tooltipContent = (
        <div style={{ fontSize: "14px" }}>
            <div style={{ fontWeight: "bold" }}>{role.roleName}</div>
            <div>{role.resource?.name ?? role.interimResource?.name}</div>
        </div>
    )

    const tag = role.resource ? null : role.interimResource ? <Pill text="Interim" color="rgb(211, 126, 16)" /> : <Pill text="Open" color="rgb(173, 41, 41)" />

    return (
        <Tooltip title={tooltipContent} placement="top" arrow>
            <div className={styles.container}>
                <div
                    className={styles.profileImage}
                    onClick={() => {
                        onClick(role.id)
                    }}
                >
                    <ProfileImage imageUrl={role.resource?.slackUser?.profile.image_192 ?? role.interimResource?.slackUser?.profile.image_192} />
                </div>
                <div className={styles.tagContainer}>{tag}</div>
            </div>
        </Tooltip>
    )
}

export default RoleChip
