import React from "react"
import styles from "./LoadLayout.module.css"
import { CountSummary } from "../Model/types"
import { LayoutData } from "../Utils/types"
import { loadSavedLayouts } from "../Utils/localStorageUtils"
import Dashboard from "../Dashboard"

export interface LoadLayoutProps {
    countSummary: CountSummary
    selectLayout: (layout: LayoutData) => void
}

const LoadLayout: React.FC<LoadLayoutProps> = ({ countSummary, selectLayout }) => {
    const savedLayouts = loadSavedLayouts()

    const layouts = savedLayouts.map((layout) => (
        <div
            key={layout.id}
            className={styles.layoutThumbnail}
            onClick={() => {
                selectLayout(layout)
            }}
        >
            {/* <div className={styles.layoutName}>{layout.name}</div> */}
            <Dashboard countSummary={countSummary} initialLayoutData={layout} isThumbnail={true} />
        </div>
    ))

    return (
        <div className={styles.loadLayout}>
            <div className={styles.title}>Select a Layout to Load</div>
            <div className={styles.layouts}>{layouts}</div>
        </div>
    )
}

export default LoadLayout
