import React, { CSSProperties } from "react"
import styles from "./CalendarSidebar.module.css"
import { useSelector } from "react-redux"
import { useDispatch } from "../Model/hooks"
import { selectEventCategories } from "./foundryCalendarSlice"
import { toggleCagetory } from "./foundryCalendarSlice"

interface CalendarSidebarProps {
    // Define any props here if needed
}

const CalendarSidebar: React.FC<CalendarSidebarProps> = (props) => {
    const eventCategories = useSelector(selectEventCategories)
    const dispatch = useDispatch()

    const categoryViews = eventCategories.map((category) => {
        const { name, color, visible } = category
        const style: CSSProperties = {
            backgroundColor: color,
            borderRadius: "4px",
            border: `2px solid ${color}`,
            opacity: visible ? 1.0 : 0.5,
        }
        return (
            <div
                className={styles.category}
                style={style}
                onClick={() => dispatch(toggleCagetory(category.name))}
                key={`id-category-toggle-${category.name}`}
            >
                {name}
            </div>
        )
    })

    return (
        <div className={styles.sidebar}>
            <div className={styles.title}>Calendar Categories</div>
            <div className={styles.categories}>{categoryViews}</div>
        </div>
    )
}

export default CalendarSidebar
