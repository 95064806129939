import trimQuotes from "../Utils/trimQuotes"

export default class AirtableFilters {
    static roles = {
        // root node for the functional org structure view
        // possible add a "Group Type" of "Foundry" to the foundry role so this is simpler
        rootData: (item: any): boolean => {
            return item.fields.Name === "Digital Foundry"
        },
        // child nodes for the functional org structure view
        children: (parentItem: any, data: any): any[] => {
            return data.filter((childItem: any) => {
                let parent = childItem.fields.Parent
                if (Array.isArray(parent)) {
                    parent = parent[0]
                }
                return trimQuotes(parent) === parentItem.fields.Primary
            })
        },
        // this is the function that determines which platforms should be shown on the Value Streams section
        platforms: (item: any): boolean => {
            const type = item.fields["Type"]
            const parent = item.fields["Parent"]
            return type === "Platform" && parent
        },
        // this is how we map a value stream to a platform
        // "Group Type" = "Value Stream" AND "Funding Source" = (platform name)
        // valueStreams: (parentItem: any) => {
        //     return (item: any) => {
        //         let tag = ""
        //         if (item.fields["Funding Source"] !== undefined) {
        //             tag = item.fields["Funding Source"][0]
        //                 .trim()
        //                 .replace(/\s+/g, " ")
        //         }
        //         const platformName = parentItem.fields["Role"]
        //             .trim()
        //             .replace(/\s+/g, " ")
        //         return (
        //             item.fields["Group Type"] === "Value Stream" &&
        //             tag === platformName
        //         )
        //     }
        // },
        // map area paths to value streams
        areaPaths: (parentItem: any) => {
            return (item: any) => {
                const parent = item.fields.Parent
                const type = item.fields.Type
                return (
                    parent === parentItem.fields.Primary && type === "Area Path"
                )
            }
        },
        // possible add a "Group Type" of "individual"
        support: (parentItem: any) => {
            return (supportItem: any) => {
                const parent = supportItem.fields["Parent"] ?? ""
                const type = supportItem.fields["Type"]
                return parent === parentItem.fields.Primary && type === "Role"
            }
        },
        // we need a different method for getting value stream pods because they are not children of thier value streams - relevant for non io value streams
        // valueStreamPods: (parentItem: any) => {
        //     const valueStreamName = parentItem.fields["Role"]
        //         .trim()
        //         .replace(/\s+/g, " ")
        //     return (item: any) => {
        //         return (
        //             item.fields["Group Type"] === "Pod" &&
        //             item.fields["Value Stream Tag"] === valueStreamName &&
        //             item.fields["(Review) Area Path Tag"] === undefined
        //         )
        //     }
        // },
        // this pulls the pods for an area path
        pods: (parentItem: any) => {
            return (item: any) => {
                const parent = item.fields.Parent
                const type = item.fields.Type
                return parent === parentItem.fields.Primary && type === "Pod"
            }
        },
        devPods: (parentItem: any) => {
            return (item: any) => {
                const parent = item.fields.Parent
                const type = item.fields.Type
                return (
                    parent === parentItem.fields.Primary &&
                    type === "Developer Group"
                )
            }
        },
        devsForDevPod: (devPod: any) => {
            return (item: any) => {
                const parent = item.fields.Parent
                const type = item.fields.Type
                return parent === devPod.fields.Primary && type === "Role"
            }
        },
        services: (parentItem: any) => {
            return (item: any) => {
                const parent = item.fields.Parent
                const type = item.fields.Type
                return (
                    parent === parentItem.fields.Primary && type === "Service"
                )
            }
        },
    }
    static resources = {
        rootData: (item: any): boolean => {
            return item.fields["Title"] === "Chief Digital Officer"
        },
        children: (parentItem: any) => {
            return (childItem: any) => {
                const parentIds = childItem.fields["Manager"]
                if (parentIds && parentIds.length > 0) {
                    return parentIds[0] === parentItem.id
                } else {
                    return false
                }
            }
        },
        directReports: (parentId: string) => {
            return (item: any) => {
                return item.fields["Manager"]?.[0] === parentId
            }
        },
    }
}
