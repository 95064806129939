import Color from "color"
import { Product } from "./Model/types"

interface TimeCount {
    name: string // Readable timestamp
    value: [Date, number] // Tuple with Date object and total count
}

const ONE_HOUR = 3600000 // One hour in milliseconds
const SHOW_ONLY_REAL_DATA = false // Set this to false to show filled data points

export function getAllTimestamps(products: Product[]): number[] {
    const timestamps = new Set<number>()
    products.forEach((product) => {
        product.historicalCounts.forEach((count) => {
            timestamps.add(count.timestamp)
        })
    })
    return Array.from(timestamps).sort((a, b) => a - b)
}

export function fillMissingData(products: Product[], allTimestamps: number[]): Product[] {
    return products.map((product) => {
        const filledCounts: any = []
        const historicalCountsMap = new Map(product.historicalCounts.map((count) => [count.timestamp, count.connectedCount]))

        // Initialize lastKnownValue with the product's historical count that has the lowest timestamp
        let lastKnownValue = product.historicalCounts.length > 0 ? product.historicalCounts[0].connectedCount : 0
        if (product.historicalCounts.length > 0) {
            product.historicalCounts.sort((a, b) => a.timestamp - b.timestamp)
            lastKnownValue = product.historicalCounts[0].connectedCount
        }

        allTimestamps.forEach((timestamp) => {
            if (historicalCountsMap.has(timestamp)) {
                lastKnownValue = historicalCountsMap.get(timestamp)!
            }
            filledCounts.push({ timestamp, connectedCount: lastKnownValue })
        })

        return { ...product, historicalCounts: filledCounts }
    })
}

export function aggregateCountsByTimestamp(products: Product[]): TimeCount[] {
    const allTimestamps = getAllTimestamps(products)
    const filledProducts = fillMissingData(products, allTimestamps)

    const result: TimeCount[] = allTimestamps.map((timestamp) => {
        let totalForTimestamp = 0

        filledProducts.forEach((product) => {
            const count = product.historicalCounts.find((deviceCount) => deviceCount.timestamp === timestamp)
            totalForTimestamp += count ? count.connectedCount : 0
        })

        const date = new Date(timestamp)
        const readableDate = date.toLocaleDateString("en-US", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        })

        return {
            name: readableDate,
            value: [date, totalForTimestamp],
        }
    })

    if (SHOW_ONLY_REAL_DATA) {
        const realDataPoints = new Set<number>()
        products.forEach((product) => {
            product.historicalCounts.forEach((count) => {
                realDataPoints.add(count.timestamp)
            })
        })

        return result.filter((timeCount) => realDataPoints.has(timeCount.value[0].getTime()))
    }

    return result
}

export function generateGradientColors(colorHexArray: string[], stepCount: number): string[] {
    const gradient: string[] = []
    const totalIntervals = colorHexArray.length - 1

    for (let i = 0; i < totalIntervals; i++) {
        let stepsPerInterval = Math.floor(stepCount / totalIntervals)
        if (i === totalIntervals - 1) {
            stepsPerInterval += stepCount % totalIntervals // Adjust the last segment
        }

        const startColor = Color(colorHexArray[i])
        const endColor = Color(colorHexArray[i + 1])

        for (let step = 0; step < stepsPerInterval; step++) {
            const factor = step / stepsPerInterval // Adjust factor calculation
            const interpolatedColor = startColor.mix(endColor, factor).hex()
            gradient.push(interpolatedColor)
        }
    }

    // Adjust the gradient array to ensure it has exactly stepCount elements
    while (gradient.length > stepCount) {
        gradient.pop()
    }

    // Ensure the last color is exactly as specified
    if (gradient.length < stepCount) {
        gradient.push(colorHexArray[colorHexArray.length - 1])
    }

    return gradient
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export function createCSV(productKeys: string[], count: number): string {
    let csvContent = "UUID,Count,Product Key,Timestamp\n"
    let id = 1
    const timeGap = 24 * 60 * 60 * 1000 // n hours in milliseconds

    productKeys.forEach((productKey) => {
        let currentTimestamp = Date.now()

        let currentCount = Math.floor(Math.random() * 9000 + 1000)

        for (let i = 0; i < count; i++) {
            // Generate a unique identifier
            const uuid = id++

            const count = currentCount
            const jitter = Math.floor((Math.random() - 0.45) * 2000)
            currentCount -= jitter
            currentCount = Math.max(0, currentCount)

            // Adjust timestamp to go backwards by with each iteration
            const timestamp = currentTimestamp - i * timeGap

            // Add a row to the CSV string
            csvContent += `${uuid},${count},${productKey},${timestamp}\n`
        }
    })

    return csvContent
}

export function generateCSVData() {
    const productKeys = ["smart", "ahp", "ahs-iaq-sensors", "europe", "lynx-fleet", "gcs-chillers", "air-monitor", "air-purifier", "ecobee-intelisence", "ecobee-cor-housewise", "infinity", "observer-icp", "gcs-tctc", "eco-energy", "sensitech"]
    const csv = createCSV(productKeys, 365)
    console.log("🚀 ~ csv:", csv)
}

export function generateDeviceCountData(productKeys: string[]) {
    // const productKeys = ["smart", "ahp", "ahs-iaq-sensors", "europe", "lynx-fleet", "gcs-chillers", "air-monitor", "air-purifier", "ecobee-intelisence", "ecobee-cor-housewise", "infinity", "observer-icp", "gcs-tctc", "eco-energy", "sensitech"]
    const data: any[] = []
    const count = 36
    let id = 1
    const timeGap = 10 * 24 * 60 * 60 * 1000 // n hours in milliseconds

    productKeys.forEach((productKey) => {
        let currentTimestamp = Date.now()

        let currentCount = Math.floor(Math.random() * 90000 + 1000)

        for (let i = 0; i < count; i++) {
            // Generate a unique identifier
            const uuid = id++

            const count = currentCount
            const jitter = Math.floor((Math.random() - 0.47) * currentCount * 0.2)
            currentCount -= jitter
            currentCount = Math.max(0, currentCount)

            // Adjust timestamp to go backwards by with each iteration
            const timestamp = currentTimestamp - i * timeGap

            const scale = 1 - Math.random() * 0.3

            data.push({
                id: uuid,
                fields: {
                    UUID: uuid,
                    "Connected Count": Math.floor(count * scale),
                    "Total Count": count,
                    "Product Key": productKey,
                    Timestamp: timestamp,
                },
            })
        }
    })

    return data
}
