import styles from "./DetailPanel.module.css"
import { motion } from "framer-motion"
import { ProjectCompositionItem } from "./projectCompositionSlice"

interface DetailPanelProps {
    projectCompositionItem: ProjectCompositionItem
}

const DetailPanel: React.FC<DetailPanelProps> = ({
    projectCompositionItem,
}) => {
    let title = projectCompositionItem.type
    let subtitle = projectCompositionItem.name
    const { description, storyPoints, issueKey } = projectCompositionItem
    let stripColor = projectCompositionItem.itemStyle?.color ?? "clear"
    let tShirtSize: string | undefined =
        projectCompositionItem.tShirtSize ?? "None"

    if (stripColor === "clear") {
        if (title === "Jira Epic") {
            stripColor = "#5B647B"
        }
        if (title === "Product Epic") {
            stripColor = "#3F4A64"
        }
        if (title === "Jira Story") {
            stripColor = "#757D91"
        }
        if (title === "Project") {
            stripColor = "#25314F"
        }
    }

    if (title === "Organization" || title === "Project") {
        title = subtitle
        subtitle = ""
        tShirtSize = undefined
    }

    if (title === "Jira Story") {
        tShirtSize = undefined
    }

    if (title === "Jira Epic" || title === "Product Epic") {
    }

    const onIssueClick = () => {
        if (issueKey) {
            const url = `https://carrier-digital.atlassian.net/browse/${issueKey}`
            window.open(url, "_blank")
        }
    }

    return (
        <div className={styles.detailPanel} onClick={onIssueClick}>
            <div>{title}</div>
            <div className={styles.subtitle}>{subtitle}</div>
            <div>{description}</div>
            {tShirtSize && (
                <div>
                    T-Shirt Size: <b>{tShirtSize}</b>
                </div>
            )}
            <div>
                Story Points: <b>{storyPoints}</b>
            </div>
            <div
                className={styles.colorStrip}
                style={{ backgroundColor: stripColor }}
            ></div>
        </div>
    )
}

export default DetailPanel
