import React, { CSSProperties } from "react"
import styles from "./ServiceChip.module.css"
import { Service } from "../Model/types"
import { Tooltip, TooltipProps } from "@mui/material"

interface ServiceChipProps {
    service: Service
}

const ServiceChip: React.FC<ServiceChipProps> = ({ service }) => {
    const onServiceClick = () => {
        const url = `https://carrier-digital.atlassian.net/browse/${service.jiraKey}`
        window.open(url, "_blank")
    }

    function tooltipContentDescription(description?: string) {
        return <div className={styles.tooltipContentDescription}>{description}</div>
    }

    const tooltipDefaultProps: Partial<TooltipProps> = {
        enterDelay: 500,
        placement: "top-start",
        arrow: true,
    }

    return (
        <Tooltip key={service.id} title={tooltipContentDescription(service.description)} {...tooltipDefaultProps}>
            <div className={styles.service} onClick={onServiceClick}>
                {service.name}
            </div>
        </Tooltip>
    )
}

export default ServiceChip
