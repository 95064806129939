import React from "react"
import {
    Routes,
    Route,
    useMatch,
    useNavigate,
    Navigate,
} from "react-router-dom"
import styles from "./Metrics.module.css"
import ProductIncrement from "./ProductIncrement/ProductIncrement"
import ProjectComposition from "./ProjectComposition/ProjectComposition"
import TabButton from "../Components/TabButton"

interface MetricsProps {}

const Metrics: React.FC<MetricsProps> = () => {
    const navigate = useNavigate()
    const programIncrementMatch = useMatch("/metrics/program-increment")
    const projectCompositionMatch = useMatch("/metrics/project-composition")

    return (
        <div className={styles.container}>
            <div className={styles.topNav}>
                <TabButton
                    title="Program Increment"
                    isSelected={!!programIncrementMatch}
                    onClick={() => {
                        navigate("/metrics/program-increment")
                    }}
                />
                <TabButton
                    title="Project Composition"
                    isSelected={!!projectCompositionMatch}
                    onClick={() => {
                        navigate("/metrics/project-composition")
                    }}
                />
            </div>
            <div className={styles.content}>
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to="/metrics/program-increment" />}
                    />
                    <Route
                        path="program-increment"
                        element={<ProductIncrement />}
                    />
                    <Route
                        path="project-composition"
                        element={<ProjectComposition />}
                    />
                </Routes>
            </div>
        </div>
    )
}

export default Metrics
