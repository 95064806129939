import React, { CSSProperties } from "react"
import styles from "./PlatformHeader.module.css"
import { Platform, Role, ValueStream } from "../Model/types"
import RoleView from "./Views/RoleView"
import { useNavigate } from "react-router-dom"

interface PlatformHeaderProps {
    platform: Platform
    onRoleClick: (role: Role) => void
    showLeads?: boolean
    onHeaderClick?: () => void
}

const PlatformHeader: React.FC<PlatformHeaderProps> = ({ platform, showLeads, onHeaderClick, onRoleClick }) => {
    const navigate = useNavigate()

    const leads = platform.support.map((role) => {
        return <RoleView role={role} key={"platformLead-" + role.id} onClick={onRoleClick} />
    })

    let titleClass = `${styles.platformTitle}`
    if (onHeaderClick) {
        titleClass += ` ${styles.clickable}`
    }

    const onViewAllClick = () => {
        navigate(`/value-streams/`)
    }

    return (
        <div className={styles.platformHeader}>
            <div className={styles.topContainer}>
                <div
                    className={titleClass}
                    onClick={() => {
                        onHeaderClick?.()
                    }}
                >
                    {platform.name}
                </div>
                {!showLeads && (
                    <div className={styles.backButton} onClick={onViewAllClick}>
                        View All Platforms
                    </div>
                )}
            </div>

            {showLeads && <div className={styles.platformLeads}>{leads}</div>}
        </div>
    )
}

export default PlatformHeader
