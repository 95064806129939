import React, { useRef, useEffect, useState, useContext, createContext } from "react"
import { init, ECharts, EChartsOption, SetOptionOpts } from "echarts"
import { useAnimation } from "../ProjectYarn/Utils/animationContext"

export interface EchartsReactWrapperProps {
    option: EChartsOption
    style?: React.CSSProperties
    settings?: SetOptionOpts
    loading?: boolean
    theme?: "light" | "dark"
    onChartClick?: (params: any, chart: ECharts) => void
    onChartHover?: (params: any, chart: ECharts) => void
    onChartHoverEnd?: (params: any, chart: ECharts) => void
}

const EChartsContext = createContext<ECharts | null>(null)

export const useECharts = () => useContext(EChartsContext)

export const EchartsReactWrapper: React.FC<EchartsReactWrapperProps> = ({ option, style, settings, loading, theme, onChartClick, onChartHover, onChartHoverEnd }) => {
    const chartRef = useRef<HTMLDivElement>(null)
    const [chartInstance, setChartInstance] = useState<ECharts | null>(null)
    const resizeTimer = useRef<NodeJS.Timeout | null>(null)
    const { animation } = useAnimation()

    useEffect(() => {
        let chart: ECharts | undefined
        if (chartRef.current !== null) {
            chart = init(chartRef.current, theme)
            setChartInstance(chart)

            const resizeObserver = new ResizeObserver(() => {
                if (resizeTimer.current) clearTimeout(resizeTimer.current)
                resizeTimer.current = setTimeout(() => {
                    chart?.resize()
                }, 100) // Resize delay of 100ms
            })
            resizeObserver.observe(chartRef.current)

            return () => {
                resizeObserver.disconnect()
                if (resizeTimer.current) clearTimeout(resizeTimer.current)
                chart?.dispose()
            }
        }
    }, [theme])

    useEffect(() => {
        if (chartInstance) {
            const updatedOption = { ...option, animation }
            chartInstance.setOption(updatedOption as any, settings)
        }
    }, [option, settings, chartInstance, animation])

    useEffect(() => {
        if (chartInstance) {
            loading ? chartInstance.showLoading() : chartInstance.hideLoading()
        }
    }, [loading, chartInstance])

    useEffect(() => {
        const chart = chartInstance

        if (!chart) return

        chart.off("click")
        chart.off("mouseover")
        chart.off("mouseout")

        chart.on("click", (params: any) => onChartClick?.(params, chart))
        chart.on("mouseover", (params: any) => onChartHover?.(params, chart))
        chart.on("mouseout", (params: any) => onChartHoverEnd?.(params, chart))
    }, [onChartClick, onChartHover, onChartHoverEnd, chartInstance])

    return (
        <EChartsContext.Provider value={chartInstance}>
            <div ref={chartRef} style={{ width: "100%", height: "100%", ...style }} />
        </EChartsContext.Provider>
    )
}

export default React.memo(EchartsReactWrapper)
