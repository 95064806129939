import React, { useContext } from "react"
import styles from "./CategoryPieTile.module.css"
import baseStyles from "../Tile.module.css"
import { CountSummary, Product } from "../Model/types"
import { EchartsReactWrapper } from "../../Components/EchartReactWrapper"
import { generateGradientColors } from "../utils"
import { ProductColorsContext } from "../Dashboard"
import { EChartOption } from "echarts"

export interface CategoryPieTileProps {
    size: { width: number; height: number }
    countSummary: CountSummary
}

const CategoryPieTile: React.FC<CategoryPieTileProps> = ({ size, countSummary }) => {
    const layoutStyle = size.width > 300 ? styles.largeLayout : styles.smallLayout

    // const colors = ["#DCB620", "#AA0086", "#438CD3", "#5743CC", "#61C6A9"]
    const colors = ["rgba(255, 255, 255, 0.1"]
    const productColors = useContext(ProductColorsContext)
    const options = getOptions(countSummary, colors, productColors)

    return (
        <div className={styles.categoryPieTile}>
            <div className={baseStyles.title}>Connected Devices by Category</div>
            <div style={{ flexGrow: 1 }}>
                <div className={styles.chart}>
                    <EchartsReactWrapper option={options as any} />
                </div>
            </div>
        </div>
    )
}

function getOptions(countSummary: CountSummary, genericColors: string[], productColors: { [id: string]: string }): any {
    const products = countSummary.products

    // Get unique categories
    const uniqueCategories = Array.from(new Set(products.map((product) => product.category)))

    // Generate data for the inner pie chart (category totals) with linear gradients
    const innerPieData = uniqueCategories.map((category) => {
        const categoryProducts = products.filter((product) => product.category === category)
        const totalConnectedCount = categoryProducts.reduce((sum, product) => sum + (product.latestCount?.connectedCount || 0), 0)

        // Create the gradient color
        const gradientColor = {
            type: "linear",
            x: 0,
            y: 0.5,
            x2: 1,
            y2: 0.5,
            colorStops: categoryProducts.map((product, index) => {
                const weight = (product.latestCount?.connectedCount || 0) / totalConnectedCount
                return {
                    offset: categoryProducts.slice(0, index).reduce((acc, p) => acc + (p.latestCount?.connectedCount || 0) / totalConnectedCount, 0),
                    color: productColors[product.key] || genericColors[Math.floor(Math.random() * genericColors.length)],
                }
            }),
        }

        return {
            value: totalConnectedCount,
            name: category,
            itemStyle: {
                color: gradientColor,
            },
        }
    })

    // Generate data for the outer pie chart (product totals)
    const outerPieData = uniqueCategories.flatMap((category) => {
        return products
            .filter((product) => product.category === category)
            .map((product) => ({
                value: product.latestCount?.connectedCount || 0,
                name: product.name,
                category: category,
                itemStyle: {
                    color: productColors[product.key] || genericColors[Math.floor(Math.random() * genericColors.length)],
                },
            }))
    })

    const startAngle = -10

    const options: EChartOption = {
        tooltip: {
            trigger: "item",
            formatter: (params: any) => {
                const category = params.name
                const categoryProducts = products.filter((product) => product.category === category)

                // Sort products by latestCount.connectedCount in descending order
                categoryProducts.sort((a, b) => (b.latestCount?.connectedCount || 0) - (a.latestCount?.connectedCount || 0))

                // Construct the tooltip content
                let tooltipContent = `<div style="font-size: 18px; font-weight: bold; margin-bottom: 10px;">${category}</div>`
                categoryProducts.forEach((product) => {
                    const color = productColors[product.key] || genericColors[Math.floor(Math.random() * genericColors.length)]
                    tooltipContent += `<span style="display: inline-block; width: 10px; height: 10px; background-color: ${color}; border-radius: 50%; margin-right: 5px;"></span>${product.name}: <b>${product.latestCount?.connectedCount?.toLocaleString() || 0}</b><br/>`
                })

                return tooltipContent
            },
            backgroundColor: "#fff",
            textStyle: {
                color: "#000",
            },
        },
        series: [
            {
                name: "Category Totals",
                type: "pie",
                radius: "77%",
                startAngle: startAngle,
                data: innerPieData,
                itemStyle: {
                    borderWidth: 2,
                    borderColor: "rgba(0, 0, 0, 0.5)",
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
                label: {
                    position: "inside",
                    formatter: (params: any) => {
                        return `{name|${params.name}}\n{value|${params.value.toLocaleString()}}`
                    },
                    rich: {
                        name: {
                            color: "#fff",
                            fontSize: 24,
                            fontWeight: "bold",
                            align: "center",
                            lineHeight: 28,
                        },
                        value: {
                            color: "#fff",
                            fontSize: 24,
                            fontWeight: "bold",
                            align: "center",
                        },
                    },
                    textBorderColor: "transparent",
                },
            },
            {
                name: "Product Totals",
                type: "pie",
                radius: ["80%", "90%"],
                startAngle: startAngle,
                // roseType: "area",
                data: outerPieData,
                itemStyle: {
                    borderWidth: 2,
                    borderColor: "rgba(0, 0, 0, 0.5)",
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
                label: {
                    position: "outside",
                    formatter: (params: any) => {
                        return `{name|${params.name}}\n{value|${params.value.toLocaleString()}}`
                    },
                    minMargin: 10,
                    rich: {
                        name: {
                            color: "#fff",
                            fontSize: 16,
                            fontWeight: "bold",
                            align: "center",
                            lineHeight: 18,
                        },
                        value: {
                            color: "#aaa",
                            fontSize: 14,
                            fontWeight: "bold",
                            align: "center",
                        },
                    },
                    textBorderColor: "transparent",
                },
            },
        ],
    }

    return options
}

export default CategoryPieTile
