import React, { useEffect } from "react"
import styles from "./ProductIncrement.module.css"
import { useSelector } from "react-redux"
import { useDispatch } from "../../Model/hooks"
import JiraOverview from "./JiraOverview"
import SteppedProgressBar from "../../Components/SteppedProgressBar"
import { fetchProductIncrement, selectCurrentPi, selectJiraOverviews, selectLoading } from "./productIncrementSlice"
import { addDaysToDate, countCompletedSprints, daysBetween, daysSince, formatShortDate } from "./utils"
import { statusCategories } from "./statusCategories"
import LoadingStatus from "../../Components/LoadingStatus/LoadingStatus"
import { setLoadingMessage } from "../../Components/LoadingStatus/LoadingStatus"
import { motion } from "framer-motion"
import dayjs from "dayjs"

interface ProductIncrementProps {}

const ProductIncrement: React.FC<ProductIncrementProps> = () => {
    const currentPi = useSelector(selectCurrentPi)
    const jiraOverviews = useSelector(selectJiraOverviews)
    const loading = useSelector(selectLoading)
    const dispatch = useDispatch()

    const startDate = dayjs(currentPi.start).startOf("day").toDate()
    const endDate = dayjs(currentPi.end).startOf("day").toDate()
    const totalDays = daysBetween(startDate, endDate)
    const completeDays = daysSince(startDate)
    const totalSprints = currentPi.sprints.length
    const completeSprints = countCompletedSprints(currentPi.sprints)

    useEffect(() => {
        if (loading === "idle") {
            // dispatch(setLoadingMessage("Product Increment"))
            dispatch(fetchProductIncrement())
        }
    }, [loading, dispatch])

    const jiraViews = jiraOverviews.map((overview, index) => {
        return (
            <motion.div
                style={{}}
                // layout
                key={`motion-id-${overview.id}`}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.3,
                    delay: index * 0.1 + 0.1,
                    ease: "easeOut",
                }}
            >
                <JiraOverview key={overview.id} overview={overview} />
            </motion.div>
        )
    })

    const getDayTooltip = (index: number) => {
        const date = addDaysToDate(startDate, index)
        return <div>{formatShortDate(date)}</div>
    }

    const getSprintTooltip = (index: number) => {
        const sprintName = currentPi.sprints[index].name
        return <div>{sprintName}</div>
    }

    if (loading !== "loaded") return <LoadingStatus />

    let statusCategoryViews = statusCategories.map((category, index) => {
        const { name, color, statuses } = category
        return (
            <div className={styles.statusCategory} key={"id" + index}>
                <div className={styles.colorBlock} style={{ backgroundColor: color }}></div>
                {name}
            </div>
        )
    })

    const color = "#aaa"
    statusCategoryViews.push(
        <div className={styles.statusCategory} key={"id-notDueInPi"}>
            <div
                className={styles.colorBlock}
                style={{
                    backgroundColor: "gray",
                    background: `repeating-linear-gradient(
                                    -45deg,
                                    ${color},
                                    ${color} 6px,
                                    #46529800 6px,
                                    #46529800 8px
                                    )`,
                }}
            ></div>
            Not Due in {currentPi.name}
        </div>
    )

    return (
        <motion.div
            style={{}}
            // layout
            key={`motion-id-product-increment`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.pi}>
                        <div className={styles.piHeader}>{currentPi.name}</div>
                        <div className={styles.piProgress}>
                            <div className={styles.currentPiDates}>
                                <div>{formatShortDate(startDate)}</div>
                                <div>{formatShortDate(endDate)}</div>
                            </div>
                            <div style={{ height: "10px", marginBottom: "10px" }}>
                                <SteppedProgressBar stepCount={totalDays} currentStep={completeDays} getTooltip={getDayTooltip} />
                            </div>
                            <div style={{ height: "10px" }}>
                                <SteppedProgressBar stepCount={totalSprints} currentStep={completeSprints} getTooltip={getSprintTooltip} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.statusCategoryContainer}>
                        <div className={styles.statusCategoryCollection}>{statusCategoryViews}</div>
                    </div>
                </div>

                <div className={styles.jiraOverviews}>{jiraViews}</div>
            </div>
        </motion.div>
    )
}

export default ProductIncrement
