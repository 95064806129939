import React from "react"
import ProfilePic from "../Assets/profilePlaceholder.png"
import styles from "./ProfileImage.module.css"
import { ReactComponent as DefaultImage } from "../Assets/person.svg"

interface ProfileImageProps {
    imageUrl?: string
}

const ProfileImage: React.FC<ProfileImageProps> = ({ imageUrl }) => {
    if (imageUrl) {
        return (
            <div className={styles.profileImage}>
                <img className={styles.slackImage} src={imageUrl} alt={imageUrl} />
            </div>
        )
    } else {
        return (
            <div className={styles.profileImage}>
                <DefaultImage className={styles.defaltSvg} />
            </div>
        )
    }
}

export default ProfileImage
