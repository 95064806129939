import React, { useState, useEffect, ChangeEvent, useMemo } from "react"
import {
    Outlet,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom"
import Store from "../Model/Store"
import { Resource } from "../Model/types"
import styles from "./ResourceFinder.module.css"
import ResourceTile from "./ResourceTile"
import Modal from "../Components/Modal"
import ResourceDetailView from "../Components/ResourceDetailView"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import { PersonSearch, Cancel } from "@mui/icons-material"
import IconButton from "@mui/material/IconButton"
import { Button } from "@mui/material"
import { motion } from "framer-motion"

interface ResourceFinderProps {
    store?: Store
}

type FilterField = keyof Resource
const filterFields: FilterField[] = [
    "valueStreams",
    "areaPaths",
    "discipline",
    "subDiscipline",
    "location",
    "employer",
    "category",
    "pods",
]

const ResourceFinder: React.FC<ResourceFinderProps> = ({ store }) => {
    const { resourceId } = useParams<{ resourceId?: string }>()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchTerm = searchParams.get("search") || ""
    const [selectedFilters, setSelectedFilters] = useState<
        Record<FilterField, string[]>
    >({} as Record<FilterField, string[]>)

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newSearchTerm = event.target.value
        setSearchParams({ search: newSearchTerm })
    }

    const handleClearSearch = () => {
        setSearchParams({ search: "" })
    }

    const handleCheckboxChange =
        (field: FilterField, value: string) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            setSelectedFilters((prev) => {
                const currentValues = prev[field] || []
                return {
                    ...prev,
                    [field]: event.target.checked
                        ? [...currentValues, value]
                        : currentValues.filter((v) => v !== value),
                }
            })
        }

    const onSelectResource = (id: string) => {
        navigate(`/resource-finder/resource/${id}?search=${searchTerm}`)
    }

    const resources = store
        ? store.getAllResources().filter((resource) => {
              if (!resource) return false
              const searchTermLower = searchTerm.toLowerCase()
              if (
                  resource.name &&
                  resource.name.toLowerCase().includes(searchTermLower)
              )
                  return true
              if (
                  resource.title &&
                  resource.title.toLowerCase().includes(searchTermLower)
              )
                  return true
              return false
          })
        : undefined

    const filteredResources = resources?.filter((resource) =>
        filterFields.every((field) => {
            const selectedValues = selectedFilters[field] || []
            const resourceFieldValue = resource?.[field]
            if (Array.isArray(resourceFieldValue)) {
                return selectedValues.length === 0
                    ? true
                    : resourceFieldValue.some((val) =>
                          selectedValues.includes(val)
                      )
            } else {
                return selectedValues.length === 0
                    ? true
                    : selectedValues.includes(resourceFieldValue as string)
            }
        })
    )

    const allUniqueFilterValues = useMemo(
        () =>
            filterFields.reduce((acc, field) => {
                acc[field] = Array.from(
                    new Set<string>(
                        (resources ?? []).flatMap((resource) => {
                            const fieldValue = resource?.[field]
                            if (Array.isArray(fieldValue)) {
                                return fieldValue.filter(
                                    (value) => typeof value === "string"
                                )
                            } else if (typeof fieldValue === "string") {
                                return fieldValue ? [fieldValue] : []
                            }
                            return []
                        })
                    )
                )
                return acc
            }, {} as Record<FilterField, string[]>),
        [resources]
    )

    const filterCounts = useMemo(
        () =>
            filterFields.reduce((acc, field) => {
                acc[field] =
                    allUniqueFilterValues[field]?.reduce((obj, value) => {
                        const count =
                            filteredResources?.filter((resource) => {
                                const fieldVal = resource && resource[field]
                                if (Array.isArray(fieldVal)) {
                                    return fieldVal.includes(value)
                                }
                                return fieldVal === value
                            })?.length ?? 0
                        obj[value] = count
                        return obj
                    }, {} as Record<string, number>) ?? {}
                return acc
            }, {} as Record<FilterField, Record<string, number>>),
        [filteredResources, allUniqueFilterValues]
    )

    const resourceTiles = filteredResources
        ?.sort((a, b) => (a?.name || "").localeCompare(b?.name || ""))
        .map(
            (resource, index) =>
                resource && (
                    <ResourceTile
                        key={resource.id}
                        resource={resource}
                        onClick={onSelectResource}
                        index={index}
                    />
                )
        )

    const onModalClose = () => {
        navigate(`/resource-finder/?search=${searchTerm}`)
    }

    let modal: React.ReactNode = null
    if (resourceId) {
        const selectedResource = store?.getResourceById(resourceId)
        if (selectedResource && store) {
            modal = (
                <Modal onClose={onModalClose}>
                    <ResourceDetailView
                        resource={selectedResource}
                        onSelectResource={onSelectResource}
                    />
                </Modal>
            )
        }
    }

    const filters = filterFields.map((field) => (
        <div key={field} className={styles.filterGroup}>
            <div className={styles.filterName}>
                {field.charAt(0).toUpperCase() + field.slice(1)}
            </div>
            <div className={styles.filterList}>
                {(allUniqueFilterValues[field] || [])
                    .sort(
                        (a, b) =>
                            (filterCounts[field][b] || 0) -
                            (filterCounts[field][a] || 0)
                    )
                    .map((value) => {
                        const count = filterCounts[field]?.[value] ?? 0
                        return (
                            <div
                                className={styles.filter}
                                key={value}
                                style={{ opacity: count > 0 ? 1 : 0.3 }}
                            >
                                <label className={styles.checkboxLabel}>
                                    <input
                                        className={styles.checkbox}
                                        type="checkbox"
                                        value={value}
                                        onChange={handleCheckboxChange(
                                            field,
                                            value
                                        )}
                                        checked={
                                            selectedFilters[field]?.includes(
                                                value
                                            ) ?? false
                                        }
                                    />
                                    <div className={styles.filterValue}>
                                        {value} ({count})
                                    </div>
                                </label>
                            </div>
                        )
                    })}
            </div>
        </div>
    ))

    const onClearFilters = () => {
        setSelectedFilters({} as Record<FilterField, string[]>)
    }

    return (
        <div className={styles.container}>
            {modal}
            <motion.div
                className={styles.filterBar}
                initial={{ opacity: 0, scale: 1, translateX: -200 }}
                animate={{ opacity: 1, scale: 1, translateX: 0 }}
                transition={{ duration: 0.3, ease: "easeOut" }}
            >
                <div className={styles.filterMastHead}>
                    <div className={styles.search}>
                        <TextField
                            autoFocus
                            className={styles.textInput}
                            value={searchTerm}
                            onChange={handleSearchChange}
                            id="search-input"
                            label="🔍 search by name or title"
                            variant="outlined"
                            InputProps={{
                                endAdornment: searchTerm ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={handleClearSearch}
                                        >
                                            <Cancel />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null,
                            }}
                        />
                    </div>

                    {Object.keys(selectedFilters).length > 0 ? (
                        <div className={styles.filterHeader}>
                            <Button
                                sx={{ width: "100%" }}
                                variant="contained"
                                onClick={onClearFilters}
                            >
                                Clear Filters
                            </Button>
                        </div>
                    ) : null}
                </div>

                <div className={styles.filterContainer}>{filters}</div>
            </motion.div>
            <div className={styles.content}>
                <div className={styles.tileContainer}>{resourceTiles}</div>
            </div>
            <Outlet />
        </div>
    )
}

export default ResourceFinder
