import React from "react"
import styles from "./BasicTile.module.css"

export interface BasicTileProps {
    size: { width: number; height: number }
}

const BasicTile: React.FC<BasicTileProps> = ({ size }) => {
    const title = "Basic Tile"
    const layoutStyle = size.width > 300 ? styles.largeLayout : styles.smallLayout

    return (
        <div className={`${styles.basicTile} ${layoutStyle}`}>
            <div className={styles.title}>{title}</div>
        </div>
    )
}

export default BasicTile
